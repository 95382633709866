import { Button, Popover } from 'antd'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { FC } from 'react'
import styles from './AdditionalItemsPopover.less'

interface Props {
  items: Array<string>
}

const AdditionalItemsPopover: FC<Props> = ({ items }) => {
  const { t } = useTranslation()

  if (!items.length) {
    return (
      <div className={styles.wrapper}>
        {t('common.additionalItemsPopover.noItems')}
      </div>
    )
  }

  const [firstItem, ...rest] = items

  return (
    <div className={styles.wrapper}>
      <Text>{firstItem}</Text>
      <Popover
        content={
          <>
            {rest.map((item) => (
              <Text block key={item}>
                {item}
              </Text>
            ))}
          </>
        }
      >
        {rest.length > 0 && (
          <Button className={styles.button} shape="circle">
            + {rest.length}
          </Button>
        )}
      </Popover>
    </div>
  )
}

export default AdditionalItemsPopover
