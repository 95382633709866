import { FC } from 'react'
import { Card, Divider, Button, Tooltip, Flex } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import { DownloadOutlined } from '@ant-design/icons'
import {
  Text,
  Space,
  useTokenDownload,
  useUserContext,
} from '@signifyd/components'
import { getRuleSimulationDownloadToken, RuleSimulation } from '@signifyd/http'
import { checkpointToActionMap } from 'core/constants'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { useTypedRouteParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage'
import { VIEW_TYPE } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import { useStoreState } from 'stores'
import TotalImpactStatistic from '../../components/TotalImpactStatistic'
import styles from './TotalImpactCard.less'
import { checkpointActionToImpactKeysMap } from './constants'

const TotalImpactCard: FC<{ ruleSimulation: RuleSimulation }> = ({
  ruleSimulation,
}) => {
  const { t, i18n } = useTranslation()

  const routeParams = useTypedRouteParams()
  const [{ viewType }] = useQueryParams(PublishPageQueryParams)
  const { user } = useUserContext()

  const { formattedCountImpact, formattedGmvImpact } = useStoreState(
    (state) => state.simulationModel
  )

  const {
    downloadSearchResults,
    isBackgroundDownloadActive,
    isDownloadPending,
  } = useTokenDownload<number>({
    getTokenFunction: getRuleSimulationDownloadToken,
    getTokenPayload: ruleSimulation.simulationId,
    baseDownloadUrl: `${BASE_URL}/v2/ruleSimulations/download/${user.userId}`,
  })

  const { simulationSize } = ruleSimulation
  const formattedImpact =
    viewType === VIEW_TYPE.GMV ? formattedGmvImpact : formattedCountImpact

  if (!simulationSize || !formattedImpact || !routeParams?.checkpoint) {
    return null
  }

  const { checkpoint } = routeParams
  const actions = checkpointToActionMap[checkpoint]

  return (
    <Card
      title={t('publishWithSimulatorPage.totalImpactCard.title')}
      extra={
        <Text type="secondary">
          {t(
            `publishWithSimulatorPage.totalImpactCard.statisticLabels.${checkpoint}.extraContent`,
            {
              formattedNumber: simulationSize.toLocaleString(i18n.language),
              count: simulationSize,
            }
          )}
        </Text>
      }
    >
      <Space size="sm" />
      <Flex align="middle" justify="space-between" gap={32}>
        <div className={styles.totalImpact}>
          <TotalImpactStatistic
            title={
              <div className={styles.totalCard}>
                {t(
                  `publishWithSimulatorPage.totalImpactCard.statisticLabels.${checkpoint}.total`
                )}
                <Tooltip
                  placement="top"
                  title={t(
                    'publishWithSimulatorPage.totalImpactCard.download.tooltip'
                  )}
                  overlayClassName={styles.downloadTooltip}
                >
                  <Button
                    type="link"
                    onClick={downloadSearchResults}
                    loading={isDownloadPending}
                    disabled={isBackgroundDownloadActive}
                  >
                    {!isDownloadPending && <DownloadOutlined />}
                    {t(
                      'publishWithSimulatorPage.totalImpactCard.download.title'
                    )}
                  </Button>
                </Tooltip>
              </div>
            }
            value={formattedImpact.newTotal}
            percentage={formattedImpact.totalChange}
            originalNumber={formattedImpact.originalTotal}
          />
        </div>
        <Divider type="vertical" className={styles.mainDivider} />
        <Flex align="middle" justify="space-between" flex="1 0 auto">
          {actions.map((action) => {
            const impactKeys = checkpointActionToImpactKeysMap[action]

            return (
              <TotalImpactStatistic
                // TODO FET-1825 https://signifyd.atlassian.net/browse/FET-1825
                // casting is needed here as the current typing of this component implies that all CHECKPOINTS can have all ACTIONS, which is not true
                // CHECKPOINT_ACTION probably needs retyping in sig-http as a generic to return based on the CHECKPOINT type
                key={action}
                title={t(
                  `publishWithSimulatorPage.totalImpactCard.statisticLabels.${checkpoint}.${action}` as any
                )}
                value={formattedImpact[impactKeys.value]}
                percentage={formattedImpact[impactKeys.percentage]}
                originalNumber={formattedImpact[impactKeys.originalNumber]}
              />
            )
          })}
        </Flex>
      </Flex>
    </Card>
  )
}

export default TotalImpactCard
