import { ReactElement } from 'react'
import { CaretRightOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import { CollapseProps } from 'antd/es/collapse'
import { joinClassNames } from '@signifyd/utils'
import Panel from './CollapsePanel'
import styles from './Collapse.less'

function SigCollapse({
  className,
  ...props
}: CollapseProps): ReactElement<CollapseProps> {
  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      {...props}
      className={joinClassNames([className, styles.collapse])}
    />
  )
}

SigCollapse.Panel = Panel

export default SigCollapse
