import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PageSpinner, Layout } from '@signifyd/components'
import AppNavBar from 'core/containers/AppNavBar'
import { UnauthorizedLayout } from 'core/components/UnauthorizedLayout/UnauthorizedLayout'
import TestTeamBanner from 'core/components/TestTeamBanner'
import DashboardContainer from './containers/DashboardContainer'
import useSelectedTeamCheckpoints from './queries/useSelectedTeamCheckpoints'
import useTeamId from './hooks/useTeamId'
import useInitializeDashboard from './hooks/useInitializeDashboard'
import DashboardHeader from './components/DashboardHeader/DashboardHeader'

const { NavBar } = Layout

const DashboardPage: FC = () => {
  const { t } = useTranslation()

  const [createRuleModalVisible, setCreateRuleModalVisible] = useState(false)

  const teamId = useTeamId()

  const { data: selectedTeamCheckpoints, isLoading } =
    useSelectedTeamCheckpoints()

  useInitializeDashboard()

  if (isLoading || !teamId) {
    return <PageSpinner />
  }

  const hasCheckpoints = selectedTeamCheckpoints?.length

  return (
    <>
      <NavBar>
        <AppNavBar teamId={Number(teamId)} />
      </NavBar>
      <TestTeamBanner
        teamId={teamId}
        text={t('common.testTeamBanner.policy')}
      />

      <DashboardHeader isCreatePolicyModalVisible={createRuleModalVisible} />

      {hasCheckpoints ? (
        <DashboardContainer
          selectedTeamCheckpoints={selectedTeamCheckpoints}
          setCreateRuleModalVisible={setCreateRuleModalVisible}
        />
      ) : (
        <UnauthorizedLayout message={t('common.unauthorized.default')} />
      )}
    </>
  )
}

export default DashboardPage
