import { FC, useEffect, useRef, useState } from 'react'
import { Button, Popover } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip'
import { useQueryParam } from 'use-query-params'
import { Space } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import styles from './FilterPopover.less'
import FilterButton from './FilterButton/FilterButton'
import TextFilter from './TextFilter/TextFilter'
import { FilterConfig } from '../VariableTableFilterConfig'

export interface PopoverProps {
  popoverSize?: 'medium' | 'large'
  popoverPlacement?: TooltipPlacement
}

export interface ColumnFilterProps extends PopoverProps {
  filter: FilterConfig
}

const FilterPopover: FC<ColumnFilterProps> = ({
  filter,
  popoverPlacement = 'bottom',
}) => {
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)

  const initializedValue = useRef<string | undefined>()
  const [queryValue, setQueryValue] = useQueryParam(filter.queryKey)
  const [localValue, setLocalValue] = useState<string>()

  useEffect(() => {
    if (queryValue !== initializedValue.current) {
      setLocalValue(queryValue as string)
      initializedValue.current = queryValue as string
    }
  }, [queryValue])

  const onPopoverVisibleChange = (localVisible: boolean): void => {
    setVisible(localVisible)
  }

  const handleClick = (): void => {
    setVisible(!visible)
  }

  return (
    <div>
      <Popover
        content={
          <div>
            <TextFilter value={localValue} onChange={setLocalValue} />
            <Space size="sm" />
            <div className={styles.buttons}>
              <Button onClick={() => setVisible(false)}>
                {t('variablePage.variableTable.filterPopover.cancelButton')}
              </Button>
              <Button
                onClick={() => {
                  setQueryValue(localValue)
                  setVisible(false)
                }}
              >
                {t('variablePage.variableTable.filterPopover.saveButton')}
              </Button>
            </div>
          </div>
        }
        placement={popoverPlacement}
        trigger="click"
        open={visible}
        onOpenChange={onPopoverVisibleChange}
        destroyTooltipOnHide
      >
        <FilterButton
          onClick={handleClick}
          selected={!!queryValue}
          columnKey={filter.queryKey}
        />
      </Popover>
    </div>
  )
}

export default FilterPopover
