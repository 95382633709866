import { ValidateRuleResultItem, PolicyMatchId } from '@signifyd/http'
import { omit, isEqual } from 'lodash'

export const filterPolicyMatchIds = (
  invalidInvestigationResults: Array<ValidateRuleResultItem>,
  currentIds: Array<PolicyMatchId>,
  newPolicyMatchIds: Array<PolicyMatchId>
): Array<PolicyMatchId> => {
  const removedExistingInvalidIds: Array<PolicyMatchId> = currentIds.filter(
    (currentId) =>
      !invalidInvestigationResults.find((invalidResult) => {
        const { investigationId, ...rest } = currentId
        const mappedCurrentId = {
          ...rest,
          investigationId: investigationId?.toString() ?? null,
        }

        const omittedInvalidResult = omit(
          invalidResult,
          'conditionTestResult',
          'validationFailureReason'
        )

        return isEqual(mappedCurrentId, omittedInvalidResult)
      })
  )

  const removedDuplicates = newPolicyMatchIds.filter(
    (newId) =>
      !removedExistingInvalidIds.find((invalidResult) =>
        isEqual(invalidResult, newId)
      )
  )

  return [...removedExistingInvalidIds, ...removedDuplicates]
}
