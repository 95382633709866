import { FunctionComponent, ReactNode } from 'react'
import { Card } from 'antd'
import { colorSnow } from '@signifyd/colors'
import styles from './RuleConfigCard.less'

const bodyStyle = {
  background: colorSnow,
}

interface Props {
  label: string
  children: ReactNode
}

const RuleConfigCard: FunctionComponent<Props> = ({ label, children }) => {
  return (
    <Card size="small" styles={{ body: bodyStyle }} className={styles.card}>
      <div className={styles.body}>
        <div className={styles.label}>{label}</div>
        <div className={styles.content}>{children}</div>
      </div>
    </Card>
  )
}

export default RuleConfigCard
