import { ForwardRefRenderFunction, ReactNode, forwardRef } from 'react'
import { joinClassNames } from '@signifyd/utils'
import styles from './RuleSetDnDDroppableRow.less'

interface Props {
  children?: ReactNode
  className?: string
  [props: string]: any
}

const renderRow: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { className, children, ...rest }: Props,
  ref
) => (
  <div {...rest} ref={ref} className={joinClassNames([styles.row, className])}>
    {children}
  </div>
)

const RuleSetDnDDroppableRow = forwardRef(renderRow)

export default RuleSetDnDDroppableRow
