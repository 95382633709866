import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'antd'
import { Layout, Space } from '@signifyd/components'
import AppNavBar from 'core/containers/AppNavBar'
import ListDetailsPageSidePanel from 'pages/ListDetailsPage/containers/ListDetailsPageSidePanel'
import usePredictionListDetails from 'core/hooks/usePredictionListDetails'
import TestTeamBanner from 'core/components/TestTeamBanner'
import ListDetailsPageHeader from './containers/ListDetailsPageComponents/ListDetailsPageHeader'
import ListDetailsPageTitle from './containers/ListDetailsPageComponents/ListDetailsPageTitle'
import ListDetailsPageOrdersToggle from './containers/ListDetailsPageComponents/ListDetailsPageOrdersToggle'
import ListEntriesTableContainer from './containers/ListEntriesTableContainer/ListEntriesTableContainer'

const { Content, NavBar } = Layout

const ListDetailsPage: FC = () => {
  const { listId } = useParams()
  const { data: predictionList } = usePredictionListDetails(Number(listId))

  if (!listId || !predictionList) {
    return null
  }

  return (
    <>
      <NavBar>
        <AppNavBar teamId={predictionList.teamId} />
      </NavBar>

      <TestTeamBanner teamId={predictionList.teamId} />

      <Content>
        <ListDetailsPageHeader predictionList={predictionList} />

        <Row justify="space-between">
          <ListDetailsPageTitle predictionList={predictionList} />
          <ListDetailsPageOrdersToggle predictionList={predictionList} />
        </Row>

        <Space size="md" />

        <Row gutter={32}>
          <Col span={6}>
            <ListDetailsPageSidePanel predictionList={predictionList} />
          </Col>
          <Col span={18}>
            <ListEntriesTableContainer predictionList={predictionList} />
          </Col>
        </Row>
      </Content>
    </>
  )
}

export default ListDetailsPage
