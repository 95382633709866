import { FC, ReactNode } from 'react'
import { Card, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { Text, Space } from '@signifyd/components'
import { PolicyMatchId, CHECKPOINT } from '@signifyd/http'
import getColumns from './columns'

export interface RuleValidationResultProps {
  'data-test-id'?: string
  title: ReactNode
  validationResults: Array<PolicyMatchId>
  checkpoint: CHECKPOINT
}

const SCROLL_PROP = { y: 200 }
const LOCALE = { emptyText: ' ' }

const getRowKey = (
  { investigationId }: PolicyMatchId,
  index: number | undefined
): string => `${investigationId || ''}-${index}`

const RuleValidationResult: FC<RuleValidationResultProps> = ({
  title,
  checkpoint,
  validationResults,
  'data-test-id': dataTestId,
}) => {
  const { t } = useTranslation()

  const isReturnPolicy = checkpoint === CHECKPOINT.RETURN

  const columns = getColumns({ isReturnPolicy })

  return (
    <Card data-test-id={dataTestId}>
      <Text weight="semibold" block>
        {title}
      </Text>
      <Table
        rowKey={getRowKey}
        size="middle"
        bordered={false}
        pagination={false}
        columns={columns}
        dataSource={validationResults}
        scroll={SCROLL_PROP}
        locale={LOCALE}
      />
      <Space size="sm" />
      <Text type="secondary">
        {t('ruleValidationPage.itemCount', { count: validationResults.length })}
      </Text>
    </Card>
  )
}

export default RuleValidationResult
