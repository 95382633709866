import { FC, useState } from 'react'
import cx from 'classnames'
import { Select, Tooltip } from 'antd'
import { useTranslation, Trans } from 'react-i18next'
import { Text } from '@signifyd/components'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import { QuestionCircleOutlined } from '@ant-design/icons'
import FormError from 'core/components/FormError'
import styles from './ListEntriesForm.less'
import AddressEntryForm from '../AddressEntryForm'
import { handleClientValidation } from './manualFormValidation.utils'

export interface Props {
  disabled?: boolean
  manualInputError?: string
  manualInputValue: Array<string>
  manualInputValueCountLimit: number
  onChangeManualInputValue: (param: Array<string>) => void
  onPartialEntry: (value: boolean) => void
  predictionListType: PREDICTION_LIST_TYPE
}

const ManualEntriesForm: FC<Props> = ({
  disabled,
  manualInputError,
  manualInputValue,
  manualInputValueCountLimit,
  onChangeManualInputValue,
  onPartialEntry,
  predictionListType,
}) => {
  const { t } = useTranslation()
  const [clientValidation, setClientValidation] = useState<string | undefined>()

  const handleOnChange = (e: Array<string>): void => {
    onChangeManualInputValue(e)
    const validated = handleClientValidation({
      predictionListType,
      manualInputValue: e,
    })

    setClientValidation(validated)
  }

  if (predictionListType === PREDICTION_LIST_TYPE.ADDRESS) {
    return (
      <AddressEntryForm
        onChangeManualInputValue={onChangeManualInputValue}
        onPartialEntry={onPartialEntry}
      />
    )
  }

  return (
    <>
      {predictionListType === PREDICTION_LIST_TYPE.EMAIL_DOMAIN && (
        <>
          <Text>{t('listCommon.listTypeStartCase.EMAIL_DOMAIN')}</Text>

          <Tooltip
            placement="top"
            trigger="hover"
            className={styles.tooltip}
            title={
              <Trans
                i18nKey="listCommon.emailDomain.toggleTooltip"
                components={{ p: <p /> }}
              />
            }
          >
            <QuestionCircleOutlined
              data-test-id="emailDomainTooltip"
              data-analytics-id="email-domain-tooltip"
            />
          </Tooltip>
        </>
      )}
      <Select
        disabled={disabled}
        className={cx(styles.select, clientValidation && styles.error)}
        suffixIcon={null}
        allowClear
        tokenSeparators={[',', ' ']}
        mode="tags"
        popupClassName={styles.dropdown}
        placeholder={t(
          'listDetailsPage.listEntriesForm.manualInputPlaceholder',
          { limit: manualInputValueCountLimit }
        )}
        value={manualInputValue}
        onChange={handleOnChange}
        data-test-id="addEntriesManuallySelect"
        // hack to prevent pressing enter deleting both duplicate values (if user inputs 123, enter, 123, then only the second 123 tag should get removed)
        // https://github.com/ant-design/ant-design/issues/20198
        open={false}
      />
      <FormError error={manualInputError} data-test-id="formErrorManualInput" />
      {manualInputValue.length > manualInputValueCountLimit && (
        <FormError
          error={t(
            'listDetailsPage.listEntriesForm.manualInputLimitReachedError',
            {
              limit: manualInputValueCountLimit,
            }
          )}
          data-test-id="formErrorManualInputLimit"
        />
      )}
      {clientValidation && !manualInputError && (
        <FormError
          error={clientValidation}
          data-test-id="formValidationError"
        />
      )}
    </>
  )
}

export default ManualEntriesForm
