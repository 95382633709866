import { FC, ReactNode } from 'react'
import { Popconfirm } from 'antd'
import { PopconfirmProps } from 'antd/lib/popconfirm'
import { useTranslation } from 'react-i18next'
import { Text, Space } from '@signifyd/components'
import styles from './DeleteListEntryPopconfirm.less'

type PartialPopconfirmProps = Omit<PopconfirmProps, 'title'>

interface Props extends PartialPopconfirmProps {
  count?: number
  children: ReactNode
  onConfirm: () => void
  onCancel?: () => void
}

const DeleteListEntryPopconfirm: FC<Props> = ({
  count,
  children,
  onConfirm,
  onCancel,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Popconfirm
      arrow={{ pointAtCenter: true }}
      destroyTooltipOnHide
      overlayClassName={`sig-popconfirm ${styles.popconfirm}`}
      title={
        <span data-test-id="deleteListEntryPopconfirm">
          <Text block weight="semibold">
            {t(
              'listDetailsPage.listEntriesTable.deleteListEntryPopconfirm.title',
              {
                count,
              }
            )}
          </Text>
          <Space size={12} />
          <Text block type="secondary" size="sm">
            {t(
              'listDetailsPage.listEntriesTable.deleteListEntryPopconfirm.description',
              {
                count,
              }
            )}
          </Text>
        </span>
      }
      icon={null}
      okButtonProps={{
        'data-test-id': 'confirmDeleteListItemButton',
      }}
      onConfirm={onConfirm}
      onCancel={onCancel}
      okText={t(
        'listDetailsPage.listEntriesTable.deleteListEntryPopconfirm.okText'
      )}
      cancelText={t(
        'listDetailsPage.listEntriesTable.deleteListEntryPopconfirm.cancelText'
      )}
      disabled={count === 0}
      {...props}
    >
      {children}
    </Popconfirm>
  )
}

export default DeleteListEntryPopconfirm
