import { i18nInstance } from '@signifyd/components'
import { ComparisonOperatorWithNot } from 'stores/conditionTree'

const getComparisonOperatorTranslation = ({
  isNot,
  name,
}: ComparisonOperatorWithNot): string => {
  return i18nInstance.t(`comparisonOperator.${name}.${isNot ? 'isNot' : 'is'}`)
}

export default getComparisonOperatorTranslation
