import {
  DecodedValueMap,
  NumberParam,
  SetQuery,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import { createEnumParam } from '@signifyd/utils'
import {
  LIST_PAGE_EXPIRATION_FILTER,
  LIST_PAGE_SORT_BY_FILTER,
  LIST_PAGE_TYPE_FILTER,
} from 'stores/predictionList'

export const listPageQueryParams = {
  teamId: NumberParam,
  type: withDefault(
    createEnumParam(LIST_PAGE_TYPE_FILTER),
    LIST_PAGE_TYPE_FILTER.ALL
  ),
  sortBy: withDefault(
    createEnumParam(LIST_PAGE_SORT_BY_FILTER),
    LIST_PAGE_SORT_BY_FILTER.LAST_UPDATE
  ),
  hasExpiration: withDefault(
    createEnumParam(LIST_PAGE_EXPIRATION_FILTER),
    LIST_PAGE_EXPIRATION_FILTER.ALL
  ),
}

export type ListPageQueryParams = DecodedValueMap<typeof listPageQueryParams>

export type UseListPageQueryParams = [
  ListPageQueryParams,
  SetQuery<typeof listPageQueryParams>
]

export const useListPageQueryParams = (): UseListPageQueryParams => {
  const [query, setQuery] = useQueryParams(listPageQueryParams)

  return [query, setQuery]
}
