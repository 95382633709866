import { FC } from 'react'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { Space, Text, Tooltip } from '@signifyd/components'
import { useStoreActions, useStoreState } from 'stores'
import RuleConfigCard from 'pages/PolicyConditionsPage/components/RuleConfigCard'
import { checkpointToActionMap } from 'core/constants'
import styles from './RuleRecommendedActionSelect.less'

interface Props {
  isEditing: boolean
}

const RuleRecommendedActionSelect: FC<Props> = ({ isEditing }) => {
  const { t } = useTranslation()
  const policy = useStoreState((state) => state.ruleModel.policy)
  const { setRuleRecommendedAction } = useStoreActions(
    (actions) => actions.ruleModel
  )

  if (!policy) {
    return null
  }

  const actions = checkpointToActionMap[policy.checkpoint]

  return (
    <>
      <div>
        <Text>
          {t('ruleConditionsPage.ruleRecommendedActionSelect.cardTitle')}
        </Text>
      </div>

      <Space size={8} />

      <RuleConfigCard
        label={t('ruleConditionsPage.ruleRecommendedActionSelect.cardLabel')}
      >
        <Tooltip
          enabled={isEditing}
          title={
            <div className={styles.tooltip}>
              {t(
                'ruleConditionsPage.ruleRecommendedActionSelect.disabledTooltip'
              )}
            </div>
          }
        >
          <Select
            data-test-id="policyActionSelect"
            placeholder={t(
              'ruleConditionsPage.ruleRecommendedActionSelect.placeholder'
            )}
            className={styles.select}
            value={policy?.ruleOutcome?.ruleRecommendedAction || undefined}
            onChange={setRuleRecommendedAction}
            disabled={isEditing}
            options={actions.map((action) => ({
              key: action,
              label: t(
                `ruleConditionsPage.ruleRecommendedActionSelect.options.${action}`
              ),
              value: action,
              'data-test-id': `actionOption-${action}`,
            }))}
          />
        </Tooltip>
      </RuleConfigCard>
      <Space size="xs" />
      <Text block size="xs" type="secondary">
        {t('ruleConditionsPage.ruleRecommendedActionSelect.footer')}
      </Text>
    </>
  )
}

export default RuleRecommendedActionSelect
