import { Component, ReactChild, ReactNode } from 'react'
import { joinClassNames } from '@signifyd/utils'
import './ExternalLink.less'

interface Props {
  url: string
  cssClass?: string
  children: ReactChild
  saveVisited?: boolean
  title?: string
  target?: '_blank' | '_self'
}

interface State {
  visited?: boolean
}

class ExternalLink extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  handleClick = (): void => {
    const { saveVisited } = this.props
    const { visited } = this.state
    if (saveVisited && !visited) {
      this.setState({ visited: true })
    }
  }

  render(): ReactNode {
    const { url, cssClass, children, title, target = '_blank' } = this.props
    const { visited } = this.state

    return (
      <a
        className={joinClassNames([
          'ExternalLink',
          cssClass,
          visited ? ' visited' : '',
        ])}
        href={url}
        target={target}
        rel="noopener noreferrer"
        draggable={false}
        onClick={this.handleClick}
        title={title}
      >
        {children}
      </a>
    )
  }
}

export default ExternalLink
