import { useState, FC } from 'react'
import { Select, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import {
  CHECKPOINT,
  PolicyMatchId,
  VALIDATE_RULE_ID_TYPE,
} from '@signifyd/http'
import { generateNewPolicyMatchIds } from 'core/hooks/useValidatePolicies/utils'
import { useStoreState } from 'stores'
import styles from './PolicyValidationInput.less'

const shouldShowValidation = (
  idTypeToValidate: VALIDATE_RULE_ID_TYPE,
  idsToValidate: Array<string>,
  currentUserInput: string
): boolean => {
  return (
    idTypeToValidate === VALIDATE_RULE_ID_TYPE.INVESTIGATION_ID &&
    (Number.isNaN(+currentUserInput) ||
      idsToValidate.some((id) => Number.isNaN(+id)))
  )
}

interface Props {
  loading?: boolean
  onSubmit: (policyMatchIds: Array<PolicyMatchId>) => void
  onChange: (idsToValidate: Array<string>, currentUserInput: string) => void
  'data-test-id': string
}

const PolicyValidationInput: FC<Props> = ({
  loading,
  onSubmit,
  onChange,
  'data-test-id': dataTestId,
}) => {
  const { t } = useTranslation()
  const checkpoint = useStoreState(
    (state) => state.ruleModel.policy?.checkpoint
  )

  // Because we want to allow submission the moment something is typed, we also need to store the user's current input
  const [currentUserInput, setCurrentUserInput] = useState('')
  const [idsToValidate, setIdsToValidate] = useState<Array<string>>([])
  const [idTypeToValidate, setInputIdType] = useState(
    checkpoint === CHECKPOINT.RETURN
      ? VALIDATE_RULE_ID_TYPE.RETURN_ID
      : VALIDATE_RULE_ID_TYPE.ORDER_ID
  )

  const onIdTypeChange = (inputId: VALIDATE_RULE_ID_TYPE): void => {
    setInputIdType(inputId)
    setCurrentUserInput('')
    setIdsToValidate([])
    onChange([], '')
  }

  const onSearchInputChange = (userInput: string): void => {
    setCurrentUserInput(userInput)
    onChange(idsToValidate, userInput)
  }

  const onValidationIdsChange = (idsToValidate: Array<string>): void => {
    setIdsToValidate(idsToValidate)

    // When there is a change event, user input is cleared from the select's search field, so clear it from state as well
    onChange(idsToValidate, '')
    setCurrentUserInput('')
  }

  const resetInput = (): void => {
    setIdsToValidate([])
    onChange([], '')
    setCurrentUserInput('')
  }

  const onClickSubmit = (): void => {
    // The ids that have become tags are validated by onChange - we need to also validate user input before we submit since we want to allow the user to click Run the moment they type something valid
    const allIds = [...idsToValidate, currentUserInput.trim()].filter(Boolean)

    if (allIds.length) {
      // Dedupes user input from created tags
      const dedupedIds = new Set(allIds)

      onSubmit(generateNewPolicyMatchIds([...dedupedIds], idTypeToValidate))
    }

    resetInput()
  }

  const showValidation = shouldShowValidation(
    idTypeToValidate,
    idsToValidate,
    currentUserInput
  )

  const submitDisabled =
    (idsToValidate.length === 0 && !currentUserInput.length) || showValidation

  return (
    <div className={styles.inputWrapper} data-test-id={dataTestId}>
      {checkpoint === CHECKPOINT.RETURN ? (
        <div data-test-id="returnIdLabel" className={styles.idTypeLabel}>
          <Text>{t('ruleValidationPage.idType.returnId')}</Text>
        </div>
      ) : (
        <Select
          data-test-id="policyValidationInputTypeSelect"
          disabled={loading}
          className={styles.idTypeSelect}
          value={idTypeToValidate}
          onChange={onIdTypeChange}
          options={[
            {
              label: t('ruleValidationPage.idType.orderId'),
              value: VALIDATE_RULE_ID_TYPE.ORDER_ID,
            },
            {
              label: t('ruleValidationPage.idType.caseId'),
              value: VALIDATE_RULE_ID_TYPE.INVESTIGATION_ID,
            },
          ]}
        />
      )}
      <div className={styles.selectWrapper}>
        <Select
          data-test-id="policyValidationInputIdInput"
          className={styles.input}
          suffixIcon={null}
          dropdownStyle={{
            display: 'none',
          }}
          allowClear
          tokenSeparators={[',', ' ']}
          mode="tags"
          popupClassName={styles.dropdown}
          placeholder={t(
            `ruleValidationPage.inputPlaceholders.${idTypeToValidate}`
          )}
          value={idsToValidate}
          onChange={onValidationIdsChange}
          onSearch={onSearchInputChange}
          virtual={false}
        />
        {showValidation && (
          <Text size="sm" className={styles.validationText}>
            {t('publishWithSimulatorPage.simulationResult.caseIdValidation')}
          </Text>
        )}
      </div>
      <Button
        data-test-id={`validateRuleButton-${dataTestId}`}
        type="primary"
        htmlType="submit"
        disabled={submitDisabled}
        loading={loading}
        onClick={onClickSubmit}
      >
        {t('publishWithSimulatorPage.simulationResult.validateConditionBtn')}
      </Button>
    </div>
  )
}

export default PolicyValidationInput
