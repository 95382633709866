import { FunctionComponent } from 'react'
import { isFinite } from 'lodash'
import styles from './RuleRank.less'

interface Props {
  rank: number | null
}

const RuleRank: FunctionComponent<Props> = ({ rank }) =>
  isFinite(rank) ? <div className={styles.rank}>{rank}</div> : null

export default RuleRank
