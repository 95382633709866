import { FC } from 'react'
import { Card, Radio } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import { AreaChartOutlined, TableOutlined } from '@ant-design/icons'
import { Space } from '@signifyd/components'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { DISTRIBUTION_TYPE } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import DistributionSankey from '../DistributionSankey'
import SimulationDistributionTable from '../SimulationDistributionTable'
import styles from './DistributionCard.less'

const DistributionCard: FC = () => {
  const { t } = useTranslation()
  const [filters, setFilters] = useQueryParams(PublishPageQueryParams)

  return (
    <Card
      title={t('publishWithSimulatorPage.distributionCard.title')}
      extra={
        <Radio.Group
          value={filters?.distributionType}
          onChange={(e) => {
            const distributionType = e.target.value

            setFilters({ distributionType })
          }}
        >
          <Radio.Button
            value={DISTRIBUTION_TYPE.GRAPH}
            data-analytics-id="graph-chart-radio"
          >
            <AreaChartOutlined />
          </Radio.Button>
          <Radio.Button
            value={DISTRIBUTION_TYPE.TABLE}
            data-analytics-id="table-chart-radio"
          >
            <TableOutlined />
          </Radio.Button>
        </Radio.Group>
      }
      className={styles.card}
    >
      <Space size="sm" />
      {filters.distributionType === DISTRIBUTION_TYPE.GRAPH ? (
        <DistributionSankey />
      ) : (
        <SimulationDistributionTable />
      )}
    </Card>
  )
}

export default DistributionCard
