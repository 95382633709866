import { FC, FormEvent, useState } from 'react'
import { EXPIRATION_TYPES, PREDICTION_LIST_TYPE } from '@signifyd/http'
import { Form } from '@ant-design/compatible'
import { Button, Col, InputNumber, Row, Select, message } from 'antd'
import { Text } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import styles from './ListExpiration.less'
import { expirationTypesPerListType } from './listExpirationUtils'
import useListExpirationForm from './useListExpirationForm'
import ExpirationFormHeader from './ExpirationFormTooltip'

export interface Props {
  predictionListId: number
  listType: PREDICTION_LIST_TYPE
  setVisible: (param: boolean) => void
}

const CreateListExpirationForm: FC<Props> = ({
  predictionListId,
  listType,
  setVisible,
}) => {
  const { t } = useTranslation()

  const [expiration, setExpiration] = useState<number | null>()
  const [expirationType, setExpirationType] = useState<EXPIRATION_TYPES>()

  const { submitExpiration, updatePredictionListHTTPStatus } =
    useListExpirationForm({ predictionListId })

  const { pending } = updatePredictionListHTTPStatus

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault()

    if (!expiration || !expirationType) {
      return
    }

    try {
      await submitExpiration({ value: expiration, type: expirationType })

      message.success(t('listDetailsPage.sidePanel.createExpirationSuccess'))
    } catch (e) {
      message.error(
        t('listDetailsPage.sidePanel.expirationForm.errorMessage.addExpiration')
      )
    }
  }

  return (
    <>
      <ExpirationFormHeader
        formLabel={t('listDetailsPage.sidePanel.addExpiration')}
        tooltipTitle={t('listDetailsPage.sidePanel.expirationFormTooltip')}
      />
      <Form onSubmit={handleSubmit}>
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <Text weight="semibold">
              {t(
                'listDetailsPage.sidePanel.expirationForm.formLabelExpireAfter',
                {
                  listType: t(
                    `listDetailsPage.sidePanel.expirationForm.listTypeTag.${listType}`
                  ),
                }
              )}
            </Text>
          </Col>
          <Col span={8}>
            <InputNumber
              min={1}
              className={styles.expirationInput}
              data-test-id="expirationInput"
              placeholder={t(
                'listDetailsPage.sidePanel.expirationForm.inputPlaceHolder'
              )}
              onChange={setExpiration}
              precision={0}
            />
          </Col>

          <Col span={16}>
            <Select
              placeholder={t(
                'listDetailsPage.sidePanel.expirationForm.selectPlaceholder'
              )}
              onChange={setExpirationType}
              data-test-id="expirationType"
            >
              {expirationTypesPerListType[listType].map((unit) => (
                <Select.Option key={unit} value={unit}>
                  {t(
                    `listDetailsPage.sidePanel.expirationForm.expirationTypes.${unit}`,
                    {
                      count: expiration ?? 0,
                    }
                  )}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row gutter={[16, 8]} className={styles.buttonRow}>
          <Button
            className={styles.expirationFormButtons}
            data-test-id="submitExpiration"
            block
            type="default"
            htmlType="submit"
            disabled={!expiration || !expirationType || pending}
            loading={pending}
          >
            {t('listDetailsPage.sidePanel.addExpiration')}
          </Button>
        </Row>

        <Row gutter={[16, 8]}>
          <Button
            type="link"
            block
            className={styles.expirationFormButtons}
            onClick={() => setVisible(false)}
          >
            {t('listDetailsPage.sidePanel.cancelExpiration')}
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default CreateListExpirationForm
