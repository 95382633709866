import { FC, useState } from 'react'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import DisplayExpirations from './DisplayExpirations'
import EditListExpirationForm from './EditListExpirationForm'
import { ExpirationDisplayDetails } from './listExpirationUtils'

interface Props {
  predictionListId: number
  expirations: Array<ExpirationDisplayDetails>
  type: PREDICTION_LIST_TYPE
}

const HasExpirations: FC<Props> = ({ expirations, type, predictionListId }) => {
  const [isEditFormVisible, setIsEditFormVisible] = useState(false)
  const [expiration, setExpiration] = useState<ExpirationDisplayDetails>()

  const editExpiration = (expiration: ExpirationDisplayDetails): void => {
    setIsEditFormVisible(true)
    setExpiration(expiration)
  }

  if (isEditFormVisible && expiration) {
    return (
      <EditListExpirationForm
        predictionListId={predictionListId}
        currentExpiration={expiration}
        predictionListType={type}
        setVisible={setIsEditFormVisible}
      />
    )
  }

  return (
    <DisplayExpirations
      expirations={expirations}
      predictionListType={type}
      editExpiration={editExpiration}
    />
  )
}

export default HasExpirations
