import { FC, ReactNode, useState } from 'react'
import { Select } from 'antd'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { InputLabel } from '@signifyd/components'
import { joinClassNames } from '@signifyd/utils'
import { ExperimentOutlined } from '@ant-design/icons'
import useUserTeams from 'core/hooks/useUserTeams'
import styles from './TeamSelect.less'

interface Props {
  className?: string
  label?: ReactNode
  selectedTeamId: string
  onChange: (teamId: string) => void
}

const TeamSelect: FC<Props> = ({
  className,
  label,
  selectedTeamId,
  onChange,
}) => {
  const [searchValue, setSearchValue] = useState('')

  // Passing searchValue into this isn't doing anything, all filtering is currently done via the Select with the optionFilterProp
  const liveTeams = useUserTeams().getFilteredTeams(searchValue, selectedTeamId)
  const { t } = useTranslation()

  const handleSearch = (text: string): void => {
    setSearchValue(text)
  }

  return (
    <div className={joinClassNames([styles.wrapper, className])}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        onSearch={debounce(handleSearch, 300)}
        showSearch
        data-test-id="teamSelect"
        className={styles.select}
        value={selectedTeamId}
        onChange={onChange}
        notFoundContent={t('common.teamSelect.notFoundContent')}
        optionFilterProp="text"
        options={liveTeams?.map(({ teamId, teamName, test }) => ({
          key: teamId,
          label: (
            <>
              {test && (
                <ExperimentOutlined className={styles.teamSelectTestIcon} />
              )}
              {teamName}
            </>
          ),
          // this shouldn't be needed, but setting optionFilterProp to "label" doesn't seem to filter correctly
          text: teamName,
          value: String(teamId),
        }))}
      />
    </div>
  )
}

export default TeamSelect
