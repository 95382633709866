import { Layout, Space } from '@signifyd/components'
import { FC } from 'react'
import AppNavBar from 'core/containers/AppNavBar'
import VariableTable from './VariableTable/VariableTable'
import VariablePageHeader from './VariablePageHeader/VariablePageHeader'

const { Content, NavBar } = Layout

const VariableManagerPage: FC = () => {
  return (
    <Layout>
      <NavBar>
        <AppNavBar />
      </NavBar>
      <Content>
        <div>
          <VariablePageHeader />
          <Space size="md" />
          <VariableTable />
        </div>
      </Content>
    </Layout>
  )
}

export default VariableManagerPage
