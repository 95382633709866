import { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Text, Space } from '@signifyd/components'
import Collapse from 'core/components/Collapse'
import styles from './RuleSuccessModalHelp.less'

const { Panel } = Collapse

const RuleSuccessModalHelp: FC = () => {
  const { t } = useTranslation()

  return (
    <Collapse defaultActiveKey={[]}>
      <Panel
        header={
          <Text type="secondary" size="sm" className={styles.helpHeader}>
            {t('ruleDashboardPage.ruleSuccessModalHelp.title')}
          </Text>
        }
        key="1"
        theme="transparent"
      >
        <Text type="secondary" size="sm" block>
          <Trans
            i18nKey="ruleDashboardPage.ruleSuccessModalHelp.description1"
            components={{
              bold: <Text type="secondary" weight="semibold" />,
            }}
          />
        </Text>
        <Space size="sm" />
        <Text type="secondary" size="sm" block>
          <Trans
            i18nKey="ruleDashboardPage.ruleSuccessModalHelp.description2"
            components={{
              bold: <Text type="secondary" weight="semibold" />,
            }}
          />
        </Text>
      </Panel>
    </Collapse>
  )
}

export default RuleSuccessModalHelp
