import { FC } from 'react'
import { SigTag, Text } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { colorIrisLight80, colorLapisLight80 } from '@signifyd/colors'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import { FileDoneOutlined } from '@ant-design/icons'

interface Props {
  type: PREDICTION_LIST_TYPE
  className?: string
  bordered?: boolean
}

type ColorHex = `#${string}`
interface StyleMap {
  color: ColorHex
  showIcon: boolean
}

export const standardListTypeStyle = {
  color: colorLapisLight80,
  showIcon: false,
}

export const abuseListTypeStyle = {
  color: colorIrisLight80,
  typeClass: '',
  showIcon: true,
}

export const getTypeToStyles = (type: PREDICTION_LIST_TYPE): StyleMap =>
  ['PROMO_ABUSE', 'RESELLER_ABUSE'].includes(type)
    ? abuseListTypeStyle
    : standardListTypeStyle

const PredictionListTypeTag: FC<Props> = ({ className, type }) => {
  const { t } = useTranslation()
  const { showIcon, color } = getTypeToStyles(type)

  if (!(type in PREDICTION_LIST_TYPE)) {
    return null
  }

  return (
    <SigTag color={color} type="primary" className={className}>
      {showIcon && <FileDoneOutlined />}
      <Text size="sm">{t(`listCommon.listTypeTag.${type}`)}</Text>
    </SigTag>
  )
}

export default PredictionListTypeTag
