import { FC } from 'react'
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Select, Popconfirm, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import {
  LOGICAL_OPERATOR,
  LOGICAL_OPERATOR_OPTIONS,
} from 'stores/conditionTree'
import styles from './LogicalOperatorSelect.less'

interface Props {
  inner?: boolean
  value?: LOGICAL_OPERATOR
  onSelect: (operator: LOGICAL_OPERATOR) => void
  onDelete?: () => void
}

const LogicalOperatorSelect: FC<Props> = ({
  inner = false,
  value,
  onSelect,
  onDelete,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.selectWrapper}>
      <Text size="sm" className={styles.match}>
        {t('ruleConditionsPage.conditions.match')}
      </Text>
      <Select
        className={styles.select}
        value={value}
        onSelect={onSelect}
        data-test-id={inner ? `subConditionOperator` : 'conditionOperator'}
        options={LOGICAL_OPERATOR_OPTIONS.filter(({ value }) =>
          value === LOGICAL_OPERATOR.numberOfConditions ? inner : true
        ).map(({ value }) => ({
          key: value,
          value,
          label: t(`ruleConditionsPage.conditions.options.${value}`),
          'data-test-id': inner
            ? `subConditionOption-${value}`
            : `conditionOption-${value}`,
        }))}
      />
      {inner && (
        <Tooltip
          placement="topLeft"
          trigger="hover"
          className={styles.tooltip}
          arrow={{ pointAtCenter: true }}
          overlayInnerStyle={{ width: '350px' }}
          title={
            <ul className={styles.tooltipList}>
              <li>{t(`ruleConditionsPage.conditions.tooltip.all`)}</li>
              <li>{t(`ruleConditionsPage.conditions.tooltip.or`)}</li>
              <li>{t(`ruleConditionsPage.conditions.tooltip.partial`)}</li>
            </ul>
          }
        >
          <QuestionCircleOutlined
            data-test-id="matchHelperTooltip"
            data-analytics-id="match-helper-tooltip"
          />
        </Tooltip>
      )}
      {onDelete && (
        <Popconfirm
          placement="bottomLeft"
          arrow={{ pointAtCenter: true }}
          icon={null}
          title={
            <div className={styles.popconfirmContent}>
              <Text weight="semibold" block className={styles.popconfirmTitle}>
                {t(
                  'ruleConditionsPage.conditions.discardConditionPopconfirm.title'
                )}
              </Text>
              <Text size="sm" block>
                {t(
                  'ruleConditionsPage.conditions.discardConditionPopconfirm.description'
                )}
              </Text>
            </div>
          }
          okText="Delete"
          onConfirm={onDelete}
          overlayClassName="sig-popconfirm"
        >
          <DeleteOutlined
            data-test-id="deleteIcon"
            className={styles.deleteIcon}
          />
        </Popconfirm>
      )}
    </div>
  )
}

export default LogicalOperatorSelect
