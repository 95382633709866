import { CreatePredictionListPayload } from '@signifyd/http'
import { ModalProps } from 'antd/es/modal'

export enum STEP {
  one = '1',
  two = '2',
  three = '3',
}

export interface GetModalPropsByStep {
  [STEP.one]: Partial<ModalProps>
  [STEP.two]: Partial<ModalProps>
  [STEP.three]: Partial<ModalProps>
}

export type PartialCreateListPayload = Omit<
  CreatePredictionListPayload,
  'teamId'
>
