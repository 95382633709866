import { FC, ReactNode, ComponentType } from 'react'
import { Space, T4, SearchResultsIcon, IconProps } from '@signifyd/components'
import { colorYonder } from '@signifyd/colors'
import styles from './NoResults.less'

interface Props {
  icon?: ComponentType<IconProps>
  title: ReactNode
  description: ReactNode
}

const NoResults: FC<Props> = ({
  icon: Icon = SearchResultsIcon,
  title,
  description,
}) => (
  <div className={styles.wrapper}>
    <Space size="lg" />
    <Icon fill={colorYonder} className={styles.icon} />
    <Space size="md" />
    <T4>{title}</T4>
    <div>{description}</div>
    <Space size="lg" />
  </div>
)

export default NoResults
