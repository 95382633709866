import common from './common'
import ruleCommon from './ruleCommon'
import ruleConditionsPage from './ruleConditionsPage'
import ruleValidationPage from './ruleValidationPage'
import ruleDashboardPage from './ruleDashboardPage'
import ruleSummaryPage from './ruleSummaryPage'
import listCommon from './listCommon'
import listPage from './listPage'
import listDetailsPage from './listDetailsPage'
import publishWithSimulatorPage from './publishWithSimulatorPage'
import checkpointAction from './checkpointAction'
import comparisonOperator from './comparisonOperators'
import groupFilterSelect from './groupFilterSelect'
import variableGroupPage from './variableGroupPage'
import variablePage from './variablePage'

export default {
  common,
  ruleCommon,
  ruleConditionsPage,
  ruleValidationPage,
  ruleDashboardPage,
  ruleSummaryPage,
  listCommon,
  listPage,
  listDetailsPage,
  publishWithSimulatorPage,
  checkpointAction,
  comparisonOperator,
  groupFilterSelect,
  variableGroupPage,
  variablePage,
}
