import { FC } from 'react'
import { joinClassNames } from '@signifyd/utils'
import styles from './DraggableIcon.less'

interface Props {
  className?: string
}

// TODO FET-1816 https://signifyd.atlassian.net/browse/FET-1816
// Move to sig-components
const DraggableIcon: FC<Props> = ({ className }) => (
  <span className={joinClassNames([styles.iconWrapper, className])}>
    <svg width="16" height="24" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-4)" fill="none" fillRule="evenodd">
        <rect fill="#9FADBF" x="4" y="9" width="16" height="1" rx=".5" />
        <rect fill="#9FADBF" x="4" y="14" width="16" height="1" rx=".5" />
        <path
          d="M9 3.5l3-3m3 3l-3-3m-3 20l3 3m3-3l-3 3"
          stroke="#9FADBF"
          strokeLinecap="round"
        />
      </g>
    </svg>
  </span>
)

export default DraggableIcon
