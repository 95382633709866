import { CustomLayerProps } from '@nivo/line'
import { Fragment } from 'react'
import { getPointSymbol } from 'pages/DashboardPage/components/PolicyReporting/PolicyReportingChart.utils'

const Points = ({ pointSize = 0, points }: CustomLayerProps): JSX.Element => (
  <g>
    {points.map(({ id, serieId, x, y }) => (
      <Fragment key={id}>
        {getPointSymbol(
          serieId.toString(),
          x - pointSize / 2,
          y - pointSize / 2
        )}
      </Fragment>
    ))}
  </g>
)

export default Points
