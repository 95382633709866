import { Layout } from '@signifyd/components'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import useUserTeams from 'core/hooks/useUserTeams'
import useTeamId from 'pages/DashboardPage/hooks/useTeamId'
import useSelectedTeamCheckpoints from 'pages/DashboardPage/queries/useSelectedTeamCheckpoints'
import { useDashboardPageFilters } from 'pages/DashboardPage/hooks/useDashboardPageFilters'
import TeamSelect from 'core/components/TeamSelect'
import CreatePolicyButton from '../CreatePolicyButton'
import PublishSettingsButton from '../PublishSettingsButton/PublishSettingsButton'

const { Header } = Layout

interface Props {
  isCreatePolicyModalVisible: boolean
}

const DashboardHeader: FC<Props> = ({ isCreatePolicyModalVisible }) => {
  const { t } = useTranslation()

  const [, setFilters] = useDashboardPageFilters()

  const teamId = useTeamId()

  const { liveTeams = [] } = useUserTeams()
  const { data: selectedTeamCheckpoints = [] } = useSelectedTeamCheckpoints()

  if (!teamId) {
    return <Header title={t('ruleCommon.appName')} theme="light" />
  }

  const hasCheckpointAccess = !!selectedTeamCheckpoints.length
  const hasTeamSelect = liveTeams.length > 1

  return (
    <Header
      title={t('ruleCommon.appName')}
      theme="light"
      extra={
        <>
          {hasTeamSelect && (
            <TeamSelect
              label={t('common.teamSelect.label')}
              selectedTeamId={teamId.toString()}
              onChange={(teamId) => {
                setFilters({ teamId: +teamId })
              }}
            />
          )}
          {hasCheckpointAccess && (
            <>
              <PublishSettingsButton
                teamId={teamId}
                teamCheckpoints={selectedTeamCheckpoints}
              />

              <CreatePolicyButton
                icon="plus"
                defaultModalVisible={isCreatePolicyModalVisible}
                teamCheckpoints={selectedTeamCheckpoints}
              >
                {t('ruleCommon.createRuleModal.triggerBtnText')}
              </CreatePolicyButton>
            </>
          )}
        </>
      }
      marginBottom="none"
    />
  )
}

export default DashboardHeader
