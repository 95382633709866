import { FC } from 'react'
import { Col, Row } from 'antd'
import { useQueryParams } from 'use-query-params'
import { Space } from '@signifyd/components'
import useGetRuleSet from 'core/queries/useGetRuleSet/useGetRuleSet'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { useStoreState } from 'stores'
import { hasMatchingRuleIds } from 'stores/simulation/simulation.store.utils'
import TotalImpactCard from '../TotalImpactCard'
import ImpactPerPolicyCard from '../ImpactPerPolicyCard'
import DistributionCard from '../DistributionCard'
import SimulationResultWarning from '../SimulationResultWarning'
import SimulationResultInfoMessage from '../SimulationResultInfoMessage'

const SimulationResultContainer: FC = ({ children }) => {
  const [{ ruleSetId }] = useQueryParams(PublishPageQueryParams)

  const { data: ruleSet } = useGetRuleSet(ruleSetId)
  const { ruleSimulation } = useStoreState((state) => state.simulationModel)

  const matchingRuleIds = hasMatchingRuleIds(ruleSimulation, ruleSet)

  if (!ruleSimulation) {
    return null
  }

  const showInfoMessage =
    ruleSimulation.simulationSize === ruleSimulation.simulationSizeLimit

  return (
    <div data-test-id="simulationResultContainer">
      <Space size="lg" />
      {children}
      {showInfoMessage && (
        <SimulationResultInfoMessage
          simulationSizeLimit={ruleSimulation.simulationSizeLimit}
        />
      )}
      <SimulationResultWarning hasMatchingRuleIds={matchingRuleIds} />
      {!showInfoMessage && matchingRuleIds && <Space size="lg" />}
      <Row align="stretch" gutter={[32, 32]}>
        <Col span={24}>
          <TotalImpactCard ruleSimulation={ruleSimulation} />
        </Col>
        <Col span={24}>
          <DistributionCard />
        </Col>
        <Col span={24}>
          <ImpactPerPolicyCard />
        </Col>
      </Row>
      <Space size="lg" />
    </div>
  )
}

export default SimulationResultContainer
