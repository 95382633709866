import {
  GetRulesParams,
  GET_RULES_ORDER_BY,
  RuleResponse,
  RULE_STAGE,
  listRules,
} from '@signifyd/http'
import { DefinedUseQueryResult, useQuery } from '@tanstack/react-query'
import { i18nInstance } from '@signifyd/components'
import { message } from 'antd'
import { AxiosError } from 'axios'
import { GET_RULES_LIMIT } from 'core/constants'
import { getHTTPErrorStatus } from 'core/http'
import { useDashboardPageFilters } from '../../hooks/useDashboardPageFilters'

export const GET_DASHBOARD_POLICIES_QUERY_KEY = 'dashboardPolicies'

const ASCENDING_VALUE_BY_ORDER_BY = {
  [GET_RULES_ORDER_BY.SORT_RANK]: true,
  [GET_RULES_ORDER_BY.SORT_CREATED_AT]: false,
  [GET_RULES_ORDER_BY.SORT_SCHEDULE_START]: false,
  [GET_RULES_ORDER_BY.SORT_SCHEDULE_END]: false,
  [GET_RULES_ORDER_BY.SORT_HIT_COUNT]: false,
}

export interface DashboardPolicies {
  publishedPolicies: Array<RuleResponse>
  otherPolicies: Array<RuleResponse>
}

const useGetDashboardPolicies =
  (): DefinedUseQueryResult<DashboardPolicies> => {
    const [filters] = useDashboardPageFilters()
    const { teamId, checkpoint } = filters

    const publishedRulesQueryParams = {
      orderBy: filters.publishedOrderBy,
      ascending: ASCENDING_VALUE_BY_ORDER_BY[filters.publishedOrderBy],
      live: true,
      teamIds: teamId,
      limit: GET_RULES_LIMIT,
      checkpoints: checkpoint,
    } as GetRulesParams

    const otherRulesQueryParams = {
      orderBy: filters.othersOrderBy,
      ascending: ASCENDING_VALUE_BY_ORDER_BY[filters.othersOrderBy],
      live: false,
      stages: `${RULE_STAGE.NEW},${RULE_STAGE.PUBLISHED}`,
      includeDeleted: true,
      teamIds: teamId,
      limit: GET_RULES_LIMIT,
      checkpoints: filters.checkpoint,
    } as GetRulesParams

    const getPolicies = async (): Promise<DashboardPolicies> => {
      const [
        {
          data: { data: publishedPolicies },
        },
        {
          data: { data: otherPolicies },
        },
      ] = await Promise.all([
        listRules(publishedRulesQueryParams),
        listRules(otherRulesQueryParams),
      ])

      return {
        publishedPolicies,
        otherPolicies,
      }
    }

    return useQuery<DashboardPolicies, AxiosError>({
      queryKey: [
        GET_DASHBOARD_POLICIES_QUERY_KEY,
        publishedRulesQueryParams,
        otherRulesQueryParams,
      ],
      queryFn: getPolicies,
      onError: (error) => {
        const status = getHTTPErrorStatus(
          error,
          i18nInstance.t('ruleCommon.fallbackErrorMessage.filterRules')
        )

        message.error({ content: status.error })
      },
      enabled: !!teamId && !!checkpoint,
      initialData: {
        publishedPolicies: [],
        otherPolicies: [],
      },
    })
  }

export default useGetDashboardPolicies
