import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { FC } from 'react'
import { Text } from '@signifyd/components'
import styles from './ListExpiration.less'

export interface Props {
  formLabel: string
  tooltipTitle: string
}

const ExpirationFormHeader: FC<Props> = ({ formLabel, tooltipTitle }) => {
  return (
    <div className={styles.manageExpirationsTooltip}>
      {formLabel}
      <Tooltip
        placement="bottom"
        title={<Text className={styles.expirationTooltip}>{tooltipTitle}</Text>}
      >
        <QuestionCircleOutlined />
      </Tooltip>
    </div>
  )
}

export default ExpirationFormHeader
