import { useQuery, UseQueryResult } from '@tanstack/react-query'
import {
  listPredictionListEntries,
  PredictionListEntry,
  ListPredictionListEntriesParams,
  PREDICTION_LIST_ENTRY_SORT_BY,
  ListPredictionListEntriesMeta,
} from '@signifyd/http'
import { message } from 'antd'
import { i18nInstance } from '@signifyd/components'
import { PREDICTION_LIST_ENTRIES_QUERY_KEY } from 'core/queries/queryKeys'
import { GET_LIST_ENTRIES_LIMIT } from 'core/constants'

export interface GetListEntriesFilters {
  page?: number | null
  pageSize?: number | null
  ascending?: boolean | null
  orderBy?: PREDICTION_LIST_ENTRY_SORT_BY | null
  searchTerm?: string | null
}

const getSearchParams = ({
  page,
  pageSize,
  orderBy,
  searchTerm,
  ascending,
}: Partial<GetListEntriesFilters>): ListPredictionListEntriesParams => {
  const searchParams: ListPredictionListEntriesParams = {
    offset: page && pageSize ? page * pageSize : 0,
    orderBy: orderBy || undefined,
    limit: pageSize || GET_LIST_ENTRIES_LIMIT,
    searchTerm: searchTerm || undefined,
    isExpired: false,
    ascending: !!ascending,
  }

  return searchParams
}

export interface PredictionListEntriesReturnValues {
  predictionListEntriesData?: Array<PredictionListEntry>
  predictionListEntriesMeta: ListPredictionListEntriesMeta
}

const useGetPredictionListEntries = (
  listId?: number | string,
  filters?: GetListEntriesFilters | null
): UseQueryResult<PredictionListEntriesReturnValues> => {
  const listIdString = listId?.toString()

  return useQuery({
    queryKey: [PREDICTION_LIST_ENTRIES_QUERY_KEY, listIdString, filters],
    enabled: !!listIdString,
    queryFn: async () => {
      const { data: predictionList } = await listPredictionListEntries(
        listIdString!,
        getSearchParams(filters ?? {})
      )

      return {
        predictionListEntriesData: predictionList?.data,
        predictionListEntriesMeta: predictionList?.meta,
      }
    },
    onError: () => {
      message.error(
        i18nInstance.t('listCommon.fallbackErrorMessage.getCurrentList')
      )
    },
  })
}

export default useGetPredictionListEntries
