import { FC, useState } from 'react'
import { Menu, Popover } from 'antd'
import { useTranslation } from 'react-i18next'
import { TableDropdownIcon } from 'core/components/Icons'
import DeleteListEntryPopconfirm from 'pages/ListDetailsPage/components/DeleteListEntryPopconfirm'
import styles from './ListEntryDropdown.less'

interface Props {
  onClickDelete: () => void
}

const ListEntryDropdown: FC<Props> = ({ onClickDelete }) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)

  return (
    <Popover
      placement="left"
      trigger={['click']}
      content={
        <Menu>
          <DeleteListEntryPopconfirm
            count={1}
            onConfirm={onClickDelete}
            onCancel={() => setVisible(false)}
            placement="left"
          >
            <Menu.Item
              key="delete"
              className={styles.menuItem}
              data-test-id="listEntryDropdownDeleteTrigger"
            >
              {t('listDetailsPage.listEntriesTable.entryDropdown.delete')}
            </Menu.Item>
          </DeleteListEntryPopconfirm>
        </Menu>
      }
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      overlayInnerStyle={{ padding: 0 }}
      open={visible}
      onOpenChange={(visible) => setVisible(visible)}
    >
      <TableDropdownIcon data-test-id="listEntryDropdown" />
    </Popover>
  )
}

export default ListEntryDropdown
