import { FC, ReactElement, useMemo } from 'react'
import { VariableFrontEndType } from '@signifyd/http'
import { Input, Select } from 'antd'
import { i18nInstance } from '@signifyd/components'
import {
  BOOLEAN_AS_STRING,
  COMPARISON_OPERATOR,
  ConditionTreeLeafNode,
  ConditionTreeLeafNodeValue,
  LIST_COMPARISON_OPERATOR_NAME,
} from 'stores/conditionTree'
import { isPositiveDecimal } from 'core/utils/number'
import ValueForListSelect from './PredictionListSelect/ValueForListSelect'
import { BetweenElement } from './BetweenElement'
import styles from './ConditionElement.less'
import {
  ComparisonOperator,
  SubConditionNode,
} from '../ConditionTreeRule/ConditionTreeRule.types'

const BOOLEAN_AS_STRING_OPTIONS = [
  {
    label: 'True',
    value: BOOLEAN_AS_STRING.true,
  },
  {
    label: 'False',
    value: BOOLEAN_AS_STRING.false,
  },
]

export interface Props {
  onChangeValue: (value: ConditionTreeLeafNodeValue) => void
  featureType: VariableFrontEndType
  operator: ComparisonOperator
  value?: string
  leafNode: SubConditionNode
  ruleTeamId: number
}

export type ConditionProps = Pick<
  Props,
  'featureType' | 'value' | 'leafNode' | 'onChangeValue'
>

const PREDICTION_LIST_CONDITIONS = [
  VariableFrontEndType.STRING,
  VariableFrontEndType.STRING_LIST,
  VariableFrontEndType.NUMBER,
  VariableFrontEndType.BIN,
]

const getConditionElement = (props: ConditionProps): ReactElement => {
  const { t } = i18nInstance
  const { featureType, value, onChangeValue, leafNode } = props
  const values = Array.isArray(leafNode.value) ? leafNode.value : []

  if (leafNode.operator === COMPARISON_OPERATOR.isBetween) {
    return <BetweenElement {...props} />
  }

  const elementMap: Record<VariableFrontEndType, ReactElement> = {
    [VariableFrontEndType.STRING]: (
      <Input
        placeholder={t(
          'ruleConditionsPage.conditionInputs.stringInput.placeholder'
        )}
        className={styles.input}
        value={value}
        onChange={(e) => onChangeValue(e.target.value)}
        data-test-id="stringInputValue"
        data-analytics-id="string-input-value"
      />
    ),
    [VariableFrontEndType.STRING_LIST]: (
      <Input
        placeholder={t(
          'ruleConditionsPage.conditionInputs.stringInput.placeholder'
        )}
        className={styles.input}
        value={value}
        onChange={(e) => onChangeValue(e.target.value)}
        data-test-id="stringListInputValue"
        data-analytics-id="string-list-input-value"
      />
    ),
    [VariableFrontEndType.NUMBER]: (
      <Input
        placeholder={t(
          'ruleConditionsPage.conditionInputs.numberInput.placeholder'
        )}
        className={styles.input}
        value={value}
        onChange={(e) => {
          const { value } = e.target

          if (value === '' || isPositiveDecimal(value)) {
            onChangeValue(value)
          }
        }}
        data-test-id="numberInputValue"
        data-analytics-id="number-input-value"
      />
    ),
    [VariableFrontEndType.BIN]: (
      <Input
        placeholder={t(
          'ruleConditionsPage.conditionInputs.binInput.placeholder'
        )}
        className={styles.input}
        value={value}
        onChange={(e) => {
          const { value } = e.target

          if (value === '' || isPositiveDecimal(value)) {
            onChangeValue(value)
          }
        }}
        data-test-id="binInput"
        data-analytics-id="bin-input"
      />
    ),
    [VariableFrontEndType.BOOLEAN]: (
      <Select
        data-test-id="booleanValueSelect"
        data-analytics-id="boolean-value-select"
        className={styles.input}
        placeholder={t(
          'ruleConditionsPage.conditionInputs.valueSelect.placeholder'
        )}
        value={(leafNode.value as BOOLEAN_AS_STRING) ?? undefined}
        onChange={(value: BOOLEAN_AS_STRING) => {
          onChangeValue(value)
        }}
        options={BOOLEAN_AS_STRING_OPTIONS.map(({ value, label }) => ({
          value,
          label,
          'data-test-id': `boolean-${value}`,
        }))}
      />
    ),
    [VariableFrontEndType.CATEGORICAL]: (
      <Select
        data-test-id="conditionInput"
        className={styles.input}
        optionLabelProp="label"
        placeholder={t(
          'ruleConditionsPage.conditionInputs.valueSelect.placeholder'
        )}
        showSearch
        notFoundContent={t(
          'ruleConditionsPage.conditionInputs.valueSelect.notFoundContent'
        )}
        mode="multiple"
        optionFilterProp="label"
        value={values}
        virtual
        onChange={onChangeValue}
        options={leafNode.ruleFeature?.categorical?.options.map(
          ({ value, label }) => ({ value, label })
        )}
      />
    ),
  }

  return elementMap[featureType]
}

const ConditionElement: FC<Props> = ({
  onChangeValue,
  featureType,
  operator,
  value,
  leafNode,
  ruleTeamId,
}) => {
  const conditionElement = useMemo(
    () => getConditionElement({ featureType, value, leafNode, onChangeValue }),
    [featureType, value, leafNode, onChangeValue]
  )

  if (operator === COMPARISON_OPERATOR.isEmptyAndMeaningless) {
    return null
  }

  const isListCondition =
    PREDICTION_LIST_CONDITIONS.includes(featureType) &&
    operator === LIST_COMPARISON_OPERATOR_NAME &&
    !!leafNode?.ruleFeature?.predictionListFeature

  if (isListCondition) {
    return (
      <ValueForListSelect
        ruleTeamId={ruleTeamId}
        leafNode={leafNode as ConditionTreeLeafNode}
        onChangeValue={onChangeValue}
      />
    )
  }

  return conditionElement
}

export default ConditionElement
