import { FC } from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import {
  SimulationIcon,
  T4,
  Space,
  StretchToPageBottom,
} from '@signifyd/components'
import SimulationDatePicker from 'pages/PublishWithSimulatorPage/components/SimulationDatePicker'
import { SimulationDatePickerProps } from 'pages/PublishWithSimulatorPage/containers/SimulationContainer/SimulationContainer'
import { useTypedRouteParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { useStoreActions, useStoreState } from 'stores'
import { simulationDateFormat } from 'stores/simulation/simulation.constants'
import styles from './CreateSimulationContainer.less'

const CreateSimulationContainer: FC<SimulationDatePickerProps> = ({
  dateRangeValue,
  setDateRange,
  userTimeZone,
}: SimulationDatePickerProps) => {
  const { t } = useTranslation()

  const routeParams = useTypedRouteParams()
  const [filters] = useQueryParams(PublishPageQueryParams)
  const { ruleSetId } = filters

  const { createRuleSimulation } = useStoreActions(
    (actions) => actions.simulationModel
  )
  const isLoading = useStoreState(
    (state) => state.simulationModel.isLoading.createRuleSimulation
  )

  const [simulationStartDate, simulationEndDate] = dateRangeValue

  const createSimulationForTeam = (teamId: number): void => {
    // If we don't clone the input then the start date will show incorrectly in state
    const startDate = simulationStartDate
      ?.clone()
      .startOf('day')
      .utc()
      .format(simulationDateFormat)

    const endDate = simulationEndDate
      ?.clone()
      .endOf('day')
      .utc()
      .format(simulationDateFormat)

    const editedRuleId = filters.editedPolicyId ?? undefined

    if (startDate && endDate) {
      createRuleSimulation({
        ruleSetId: ruleSetId!,
        teamId,
        startDate,
        endDate,
        editedRuleId,
      })
    }
  }

  if (!routeParams) {
    return null
  }

  const { teamId, checkpoint } = routeParams

  return (
    <StretchToPageBottom centerChildren className={styles.container}>
      <div data-test-id="createSimulationContainer">
        <SimulationIcon className={styles.icon} />
        <Space size="lg" />
        <T4>{t('publishWithSimulatorPage.createSimulation.title')}</T4>
        <div className={styles.description}>
          {t(
            `publishWithSimulatorPage.createSimulation.description.${checkpoint!}`
          )}
        </div>
        <Space size="lg" />
        <Space size="sm" />
        <SimulationDatePicker
          className={styles.rangePicker}
          value={dateRangeValue}
          onChange={(dateRange) => setDateRange(dateRange)}
          userTimeZone={userTimeZone}
        />
        <Space size="lg" />
        <Button
          className={styles.button}
          type="primary"
          loading={isLoading}
          disabled={
            !(simulationStartDate && simulationEndDate) ||
            isLoading ||
            !ruleSetId ||
            !teamId
          }
          onClick={() => createSimulationForTeam(teamId!)}
          data-test-id="runSimulationButton"
          data-analytics-id="run-simulation-button"
        >
          {t('publishWithSimulatorPage.createSimulation.runSimulationBtn')}
        </Button>
      </div>
    </StretchToPageBottom>
  )
}

export default CreateSimulationContainer
