import { FunctionComponent, ReactNode } from 'react'
import { joinClassNames } from '@signifyd/utils'
import { PlusCircleFilled } from '@ant-design/icons'
import styles from './ToggleBtn.less'

interface Props {
  className?: string
  children: ReactNode
  onClick?: () => void
}

const ToggleBtn: FunctionComponent<Props> = ({
  className,
  children,
  onClick,
}) => {
  return (
    <div
      className={joinClassNames([className, styles.toggleBtn])}
      onClick={onClick || undefined}
    >
      <PlusCircleFilled className={styles.icon} />
      <div className={styles.label}>{children}</div>
    </div>
  )
}

export default ToggleBtn
