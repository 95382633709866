import { FC, useEffect } from 'react'
import { LeftOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { colorGlitter } from '@signifyd/colors'
import { Layout, PageSpinner, Space, SigTag, Text } from '@signifyd/components'
import { CHECKPOINT } from '@signifyd/http'
import AppNavBar from 'core/containers/AppNavBar'
import RuleSummaryCard from 'core/components/RuleSummaryCard'
import TestTeamBanner from 'core/components/TestTeamBanner'
import useLoadPolicyForValidation from 'core/hooks/useLoadAndValidatePolicy'
import { getPredictionListParamsForTeam } from 'core/http'
import { backOrDefault } from 'core/utils/router'
import { getDefaultDashboardUrl } from 'pages/DashboardPage/DashboardPage.utils'
import RuleValidationResult from 'pages/PolicySummaryPage/components/RuleValidationResult'
import { useStoreState, useStoreActions } from 'stores'
import {
  isConditionTreeValid,
  isPredicateUsingPredictionListFeatures,
} from 'stores/conditionTree'
import useTimeframe from 'core/hooks/useTimeframe'
import PolicyReports from './components/PolicyReports/PolicyReports'
import SummaryHeaderActions from './components/SummaryHeaderActions/SummaryHeaderActions'
import styles from './PolicySummaryPage.less'

const { Content, NavBar } = Layout

const PolicySummaryPage: FC = () => {
  const { ruleId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const { policy, conditionTree, predictionListMap } = useStoreState(
    (state) => state.ruleModel
  )

  const isPolicyValid =
    !!policy?.ruleOutcome?.ruleRecommendedAction &&
    isConditionTreeValid(conditionTree)

  const { listPredictionLists } = useStoreActions(
    (actions) => actions.ruleModel
  )

  const { t } = useTranslation()
  const { timeframe, updateTimeframe } = useTimeframe()

  useLoadPolicyForValidation(ruleId, false, false)

  useEffect(() => {
    if (
      !predictionListMap &&
      policy &&
      // only get lists if policy is using list id feature
      isPredicateUsingPredictionListFeatures(policy.predicate)
    ) {
      const params = getPredictionListParamsForTeam(policy.teamId)

      listPredictionLists(params)
    }
  }, [predictionListMap, listPredictionLists, policy])

  if (!policy || !conditionTree) {
    return (
      <>
        <NavBar>
          <AppNavBar teamId={policy?.teamId} />
        </NavBar>
        <PageSpinner />
      </>
    )
  }

  return (
    <Layout>
      <NavBar>
        <AppNavBar teamId={policy?.teamId} />
      </NavBar>
      <TestTeamBanner
        teamId={policy.teamId}
        text={t('common.testTeamBanner.policy')}
      />
      <Content>
        <PageHeader
          className={styles.pageHeader}
          backIcon={<LeftOutlined data-test-id="page-header-back-button" />}
          onBack={() =>
            backOrDefault(
              location?.state,
              `${getDefaultDashboardUrl(policy, timeframe)}`,
              navigate
            )
          }
          title={
            <>
              <SigTag
                type="secondary"
                className={styles.tag}
                color={colorGlitter}
              >
                <Text size="sm">
                  {t('common.checkpoint.label')}:{' '}
                  {t(`common.checkpoint.${policy.checkpoint}`)}
                </Text>
              </SigTag>
              <span>{policy.name}</span>
              <SummaryHeaderActions policy={policy} />
            </>
          }
        />
        <Row gutter={32}>
          <Col span={8}>
            <RuleSummaryCard
              isRuleValid={isPolicyValid}
              conditionTree={conditionTree}
              recommendedAction={policy.ruleOutcome?.ruleRecommendedAction}
              rankInActiveRuleSet={policy.rankInActiveRuleSet}
            />
          </Col>
          <Col span={16}>
            {policy.checkpoint !== CHECKPOINT.LOGIN && (
              <>
                <PolicyReports
                  policyId={policy.ruleId}
                  checkpoint={policy.checkpoint}
                  timeframe={timeframe}
                  setTimeframe={updateTimeframe}
                />
                <Space size="lg" />
              </>
            )}
            <RuleValidationResult
              data-test-id="validationResultMatching"
              title={t('ruleSummaryPage.ruleValidationResult.matching.title')}
              validationResults={policy.matchingPolicyMatchIds}
              checkpoint={policy.checkpoint}
            />
            <Space size="lg" />
            <RuleValidationResult
              data-test-id="validationResultNonMatching"
              title={t(
                'ruleSummaryPage.ruleValidationResult.nonMatching.title'
              )}
              validationResults={policy.nonMatchingPolicyMatchIds}
              checkpoint={policy.checkpoint}
            />
            <Space size="sm" />
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default PolicySummaryPage
