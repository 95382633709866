export default {
  header: {
    title: {
      CHECKOUT: 'Publishing settings for checkout',
      LOGIN: 'Publishing settings for login',
      RETURN: 'Publishing settings for return',
    },
    cancelBtn: 'Cancel',
    publishBtn: 'Publish now',
    publishConfirmation: 'Are you sure?',
    publishRecommendation:
      'We recommend simulating your policy impact before publishing to minimize unexpected behavior.',
    publishPopconfirmOkText: 'Continue',
    publishPopconfirmCancelText: 'Cancel',
    disabledPublishTooltip: 'Simulation required before publishing',
  },
  createSimulation: {
    title: 'Run simulation (required)',
    description: {
      CHECKOUT:
        'To review potential impact on orders, select a date range and run simulation against previous orders. Simulation only applies to ranked policies.',
      LOGIN:
        'To review potential impact on orders, select a date range and run simulation against previous orders. Simulation only applies to ranked policies.',
      RETURN:
        'To review potential impact on orders, select a date range and run simulation against previous orders. Simulation only applies to ranked policies.',
    },
    runSimulationBtn: 'Run simulation',
    presetRangeToday: 'Today',
    presetRangeLast7Days: 'Last 7 days',
  },
  runningSimulation: {
    description:
      'Please wait while simulation for {{startDate}} - {{endDate}} processes',
    cancelSimulationBtn: 'Cancel simulation',
    cancelSimulationPopconfirm: {
      title: 'Cancel simulation?',
      description: 'Any progress will be lost',
      okText: 'Cancel simulation',
      cancelText: 'Keep simulation',
    },
  },
  distributionSankey: {
    before: 'Before change',
    after: 'After change',
    accept: 'Accept',
    reject: 'Reject',
    hold: 'Hold',
    unaffected: 'Default',
    challenge: 'Challenge',
    credit: 'Credit',
  },
  simulationResult: {
    title: 'Simulation',
    currencyLabel: 'Currency USD',
    countView: 'Count',
    gmvView: 'GMV',
    runSimulationBtn: 'Run simulation',
    validateConditionBtn: 'Run',
    impactPerPolicy: {
      title: 'Impact per policy',
      rank: 'RANK',
      name: 'POLICY',
      action: 'ACTION',
      before: 'BEFORE CHANGE',
      after: 'AFTER CHANGE',
      change: '% CHANGE',
      changeTooltip:
        'Relative change between before and after values in percentage',
    },
    infoMessage:
      'Simulation will be based on randomly sampled {{simulationSizeLimit}} orders based on your selected time range',
    ruleIdsWarningMessage:
      'Looks like you have changes in the policy rank. Please run the simulation again.',
    caseIdValidation: 'Case ID can only contain numeric characters.',
  },
  publishStepsBar: {
    stepOne: 'Rank policies',
    stepTwo: 'Run simulation (required)',
  },
  simulationDistributionTable: {
    title: 'After change',
    before: 'Before change',
    accept: 'Accept',
    reject: 'Reject',
    hold: 'Hold',
    unaffected: 'Default',
    credit: 'Credit',
    challenge: 'Challenge',
    total: 'Total',
  },
  distributionCard: {
    title: 'Distribution',
  },
  totalImpactCard: {
    title: 'Total impact',
    numberBeforeSimulation: 'Before {{formattedNumber}}',
    tooltip: 'Relative change between values of before and after in percentage',
    statisticLabels: {
      CHECKOUT: {
        extraContent_one: 'Simulated on {{formattedNumber}} order',
        extraContent_other: 'Simulated on {{formattedNumber}} orders',
        total: 'TOTAL ORDERS AFFECTED',
        ACCEPT: 'ORDERS ACCEPTED',
        REJECT: 'ORDERS REJECTED',
        HOLD: 'ORDERS HELD',
      },
      LOGIN: {
        extraContent_one: 'Simulated on {{formattedNumber}} login',
        extraContent_other: 'Simulated on {{formattedNumber}} logins',
        total: 'TOTAL LOGIN ATTEMPTS',
        ACCEPT: 'LOGINS ACCEPTED',
        REJECT: 'LOGINS REJECTED',
        CHALLENGE: 'LOGINS CHALLENGED',
      },
      RETURN: {
        extraContent_one: 'Simulated on {{formattedNumber}} return',
        extraContent_other: 'Simulated on {{formattedNumber}} returns',
        total: 'TOTAL RETURN ATTEMPTS',
        ACCEPT: 'RETURNS ACCEPTED',
        REJECT: 'RETURNS REJECTED',
        HOLD: 'RETURNS HELD',
        CREDIT: 'RETURNS CREDITED',
      },
    },
    download: {
      title: 'Download',
      tooltip: 'Download affected orders by simulation as .csv',
    },
  },
  ruleSetDnD: {
    helpHint:
      'Drag a policy <bold>To publish</bold> section and change ranking. Only ranked policies will impact order traffic.',
    publishedTitle: 'To publish',
    othersTitle: 'Not to publish',
    publishedPlaceholder: 'No policies to be published',
    othersPlaceholder: 'New policies will appear here before publishing',
  },
  ruleSetDnDDraggableRule: {
    moveToPublish: 'Move <bold>To publish</bold>',
    moveToOthers: 'Move to <bold>Not to publish</bold>',
  },
  ruleScheduleModal: {
    addSchedule: 'Add schedule',
    manageSchedule: 'Manage schedule',
  },
  noResultsFound: {
    title: 'No results found',
    description:
      'Unable to find orders within selected timeframe. We recommend you try another timeframe.',
  },
  fallbackErrorMessage: {
    fallback:
      'Apologies — our system encountered an error.  If problem persists please contact support.',
    pollSimulation:
      'Apologies — our system encountered an error while running simulation. Try again.',
    cancelSimulation: 'Simulation canceled',
  },
  cancelPublishModal: {
    title: 'Cancel publishing?',
    description: 'All progress will be lost.',
    description_edit:
      'All progress will be lost and policy changes will not be saved.',
    ok: 'Cancel',
    cancel: 'Keep progress',
  },
  disabledTooltip:
    'Cannot change policy ranking when editing. To change rank, publish the edited policy and then select “Change Rank” from policy overview.',
  ruleName: {
    created: 'created',
  },
}
