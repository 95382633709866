import { Text, Space } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { Col, Row } from 'antd'
import { CHECKPOINT } from '@signifyd/http'
import CheckpointActionButton from 'pages/DashboardPage/components/CheckpointActionButton'
import useFilteredCheckpointIconData from './useFilteredCheckpointIconData'

interface Props {
  selectedCheckpoint: CHECKPOINT
  onSelectCheckpoint: (checkpoint: CHECKPOINT) => void
}

const PolicyCheckpointForm: FC<Props> = ({
  selectedCheckpoint,
  onSelectCheckpoint,
}) => {
  const { t } = useTranslation()

  const checkpointIconData = useFilteredCheckpointIconData()

  return (
    <div data-test-id="policy-checkpoint-form">
      <Text block size="lg">
        {t('ruleDashboardPage.policyCheckpointForm.main.header')}
      </Text>
      <Space size={8} />
      <Text block strong>
        {t('ruleDashboardPage.policyCheckpointForm.main.description')}
      </Text>
      <Space size="sm" />
      <Row gutter={16}>
        {checkpointIconData.map(({ checkpoint, Icon: icon, title }) => (
          <Col span={8} key={checkpoint}>
            <CheckpointActionButton
              title={title}
              Icon={icon}
              onClick={() => onSelectCheckpoint(checkpoint)}
              selected={checkpoint === selectedCheckpoint}
            />
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default PolicyCheckpointForm
