import {
  Filters,
  LIST_PAGE_SORT_BY_FILTER,
  LIST_PAGE_TYPE_FILTER,
  LIST_PAGE_EXPIRATION_FILTER,
} from 'stores/predictionList'
import { ListPageQueryParams } from './useListPageQueryParams'

function isValidSortBy(
  sortBy?: string | null
): sortBy is LIST_PAGE_SORT_BY_FILTER {
  return !!sortBy && sortBy in LIST_PAGE_SORT_BY_FILTER
}

function isValidPredictionListType(
  type?: string | null
): type is LIST_PAGE_TYPE_FILTER {
  return !!type && type in LIST_PAGE_TYPE_FILTER
}

function isValidPredictionListExpiration(
  hasExpiration?: string | null
): hasExpiration is LIST_PAGE_EXPIRATION_FILTER {
  return !!hasExpiration && hasExpiration in LIST_PAGE_EXPIRATION_FILTER
}

export const getFiltersFromSearchParams = ({
  teamId,
  type,
  sortBy,
  hasExpiration,
}: ListPageQueryParams): Filters | null => {
  if (
    !teamId ||
    !Number(teamId) ||
    !isValidSortBy(sortBy) ||
    !isValidPredictionListType(type) ||
    !isValidPredictionListExpiration(hasExpiration)
  ) {
    return null
  }

  return {
    listPageSortByFilter: LIST_PAGE_SORT_BY_FILTER[sortBy],
    listPageTypeFilter: LIST_PAGE_TYPE_FILTER[type],
    listPageExpirationFilter: LIST_PAGE_EXPIRATION_FILTER[hasExpiration],
    teamId: teamId.toString(),
  }
}
