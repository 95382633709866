import { isEqual } from 'lodash'
import { abbreviateBigNumber } from '@signifyd/utils'
import {
  SimulationMetrics,
  RuleTriggerMetrics,
  RuleSimulation,
  SIMULATION_STATUS,
  RuleSet,
} from '@signifyd/http'
import { FormattedImpact, FormattedImpactPerRule } from './simulation.types'

export const MAXIMUM_FRACTION_DIGITS = 2

export const getPercentageStringByLanguage =
  (language: string) =>
  (newNumber: number, oldNumber: number): string => {
    if (oldNumber === newNumber) {
      return '0%'
    }

    if (oldNumber === 0) {
      return 'N/A'
    }
    const formattedPercentage = Number(
      (((newNumber - oldNumber) * 100) / oldNumber).toFixed(
        MAXIMUM_FRACTION_DIGITS
      )
    )

    return `${
      formattedPercentage > 0 ? '+' : ''
    }${formattedPercentage.toLocaleString(language)}%`
  }

const abbrNumWithMaxTwoFractionDigits = (num: number): string => {
  return abbreviateBigNumber(num, MAXIMUM_FRACTION_DIGITS)
}

export const getFormattedCountImpact = (
  originalMetrics: SimulationMetrics | null | undefined,
  newMetrics: SimulationMetrics | null | undefined,
  language: string
): FormattedImpact | null => {
  if (!originalMetrics || !newMetrics) {
    return null
  }
  const getPercentageString = getPercentageStringByLanguage(language)

  const originalTotalCount =
    originalMetrics.acceptCount +
    originalMetrics.rejectCount +
    originalMetrics.holdCount +
    originalMetrics.challengeCount +
    originalMetrics.creditCount

  const originalTotal = abbrNumWithMaxTwoFractionDigits(originalTotalCount)

  const originalAccept = abbrNumWithMaxTwoFractionDigits(
    originalMetrics.acceptCount
  )
  const originalReject = abbrNumWithMaxTwoFractionDigits(
    originalMetrics.rejectCount
  )
  const originalHold = abbrNumWithMaxTwoFractionDigits(
    originalMetrics.holdCount
  )

  const originalChallenge = abbrNumWithMaxTwoFractionDigits(
    originalMetrics.challengeCount
  )

  const originalCredit = abbrNumWithMaxTwoFractionDigits(
    originalMetrics.creditCount
  )

  const newTotalCount =
    newMetrics.acceptCount +
    newMetrics.rejectCount +
    newMetrics.holdCount +
    newMetrics.creditCount +
    newMetrics.challengeCount

  const newTotal = abbrNumWithMaxTwoFractionDigits(newTotalCount)

  const newAccept = abbrNumWithMaxTwoFractionDigits(newMetrics.acceptCount)
  const newReject = abbrNumWithMaxTwoFractionDigits(newMetrics.rejectCount)
  const newHold = abbrNumWithMaxTwoFractionDigits(newMetrics.holdCount)
  const newCredit = abbrNumWithMaxTwoFractionDigits(newMetrics.creditCount)
  const newChallenge = abbrNumWithMaxTwoFractionDigits(
    newMetrics.challengeCount
  )

  const totalChange = getPercentageString(newTotalCount, originalTotalCount)

  const acceptChange = getPercentageString(
    newMetrics.acceptCount,
    originalMetrics.acceptCount
  )
  const rejectChange = getPercentageString(
    newMetrics.rejectCount,
    originalMetrics.rejectCount
  )
  const holdChange = getPercentageString(
    newMetrics.holdCount,
    originalMetrics.holdCount
  )

  const creditChange = getPercentageString(
    newMetrics.creditCount,
    originalMetrics.creditCount
  )
  const challengeChange = getPercentageString(
    newMetrics.challengeCount,
    originalMetrics.challengeCount
  )

  return {
    originalTotal,
    originalAccept,
    originalReject,
    originalHold,
    originalCredit,
    originalChallenge,
    newTotal,
    newAccept,
    newReject,
    newHold,
    totalChange,
    acceptChange,
    rejectChange,
    holdChange,
    creditChange,
    challengeChange,
    newCredit,
    newChallenge,
  }
}

export const getFormattedGmvImpact = (
  originalMetrics: SimulationMetrics | null | undefined,
  newMetrics: SimulationMetrics | null | undefined,
  language: string
): FormattedImpact | null => {
  if (!originalMetrics || !newMetrics) {
    return null
  }

  const getPercentageString = getPercentageStringByLanguage(language)

  const originalTotalGmv =
    originalMetrics.acceptGmv +
    originalMetrics.rejectGmv +
    originalMetrics.holdGmv +
    originalMetrics.creditGmv +
    originalMetrics.challengeGmv

  const originalTotal = `$${abbrNumWithMaxTwoFractionDigits(originalTotalGmv)}`
  const originalAccept = `$${abbrNumWithMaxTwoFractionDigits(
    originalMetrics.acceptGmv
  )}`
  const originalReject = `$${abbrNumWithMaxTwoFractionDigits(
    originalMetrics.rejectGmv
  )}`
  const originalHold = `$${abbrNumWithMaxTwoFractionDigits(
    originalMetrics.holdGmv
  )}`
  const originalCredit = `$${abbrNumWithMaxTwoFractionDigits(
    originalMetrics.creditGmv
  )}`
  const originalChallenge = `$${abbrNumWithMaxTwoFractionDigits(
    originalMetrics.challengeGmv
  )}`

  const newTotalGmv =
    newMetrics.acceptGmv +
    newMetrics.rejectGmv +
    newMetrics.holdGmv +
    newMetrics.challengeGmv +
    newMetrics.creditGmv

  const newTotal = `$${abbrNumWithMaxTwoFractionDigits(newTotalGmv)}`
  const newAccept = `$${abbrNumWithMaxTwoFractionDigits(newMetrics.acceptGmv)}`
  const newReject = `$${abbrNumWithMaxTwoFractionDigits(newMetrics.rejectGmv)}`
  const newHold = `$${abbrNumWithMaxTwoFractionDigits(newMetrics.holdGmv)}`
  const newCredit = `$${abbrNumWithMaxTwoFractionDigits(newMetrics.creditGmv)}`
  const newChallenge = `$${abbrNumWithMaxTwoFractionDigits(
    newMetrics.challengeGmv
  )}`

  const totalChange = getPercentageString(newTotalGmv, originalTotalGmv)

  const acceptChange = getPercentageString(
    newMetrics.acceptGmv,
    originalMetrics.acceptGmv
  )
  const rejectChange = getPercentageString(
    newMetrics.rejectGmv,
    originalMetrics.rejectGmv
  )
  const holdChange = getPercentageString(
    newMetrics.holdGmv,
    originalMetrics.holdGmv
  )
  const challengeChange = getPercentageString(
    newMetrics.challengeGmv,
    originalMetrics.challengeCount
  )
  const creditChange = getPercentageString(
    newMetrics.creditGmv,
    originalMetrics.creditGmv
  )

  return {
    originalTotal,
    originalAccept,
    originalReject,
    originalHold,
    newTotal,
    newAccept,
    newReject,
    newHold,
    totalChange,
    acceptChange,
    rejectChange,
    holdChange,
    challengeChange,
    creditChange,
    newChallenge,
    newCredit,
    originalChallenge,
    originalCredit,
  }
}

export const getFormattedCountImpactPerRule = (
  ruleTriggerMetrics: Array<RuleTriggerMetrics>,
  language: string
): Array<FormattedImpactPerRule> => {
  return ruleTriggerMetrics.map((metrics) => {
    const originalValue = metrics.originalTriggerCount
    const newValue = metrics.newTriggerCount

    return {
      id: metrics.ruleId,
      name: metrics.name,
      rank: metrics.rank,
      action: metrics.recommendedAction,
      originalValue,
      formattedOriginalValue: originalValue.toLocaleString(language),
      newValue,
      formattedNewValue: newValue.toLocaleString(language),
      change:
        originalValue === 0
          ? Number.MAX_VALUE
          : (newValue - originalValue) / originalValue,
      formattedChange: getPercentageStringByLanguage(language)(
        newValue,
        originalValue
      ),
    }
  })
}

export const getFormattedGmvImpactPerRule = (
  ruleTriggerMetrics: Array<RuleTriggerMetrics>,
  language: string
): Array<FormattedImpactPerRule> => {
  return ruleTriggerMetrics.map((metrics) => {
    const originalValue = metrics.originalTriggerGmv
    const newValue = metrics.newTriggerGmv

    return {
      id: metrics.ruleId,
      name: metrics.name,
      rank: metrics.rank,
      action: metrics.recommendedAction,
      originalValue,
      formattedOriginalValue: `$${originalValue.toLocaleString(language, {
        minimumFractionDigits: MAXIMUM_FRACTION_DIGITS,
        maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
      })}`,
      newValue,
      formattedNewValue: `$${newValue.toLocaleString(language, {
        minimumFractionDigits: MAXIMUM_FRACTION_DIGITS,
        maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
      })}`,
      change:
        originalValue === 0
          ? Number.MAX_VALUE
          : (newValue - originalValue) / originalValue,
      formattedChange: getPercentageStringByLanguage(language)(
        newValue,
        originalValue
      ),
    }
  })
}

export const canPublish = (
  hasMatchingRuleIds: boolean,
  ruleSimulation: RuleSimulation | null,
  updateRuleSetLoading: boolean,
  updateRuleSetError?: string
): boolean => {
  return (
    !updateRuleSetError &&
    !updateRuleSetLoading &&
    hasMatchingRuleIds &&
    ruleSimulation?.status === SIMULATION_STATUS.FINISHED
  )
}

export const hasMatchingRuleIds = (
  currentSimulation: RuleSimulation | null,
  ruleSet?: RuleSet
): boolean => {
  return (
    !!ruleSet &&
    !!currentSimulation &&
    isEqual(
      ruleSet.ruleIds.map((id: number) => `${id}`),
      currentSimulation.ruleIds
    )
  )
}
