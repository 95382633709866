import {
  CheckoutCheckpointIcon,
  IconProps,
  LoginCheckpointIcon,
  ReturnCheckpointIcon,
} from '@signifyd/components'
import { CHECKPOINT } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import useSelectedTeamCheckpoints from 'pages/DashboardPage/queries/useSelectedTeamCheckpoints'

interface CheckpointIcon {
  checkpoint: CHECKPOINT
  title: string
  Icon: React.FC<IconProps>
}

const useFilteredCheckpointIconData = (): Array<CheckpointIcon> => {
  const { t } = useTranslation()
  const { data: teamCheckpoints } = useSelectedTeamCheckpoints()

  const checkpointIconMap: Array<CheckpointIcon> = [
    {
      checkpoint: CHECKPOINT.LOGIN,
      title: t(
        'ruleDashboardPage.policyCheckpointForm.actionButtonTitles.login'
      ),
      Icon: LoginCheckpointIcon,
    },
    {
      checkpoint: CHECKPOINT.CHECKOUT,
      title: t(
        'ruleDashboardPage.policyCheckpointForm.actionButtonTitles.checkout'
      ),
      Icon: CheckoutCheckpointIcon,
    },
    {
      checkpoint: CHECKPOINT.RETURN,
      title: t(
        'ruleDashboardPage.policyCheckpointForm.actionButtonTitles.return'
      ),
      Icon: ReturnCheckpointIcon,
    },
  ]

  return checkpointIconMap.filter((icon) =>
    teamCheckpoints?.includes(icon.checkpoint)
  )
}

export default useFilteredCheckpointIconData
