import { PredictionList } from '@signifyd/http'
import { FC } from 'react'
import { getExpirations } from './listExpirationUtils'
import NoExpirations from './NoExpirations'
import HasExpirations from './HasExpirations'

interface Props {
  predictionList: PredictionList
}

const ListExpirations: FC<Props> = ({ predictionList }) => {
  const { expiration, type, id: predictionListId } = predictionList

  const expirations = getExpirations(expiration)

  const hasExpiration = !!expirations.length

  if (hasExpiration) {
    return (
      <HasExpirations
        predictionListId={predictionListId}
        expirations={expirations}
        type={type}
      />
    )
  }

  return <NoExpirations predictionListId={predictionListId} listType={type} />
}

export default ListExpirations
