import { useSearchParams } from 'react-router-dom'
import { TIMEFRAME } from 'core/components/PolicyReporting/PolicyReporting.utils'
import { isValidTimeframe } from 'core/utils/searchParams/typeGuards'

interface UseTimeFrame {
  timeframe: TIMEFRAME
  updateTimeframe: (newTimeframe: TIMEFRAME) => void
}

const TIMEFRAME_PARAM = 'timeframe'

export const getTimeframe = (searchParams: URLSearchParams): TIMEFRAME => {
  const timeframe = searchParams.get(TIMEFRAME_PARAM)

  return timeframe && isValidTimeframe(timeframe)
    ? (timeframe.toUpperCase() as TIMEFRAME)
    : TIMEFRAME['30D']
}

const useTimeframe = (): UseTimeFrame => {
  const [searchParams, setSearchParams] = useSearchParams()
  const timeframe = getTimeframe(searchParams)

  const updateTimeframe = (newTimeframe: TIMEFRAME): void => {
    setSearchParams((params) => {
      if (params.has(TIMEFRAME_PARAM)) {
        params.set(TIMEFRAME_PARAM, newTimeframe)
      } else {
        params.append(TIMEFRAME_PARAM, newTimeframe)
      }

      return params
    })
  }

  return { timeframe, updateTimeframe }
}

export default useTimeframe
