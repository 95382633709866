import Icon from '@ant-design/icons'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import {
  BetweenIcon,
  ContainIcon,
  EmptyIcon,
  EndWithIcon,
  EqualIcon,
  GreaterEqualIcon,
  GreaterIcon,
  LessEqualIcon,
  LessIcon,
  ListIcon,
  NotEqualIcon,
  StartWithIcon,
} from '@signifyd/components'
import { colorYonder } from '@signifyd/colors'

import {
  COMPARISON_OPERATOR,
  LOGICAL_OPERATOR,
  PredictionListFeature,
  ComparisonOperatorWithNot,
} from './types'

export const CONDITION_TREE_DEPTH = 3

export enum OPERATOR_OPTIONS_GROUPS {
  LOGICAL_OPERATOR_OPTIONS = 'LOGICAL_OPERATOR_OPTIONS',
  STRING_COMPARISON_OPERATORS = 'STRING_COMPARISON_OPERATORS',
  STRING_LIST_COMPARISON_OPERATORS = 'STRING_LIST_COMPARISON_OPERATORS',
  NUMBER_COMPARISON_EQUAL_OPERATORS = 'NUMBER_COMPARISON_EQUAL_OPERATORS',
  NUMBER_COMPARISON_OPERATORS = 'NUMBER_COMPARISON_OPERATORS',
  NUMBER_COMPARISON_BETWEEN_OPERATOR = 'NUMBER_COMPARISON_BETWEEN_OPERATOR',
  BIN_COMPARISON_OPERATORS = 'BIN_COMPARISON_OPERATORS',
  BOOLEAN_COMPARISON_OPERATOR = 'BOOLEAN_COMPARISON_OPERATOR',
  CATEGORICAL_COMPARISON_OPERATORS = 'CATEGORICAL_COMPARISON_OPERATORS',
  LIST_COMPARISON_OPERATORS = 'LIST_COMPARISON_OPERATORS',
  EMPTY_COMPARISON_OPERATORS = 'EMPTY_COMPARISON_OPERATORS',
}

export const MIN_VALID_PARTIAL_SUBCONDITIONS = 3

export const LOGICAL_OPERATOR_OPTIONS = [
  {
    value: LOGICAL_OPERATOR.and,
    label: 'All',
    optionGroup: OPERATOR_OPTIONS_GROUPS.LOGICAL_OPERATOR_OPTIONS,
  },
  {
    value: LOGICAL_OPERATOR.or,
    label: 'Any',
  },
  {
    value: LOGICAL_OPERATOR.numberOfConditions,
    label: 'Partial',
    optionGroup: OPERATOR_OPTIONS_GROUPS.LOGICAL_OPERATOR_OPTIONS,
  },
]

export const STRING_COMPARISON_OPERATORS: Array<ComparisonOperatorWithNot> = [
  {
    name: COMPARISON_OPERATOR.equalsIgnoreCase,
    isNot: false,
    icon: <Icon component={() => <EqualIcon fill={colorYonder} />} />,
    optionGroup: OPERATOR_OPTIONS_GROUPS.STRING_COMPARISON_OPERATORS,
  },
  {
    name: COMPARISON_OPERATOR.equalsIgnoreCase,
    isNot: true,
    icon: <Icon component={() => <NotEqualIcon fill={colorYonder} />} />,
    optionGroup: OPERATOR_OPTIONS_GROUPS.STRING_COMPARISON_OPERATORS,
  },
]

export const STRING_LIST_COMPARISON_OPERATORS: Array<ComparisonOperatorWithNot> =
  [
    {
      name: COMPARISON_OPERATOR.isSubstringIgnoreCase,
      isNot: false,
      icon: <Icon component={() => <ContainIcon fill={colorYonder} />} />,
      optionGroup: OPERATOR_OPTIONS_GROUPS.STRING_LIST_COMPARISON_OPERATORS,
    },
    {
      name: COMPARISON_OPERATOR.isSubstringIgnoreCase,
      isNot: true,
      optionGroup: OPERATOR_OPTIONS_GROUPS.STRING_LIST_COMPARISON_OPERATORS,
    },
    {
      name: COMPARISON_OPERATOR.startsWithIgnoreCase,
      isNot: false,
      icon: <Icon component={() => <StartWithIcon fill={colorYonder} />} />,
      optionGroup: OPERATOR_OPTIONS_GROUPS.STRING_LIST_COMPARISON_OPERATORS,
    },
    {
      name: COMPARISON_OPERATOR.startsWithIgnoreCase,
      isNot: true,
      optionGroup: OPERATOR_OPTIONS_GROUPS.STRING_LIST_COMPARISON_OPERATORS,
    },
    {
      name: COMPARISON_OPERATOR.endsWithIgnoreCase,
      isNot: false,
      icon: <Icon component={() => <EndWithIcon fill={colorYonder} />} />,
      optionGroup: OPERATOR_OPTIONS_GROUPS.STRING_LIST_COMPARISON_OPERATORS,
    },
    {
      name: COMPARISON_OPERATOR.endsWithIgnoreCase,
      isNot: true,
      optionGroup: OPERATOR_OPTIONS_GROUPS.STRING_LIST_COMPARISON_OPERATORS,
    },
  ]

export const NUMBER_COMPARISON_OPERATORS: Array<ComparisonOperatorWithNot> = [
  {
    name: COMPARISON_OPERATOR.equals,
    isNot: false,
    icon: <Icon component={() => <EqualIcon fill={colorYonder} />} />,
    optionGroup: OPERATOR_OPTIONS_GROUPS.NUMBER_COMPARISON_EQUAL_OPERATORS,
  },
  {
    name: COMPARISON_OPERATOR.equals,
    isNot: true,
    icon: <Icon component={() => <NotEqualIcon fill={colorYonder} />} />,
    optionGroup: OPERATOR_OPTIONS_GROUPS.NUMBER_COMPARISON_EQUAL_OPERATORS,
  },
  {
    name: COMPARISON_OPERATOR.largerThan,
    isNot: false,
    icon: <Icon component={() => <GreaterIcon fill={colorYonder} />} />,
    optionGroup: OPERATOR_OPTIONS_GROUPS.NUMBER_COMPARISON_OPERATORS,
  },
  {
    name: COMPARISON_OPERATOR.largerThanOrEqualTo,
    isNot: false,
    icon: <Icon component={() => <GreaterEqualIcon fill={colorYonder} />} />,
    optionGroup: OPERATOR_OPTIONS_GROUPS.NUMBER_COMPARISON_OPERATORS,
  },
  {
    name: COMPARISON_OPERATOR.lessThan,
    isNot: false,
    icon: <Icon component={() => <LessIcon fill={colorYonder} />} />,
    optionGroup: OPERATOR_OPTIONS_GROUPS.NUMBER_COMPARISON_OPERATORS,
  },
  {
    name: COMPARISON_OPERATOR.lessThanOrEqualTo,
    isNot: false,
    icon: <Icon component={() => <LessEqualIcon fill={colorYonder} />} />,
    optionGroup: OPERATOR_OPTIONS_GROUPS.NUMBER_COMPARISON_OPERATORS,
  },
  {
    name: COMPARISON_OPERATOR.isBetween,
    isNot: false,
    icon: <Icon component={() => <BetweenIcon fill={colorYonder} />} />,
    optionGroup: OPERATOR_OPTIONS_GROUPS.NUMBER_COMPARISON_BETWEEN_OPERATOR,
  },
]

export const BIN_COMPARISON_OPERATORS: Array<ComparisonOperatorWithNot> = [
  {
    name: COMPARISON_OPERATOR.equals,
    isNot: false,
    icon: <Icon component={() => <EqualIcon fill={colorYonder} />} />,
    optionGroup: OPERATOR_OPTIONS_GROUPS.BIN_COMPARISON_OPERATORS,
  },
  {
    name: COMPARISON_OPERATOR.equals,
    isNot: true,
    icon: <Icon component={() => <NotEqualIcon fill={colorYonder} />} />,
    optionGroup: OPERATOR_OPTIONS_GROUPS.BIN_COMPARISON_OPERATORS,
  },
]

export const BOOLEAN_COMPARISON_OPERATOR: ComparisonOperatorWithNot = {
  name: COMPARISON_OPERATOR.equals,
  isNot: false,
  icon: <Icon component={() => <EqualIcon fill={colorYonder} />} />,
  optionGroup: OPERATOR_OPTIONS_GROUPS.BOOLEAN_COMPARISON_OPERATOR,
}

export const CATEGORICAL_COMPARISON_OPERATORS: Array<ComparisonOperatorWithNot> =
  [
    {
      name: COMPARISON_OPERATOR.inIgnoreCase,
      // This is not a typo.
      // Product/Design want to label "inIgnoreCase" operators as "Is equal to"
      isNot: false,
      icon: <Icon component={() => <EqualIcon fill={colorYonder} />} />,
      optionGroup: OPERATOR_OPTIONS_GROUPS.CATEGORICAL_COMPARISON_OPERATORS,
    },
    {
      name: COMPARISON_OPERATOR.inIgnoreCase,
      isNot: true,
      icon: <Icon component={() => <NotEqualIcon fill={colorYonder} />} />,
      optionGroup: OPERATOR_OPTIONS_GROUPS.CATEGORICAL_COMPARISON_OPERATORS,
    },
  ]

export const LIST_COMPARISON_OPERATOR_NAME = COMPARISON_OPERATOR.in

export const LIST_COMPARISON_OPERATORS: Array<ComparisonOperatorWithNot> = [
  {
    name: LIST_COMPARISON_OPERATOR_NAME,
    isNot: false,
    icon: <Icon component={() => <ListIcon fill={colorYonder} />} />,
    optionGroup: OPERATOR_OPTIONS_GROUPS.LIST_COMPARISON_OPERATORS,
  },
  {
    name: LIST_COMPARISON_OPERATOR_NAME,
    isNot: true,
    optionGroup: OPERATOR_OPTIONS_GROUPS.LIST_COMPARISON_OPERATORS,
  },
]

export const EMPTY_COMPARISON_OPERATORS: Array<ComparisonOperatorWithNot> = [
  {
    name: COMPARISON_OPERATOR.isEmptyAndMeaningless,
    isNot: false,
    icon: <Icon component={() => <EmptyIcon fill={colorYonder} />} />,
    optionGroup: OPERATOR_OPTIONS_GROUPS.EMPTY_COMPARISON_OPERATORS,
  },
  {
    name: COMPARISON_OPERATOR.isEmptyAndMeaningless,
    isNot: true,
    optionGroup: OPERATOR_OPTIONS_GROUPS.EMPTY_COMPARISON_OPERATORS,
  },
]

export const ALL_COMPARISON_OPERATORS = [
  ...STRING_COMPARISON_OPERATORS,
  ...NUMBER_COMPARISON_OPERATORS,
  BOOLEAN_COMPARISON_OPERATOR,
  ...CATEGORICAL_COMPARISON_OPERATORS,
  ...EMPTY_COMPARISON_OPERATORS,
  ...LIST_COMPARISON_OPERATORS,
]

type FeatureName = string

export const PREDICTION_LIST_FEATURES: Record<
  FeatureName,
  PredictionListFeature
> = {
  RawConfirmationEmailAddressString: {
    name: 'PredictionListsIdConfirmationEmailCaseInsensitiveMatch',
    deprecatedName: 'PredictionListsConfirmationEmailCaseInsensitiveMatch',
    type: PREDICTION_LIST_TYPE.EMAIL,
  },
  ConfirmationPhoneRawNumbers: {
    name: 'PredictionListsIdPhoneNumberMatch',
    deprecatedName: 'PredictionListsIdPhoneNumberMatch',
    type: PREDICTION_LIST_TYPE.PHONE_NUMBER,
  },
  CreditCardBinNumber: {
    name: 'PredictionListsIdCardBinMatch',
    deprecatedName: 'PredictionListsCardBinMatch',
    type: PREDICTION_LIST_TYPE.CARD_BIN,
  },
  PurchaseDiscountCodes: {
    name: 'PredictionListsIdPurchaseDiscountCodeMatch',
    deprecatedName: 'PredictionListsPurchaseDiscountCodeMatch',
    type: PREDICTION_LIST_TYPE.DISCOUNT_CODE,
  },
  PurchaseIpAddress: {
    name: 'PredictionListsIdPurchaseIpMatch',
    deprecatedName: 'PredictionListsPurchaseIpMatch',
    type: PREDICTION_LIST_TYPE.IP_ADDRESS,
  },
  PurchaseItemIds: {
    name: 'PredictionListsIdItemIdMatch',
    deprecatedName: 'PredictionListsItemIdMatch',
    type: PREDICTION_LIST_TYPE.ITEM_ID,
  },
  RawDeliveryAddressFull: {
    name: 'PredictionListsIdDeliveryAddressMatch',
    deprecatedName: 'PredictionListsIdDeliveryAddressMatch',
    type: PREDICTION_LIST_TYPE.ADDRESS,
  },
  RawFirstBillingAddressFull: {
    name: 'PredictionListsIdBillingAddressMatch',
    deprecatedName: 'PredictionListsIdBillingAddressMatch',
    type: PREDICTION_LIST_TYPE.ADDRESS,
  },
  RawUserAccountEmail: {
    name: 'PredictionListsIdAccountEmailCaseInsensitiveMatch',
    deprecatedName: 'PredictionListsAccountEmailCaseInsensitiveMatch',
    type: PREDICTION_LIST_TYPE.EMAIL,
  },
  ThreatMetrixDeviceId: {
    name: 'PredictionListsIdDeviceIdMatch',
    deprecatedName: 'PredictionListsDeviceIdMatch',
    type: PREDICTION_LIST_TYPE.DEVICE_ID,
  },
  TaxId: {
    name: 'PredictionListsIdTaxIdCaseInsensitiveMatch',
    deprecatedName: 'PredictionListsIdTaxIdCaseInsensitiveMatch',
    type: PREDICTION_LIST_TYPE.TAX_ID,
  },
  ConfirmationEmailFullDomainString: {
    name: 'PredictionListsIdConfirmationEmailDomainCaseInsensitiveMatch',
    deprecatedName:
      'PredictionListsIdConfirmationEmailDomainCaseInsensitiveMatch',
    type: PREDICTION_LIST_TYPE.EMAIL_DOMAIN,
  },
  UserAccountEmailDomain: {
    name: 'PredictionListsIdAccountEmailDomainCaseInsensitiveMatch',
    deprecatedName: 'PredictionListsIdAccountEmailDomainCaseInsensitiveMatch',
    type: PREDICTION_LIST_TYPE.EMAIL_DOMAIN,
  },
  SellerEmailDomains: {
    name: 'PredictionListsIdSellerEmailDomainCaseInsensitiveMatch',
    deprecatedName: 'PredictionListsIdSellerEmailDomainCaseInsensitiveMatch',
    type: PREDICTION_LIST_TYPE.EMAIL_DOMAIN,
  },
  RawConfirmationEmailDomain: {
    name: 'PredictionListsIdConfirmationEmailDomainCaseInsensitiveMatch',
    deprecatedName:
      'PredictionListsIdConfirmationEmailDomainCaseInsensitiveMatch',
    type: PREDICTION_LIST_TYPE.EMAIL_DOMAIN,
  },
  CustomerMembershipIds: {
    name: 'PredictionListsIdMembershipIdCaseInsensitiveMatch',
    deprecatedName: 'PredictionListsIdMembershipIdCaseInsensitiveMatch',
    type: PREDICTION_LIST_TYPE.MEMBERSHIP_ID,
  },
  CustomerMembershipNames: {
    name: 'PredictionListsIdMembershipIdCaseInsensitiveMatch',
    deprecatedName: 'PredictionListsIdMembershipIdCaseInsensitiveMatch',
    type: PREDICTION_LIST_TYPE.MEMBERSHIP_ID,
  },
  UserAccountAccountNumber: {
    name: 'PredictionListsIdAccountNumberCaseInsensitiveMatch',
    deprecatedName: 'PredictionListsIdAccountNumberCaseInsensitiveMatch',
    type: PREDICTION_LIST_TYPE.ACCOUNT_NUMBER,
  },
  FirstSellerAccountNumber: {
    name: 'PredictionListsIdSellerAccountNumberCaseInsensitiveMatch',
    deprecatedName: 'PredictionListsIdSellerAccountNumberCaseInsensitiveMatch',
    type: PREDICTION_LIST_TYPE.ACCOUNT_NUMBER,
  },
  SellerAccountNumbers: {
    name: 'PredictionListsIdSellerAccountNumberCaseInsensitiveMatch',
    deprecatedName: 'PredictionListsIdSellerAccountNumberCaseInsensitiveMatch',
    type: PREDICTION_LIST_TYPE.ACCOUNT_NUMBER,
  },
  TaxIdsWithoutCountryValidation: {
    name: 'PredictionListsIdTaxIdCaseInsensitiveMatch',
    deprecatedName: 'PredictionListsIdTaxIdCaseInsensitiveMatch',
    type: PREDICTION_LIST_TYPE.TAX_ID,
  },
}

export const REVERSED_PREDICTION_LIST_FEATURE: Record<string, string> =
  Object.entries(PREDICTION_LIST_FEATURES).reduce(
    (acc, [name, predictionListFeature]) => {
      acc[predictionListFeature.name] = name
      acc[predictionListFeature.deprecatedName] = name

      return acc
    },
    {} as Record<string, string>
  )

export const REVERSED_PREDICTION_LIST_ID_FEATURES: Record<string, string> =
  Object.entries(PREDICTION_LIST_FEATURES).reduce(
    (acc, [name, predictionListFeature]) => {
      acc[predictionListFeature.name] = name

      return acc
    },
    {} as Record<string, string>
  )

export const READONLY_RULE_FEATURES = new Set([
  'ConfirmationEmailBlacklistedReasonCodeStr',
  'WmxSamsWhitelistListMatch',
  'OrderChannelCategoricalStr',
  'BinPlusLastFourSamsMxTransactionSuccessMoneyLaunderingTimeWindowProductsSamsMxJewelryAndWatchTotalProductGmv',
  'BinPlusLastFourSamsMxTransactionSuccessMoneyLaunderingTimeWindowProductsSamsMxJewelryAndWatchTotalProductCount',
  'FirstRecipientNormalizedEmailSamsMxTransactionSuccessMoneyLaunderingTimeWindowProductsSamsMxJewelryAndWatchTotalProductGmv',
  'FirstRecipientNormalizedEmailSamsMxTransactionSuccessMoneyLaunderingTimeWindowProductsSamsMxJewelryAndWatchTotalProductCount',
  'BinPlusLastFourSamsMxTransactionSuccessMoneyLaunderingTimeWindowProductsSamsMxMotorcycleTotalProductGmv',
  'BinPlusLastFourSamsMxTransactionSuccessMoneyLaunderingTimeWindowProductsSamsMxMotorcycleTotalProductCount',
  'FirstRecipientNormalizedEmailSamsMxTransactionSuccessMoneyLaunderingTimeWindowProductsSamsMxMotorcycleTotalProductGmv',
  'FirstRecipientNormalizedEmailSamsMxTransactionSuccessMoneyLaunderingTimeWindowProductsSamsMxMotorcycleTotalProductCount',
  'BinPlusLastFourSamsMxTransactionSuccessMoneyLaunderingTimeWindowProductsSamsMxPrepaidCardTotalProductGmv',
  'BinPlusLastFourSamsMxTransactionSuccessMoneyLaunderingTimeWindowProductsSamsMxPrepaidCardTotalProductCount',
  'FirstRecipientNormalizedEmailSamsMxTransactionSuccessMoneyLaunderingTimeWindowProductsSamsMxPrepaidCardTotalProductGmv',
  'FirstRecipientNormalizedEmailSamsMxTransactionSuccessMoneyLaunderingTimeWindowProductsSamsMxPrepaidCardTotalProductCount',
  'BinPlusLastFourWmxTransactionSuccessMoneyLaunderingTimeWindowProductsWmxPrepaidCardTotalProductGmv',
  'BinPlusLastFourWmxTransactionSuccessMoneyLaunderingTimeWindowProductsWmxPrepaidCardTotalProductCount',
  'BinPlusLastFourWmxTransactionSuccessMoneyLaunderingTimeWindowProductsWmxMotorcycleTotalProductGmv',
  'BinPlusLastFourWmxTransactionSuccessMoneyLaunderingTimeWindowProductsWmxMotorcycleTotalProductCount',
  'BinPlusLastFourWmxTransactionSuccessMoneyLaunderingTimeWindowProductsWmxJewelryAndWatchTotalProductGmv',
  'BinPlusLastFourWmxTransactionSuccessMoneyLaunderingTimeWindowProductsWmxJewelryAndWatchTotalProductCount',
  'FirstRecipientNormalizedEmailWmxTransactionSuccessMoneyLaunderingTimeWindowProductsWmxMotorcycleTotalProductGmv',
  'FirstRecipientNormalizedEmailWmxTransactionSuccessMoneyLaunderingTimeWindowProductsWmxMotorcycleTotalProductCount',
  'FirstRecipientNormalizedEmailWmxTransactionSuccessMoneyLaunderingTimeWindowProductsWmxPrepaidCardTotalProductGmv',
  'FirstRecipientNormalizedEmailWmxTransactionSuccessMoneyLaunderingTimeWindowProductsWmxPrepaidCardTotalProductCount',
  'FirstRecipientNormalizedEmailWmxTransactionSuccessMoneyLaunderingTimeWindowProductsWmxJewelryAndWatchTotalProductGmv',
  'FirstRecipientNormalizedEmailWmxTransactionSuccessMoneyLaunderingTimeWindowProductsWmxJewelryAndWatchTotalProductCount',
  'FirstNormalizedDeliveryAddressPrevious1DaysSameTeamCount',
  'FirstNormalizedDeliveryAddressPrevious7DaysSameTeamCount',
  'FirstNormalizedDeliveryAddressPrevious30DaysSameTeamCount',
  'FirstNormalizedDeliveryAddressPrevious180DaysSameTeamCount',
  'FirstNormalizedDeliveryAddressPrevious365DaysSameTeamCount',
  'FirstRecipientNormalizedEmailPrevious1DaysSameTeamCount',
  'FirstRecipientNormalizedEmailPrevious7DaysSameTeamCount',
  'FirstRecipientNormalizedEmailPrevious30DaysSameTeamCount',
  'FirstRecipientNormalizedEmailPrevious180DaysSameTeamCount',
  'FirstRecipientNormalizedEmailPrevious365DaysSameTeamCount',
  'NormalizedBillingAddressPrevious1DaysSameTeamCount',
  'NormalizedBillingAddressPrevious7DaysSameTeamCount',
  'NormalizedBillingAddressPrevious30DaysSameTeamCount',
  'NormalizedBillingAddressPrevious180DaysSameTeamCount',
  'NormalizedBillingAddressPrevious365DaysSameTeamCount',
  'NormalizedThreatMetrixDeviceIdPrevious1DaysSameTeamCount',
  'NormalizedThreatMetrixDeviceIdPrevious7DaysSameTeamCount',
  'NormalizedThreatMetrixDeviceIdPrevious30DaysSameTeamCount',
  'NormalizedThreatMetrixDeviceIdSameTeamPrevious1DaysTotalOrderAmountInUsd',
  'NormalizedThreatMetrixDeviceIdSameTeamPrevious7DaysTotalOrderAmountInUsd',
  'NormalizedThreatMetrixDeviceIdSameTeamPrevious30DaysTotalOrderAmountInUsd',
  'FirstRecipientNormalizedEmailSameTeamPrevious1DaysTotalOrderAmountInUsd',
  'FirstRecipientNormalizedEmailSameTeamPrevious7DaysTotalOrderAmountInUsd',
  'FirstRecipientNormalizedEmailSameTeamPrevious30DaysTotalOrderAmountInUsd',
  'BillingAddressCountry',
])
