import { FC, ReactNode } from 'react'
import { Text, Space } from '@signifyd/components'
import { Flex } from 'antd'
import styles from './FlippedStatistic.less'

interface Props {
  title: string
  value: number
  suffix?: ReactNode
  'data-test-id': string
}

const FlippedStatistic: FC<Props> = ({
  title,
  value,
  suffix,
  'data-test-id': dataTestId,
}) => {
  return (
    <>
      <Flex justify="space-between">
        <Text
          size="sm"
          weight="normal"
          className={styles.title}
          data-test-id={`${dataTestId}Title`}
        >
          {title}
        </Text>
        <Text
          weight="semibold"
          size="sm"
          className={styles.content}
          data-test-id={`${dataTestId}Value`}
        >
          {value}
        </Text>
        {suffix && <Text data-test-id={`${dataTestId}Suffix`}>{suffix}</Text>}
      </Flex>
      <Space size="sm" />
    </>
  )
}

export default FlippedStatistic
