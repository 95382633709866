export enum CREATE_POLICY_MODAL_STATE {
  selectCheckpoint,
  enterDetails,
}

export type PolicyModalStateMapValue = {
  Content: JSX.Element | null
  onOk: () => void
  previousState?: CREATE_POLICY_MODAL_STATE
  canContinue: boolean
}

export type PolicyModalStateMap = {
  [key in CREATE_POLICY_MODAL_STATE]: PolicyModalStateMapValue
}
