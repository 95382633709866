import { FC } from 'react'
import { Button, Tooltip } from 'antd'
import cx from 'classnames'
import { i18nInstance } from '@signifyd/components'
import { VALIDATION_FAILURE_REASON } from '@signifyd/http'
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import { ButtonProps } from 'antd/lib/button'
import { ID_TYPE } from 'core/hooks/useValidatePolicies/utils'
import styles from './icons.less'

export const SuccessIcon: FC = () => (
  <CheckCircleFilled
    data-test-id="success-icon"
    className={styles.successIcon}
  />
)

export const FailIcon: FC = () => (
  <CloseCircleFilled data-test-id="fail-icon" className={styles.failIcon} />
)

interface FailIconProps {
  failureReason: VALIDATION_FAILURE_REASON | null
  idType: ID_TYPE
}

export const FailIconWithTooltip: FC<FailIconProps> = ({
  failureReason,
  idType,
}) => (
  <Tooltip
    placement="top"
    title={
      failureReason &&
      i18nInstance.t(`ruleValidationPage.failureTooltip.${failureReason}`, {
        idType,
        // Temp fix
        defaultValue: '',
      })
    }
  >
    <CloseCircleFilled data-test-id="failIcon" className={styles.failIcon} />
  </Tooltip>
)

export const DeleteButton: FC<ButtonProps> = (props) => (
  <Button
    type="link"
    data-test-id="deleteButton"
    size="small"
    className={styles.deleteButton}
    {...props}
  >
    <DeleteOutlined className={styles.deleteIcon} />
  </Button>
)

interface TooltipTriggerProps {
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onFocus?: () => void
  onClick?: () => void
  className?: string
}

export const TableDropdownIcon: FC<TooltipTriggerProps> = (props) => (
  <MoreOutlined
    {...props}
    rotate={90}
    className={cx([styles.tableDropdownIcon, props.className])}
  />
)
