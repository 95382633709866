import {
  CHECKPOINT,
  PolicyOutcome,
  RuleResponse,
  RULE_TYPE,
} from '@signifyd/http'
import { i18nInstance } from '@signifyd/components'

const MAX_NAME_LENGTH = 80

interface PolicyDuplicationPayload {
  checkpoint: CHECKPOINT
  description: string
  name: string
  ruleOutcome: PolicyOutcome
  ruleType: RULE_TYPE
  targetDispositions: Array<string>
  predicate: string
}

const getPolicyDuplicationPayload = (
  policy: RuleResponse
): PolicyDuplicationPayload => {
  const {
    checkpoint,
    description,
    name,
    ruleOutcome,
    ruleType,
    targetDispositions,
    predicate,
  } = policy

  const nameSuffix = i18nInstance.t('common.duplicatePolicy.nameSuffix')
  const copyName = `${name.slice(
    0,
    MAX_NAME_LENGTH - nameSuffix.length
  )}${nameSuffix}`

  return {
    checkpoint,
    description,
    name: copyName,
    ruleOutcome,
    ruleType,
    targetDispositions,
    predicate,
  }
}

export default getPolicyDuplicationPayload
