import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getRule, RuleResponse } from '@signifyd/http'

const useGetRule = (ruleId: number): UseQueryResult<RuleResponse> => {
  return useQuery({
    queryKey: ['rule', ruleId],
    queryFn: async () => {
      const { data: rule } = await getRule(ruleId)

      return rule
    },
  })
}

export default useGetRule
