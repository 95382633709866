import { Variable } from '@signifyd/http'
import { useEffect, useMemo } from 'react'
import { nanoid } from 'nanoid'
import { ConditionTree } from 'pages/PolicyConditionsPage/components/ConditionTreeRule/ConditionTreeRule.types'
import { useStoreState } from 'stores'
import { deserializePredicateToLegacyConditionTree } from './predicate'
import { getRuleFeaturesByName } from './utils'
import { ConditionTreeLeafNode, ConditionTreeNode } from './types'
import { useConditionTreeStore } from './ConditionTreeStore'

// Temporary function until it gets removed out of the store and constructed to the new way
export const convertToNewConditionTree = (
  oldConditionTree: ConditionTreeNode | null
): ConditionTree => {
  if (!oldConditionTree) {
    return {
      id: nanoid(),
      operator: null,
      nodes: new Map(),
    }
  }

  const conditions = oldConditionTree?.children as Array<ConditionTreeNode>

  const conditionsMap = new Map()
  conditions.forEach((condition: ConditionTreeNode) => {
    const subConditions = condition?.children as Array<ConditionTreeLeafNode>

    const subConditionMap = new Map()
    subConditions.forEach((subCondition: ConditionTreeLeafNode) => {
      subConditionMap.set(subCondition.id, subCondition)
    })

    conditionsMap.set(condition.id, {
      id: condition.id,
      operator: condition.operator,
      children: subConditionMap,
      numberOfConditions: condition.numberOfConditions,
    })
  })

  const newConditionTree = {
    id: oldConditionTree.id ?? null,
    operator: oldConditionTree.operator ?? null,
    nodes: conditionsMap,
  }

  return newConditionTree
}

const getConditionTreeFromPredicate = ({
  draftPredicate,
  predicate,
  policyFeatures,
  isEditing,
}: {
  draftPredicate?: string
  predicate?: string
  policyFeatures: Array<Variable> | null
  isEditing: boolean
}): ConditionTree | null => {
  if (!policyFeatures?.length) {
    return null
  }

  const predicateToParse = isEditing ? draftPredicate : predicate

  const predicateData = JSON.parse(predicateToParse || '{}')

  const policyFeaturesByName = getRuleFeaturesByName(policyFeatures)

  const legacyConditionTree = deserializePredicateToLegacyConditionTree(
    predicateData,
    policyFeaturesByName
  )

  const newConditionTreeStructure =
    convertToNewConditionTree(legacyConditionTree)

  return newConditionTreeStructure
}

export const useSetConditionTreeFromPolicy = (isEditing = false): void => {
  const { policy, policyFeatures } = useStoreState((state) => state.ruleModel)

  const conditionTree = useMemo(
    () =>
      getConditionTreeFromPredicate({
        draftPredicate: policy?.draftPredicate,
        predicate: policy?.predicate,
        policyFeatures,
        isEditing,
      }),
    [isEditing, policy?.draftPredicate, policy?.predicate, policyFeatures]
  )

  const setConditionTree = useConditionTreeStore(
    (state) => state.setConditionTree
  )

  useEffect(() => {
    if (conditionTree) {
      setConditionTree(conditionTree)
    }
  }, [conditionTree, setConditionTree])
}
