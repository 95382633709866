import { FC, createContext, useContext, useState } from 'react'

interface VariableGroupModalContext {
  isVisible: boolean
  selectedVariableGroupId?: number
  setSelectedVariableGroupId: (groupId?: number) => void
}

const variableGroupModalContext = createContext(
  undefined as unknown as VariableGroupModalContext
)

export const VariableGroupModalProvider: FC = ({ children }) => {
  const [selectedVariableGroupId, setSelectedVariableGroupId] =
    useState<number>()

  return (
    <variableGroupModalContext.Provider
      value={{
        selectedVariableGroupId,
        setSelectedVariableGroupId,
        isVisible: !!selectedVariableGroupId,
      }}
    >
      {children}
    </variableGroupModalContext.Provider>
  )
}

export const useVariableGroupModalContext = (): VariableGroupModalContext => {
  const context = useContext(variableGroupModalContext)

  return context
}
