import { FC } from 'react'
import { Link } from 'react-router-dom'
import {
  CUSTOMER_MENU_ITEM_KEY,
  CustomerNavBar,
  NavBarMenuItem,
  i18nInstance,
} from '@signifyd/components'
import useUserTeams from 'core/hooks/useUserTeams'
import styles from './AppNavBar.less'

const items = (teamId?: number): Array<NavBarMenuItem> => {
  const teamIdQueryParam = teamId ? `?teamId=${teamId}` : ''

  return [
    {
      key: CUSTOMER_MENU_ITEM_KEY.DecisionCenter,
      title: 'Decision Center',
      name: 'Decision Center',
      subItems: [
        {
          key: CUSTOMER_MENU_ITEM_KEY.DecisionCenterRules,
          title: 'Policies',
          name: 'Policies',
          OverrideComponent: (
            <Link
              className={styles.navLink}
              to={`/policies/dashboard${teamIdQueryParam}`}
            >
              {i18nInstance.t('common.routes.policies')}
            </Link>
          ),
        },
        {
          key: CUSTOMER_MENU_ITEM_KEY.DecisionCenterLists,
          title: 'Lists',
          name: 'Lists',
          OverrideComponent: (
            <Link className={styles.navLink} to={`/lists${teamIdQueryParam}`}>
              {i18nInstance.t('common.routes.lists')}
            </Link>
          ),
        },
      ],
    },
  ]
}

interface Props {
  teamId?: number
}

const AppNavBar: FC<Props> = ({ teamId }) => {
  const { updateUiState, user, userConfigs } = useUserTeams()
  const overrides = items(teamId)

  if (!user || !userConfigs) {
    return null
  }

  return (
    <CustomerNavBar
      overrides={overrides}
      user={user}
      userConfigs={userConfigs}
      onLanguageSelectionChange={(selectedLocale) => {
        updateUiState({ locale: selectedLocale })
      }}
    />
  )
}

export default AppNavBar
