import { RuleResponse } from '@signifyd/http'

export type OnClickRuleMenuItem = (rule: RuleResponse) => void

export enum MENU_KEYS {
  ViewSummary = 'ViewSummary',
  EditRuleDetails = 'EditRuleDetails',
  EditPolicy = 'EditPolicy',
  DuplicatePolicy = 'DuplicatePolicy',
  DeletePolicy = 'DeletePolicy',
  ManageSchedule = 'ManageSchedule',
  MoveToOthers = 'MoveToOthers',
  MoveToPublished = 'MoveToPublished',
  ChangeRank = 'ChangeRank',
}
