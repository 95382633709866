import { FC } from 'react'
import { Space, Text } from '@signifyd/components'

interface Props {
  error?: string
}

const FormError: FC<Props> = ({ error, ...props }) =>
  error ? (
    <>
      <Space size="sm" />
      <Text type="danger" size="xs" {...props}>
        {error}
      </Text>
    </>
  ) : null

export default FormError
