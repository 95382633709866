import { AggregatePolicyReportResponse, RuleResponse } from '@signifyd/http'
import { DashboardPolicies } from 'pages/DashboardPage/queries/useGetDashboardPolicies/useGetDashboardPolicies'
import { EnhancedPolicy } from '../useGetDashboardContainerData'

interface EnhancedDashboardPolicies {
  otherPolicies: Array<EnhancedPolicy>
  publishedPolicies: Array<EnhancedPolicy>
}

export const mapToPolicyWithReport = (
  currentPolicy: RuleResponse,
  policyReports: AggregatePolicyReportResponse['policies']
): EnhancedPolicy => {
  const policyReport = policyReports.find(
    (policy) => policy.policyId === currentPolicy.ruleId
  )

  if (!policyReport) {
    return {
      ...currentPolicy,
      policyReport: {
        policyId: currentPolicy.ruleId,
        hitsByCount: 0,
        hitsByGmv: 0,
        percentageOfTotalCount: 0,
        percentageOfTotalAffectedByPolicies: 0,
        percentageOfTotalGmv: 0,
        percentageOfTotalGmvAffectedByPolicies: 0,
        startDate: '',
        endDate: '',
        history: [],
      },
    }
  }

  return {
    ...currentPolicy,
    policyReport,
  }
}

export const mapToDashboardPoliciesWithReports = (
  dashboardPolicies: DashboardPolicies,
  policyReports: AggregatePolicyReportResponse['policies']
): EnhancedDashboardPolicies => {
  const { otherPolicies, publishedPolicies } = dashboardPolicies

  const otherFilteredPolicies = otherPolicies.reduce<Array<EnhancedPolicy>>(
    (filteredPolicies, current) => {
      const aggregateReportHitCount = policyReports.find(
        (policy) => policy.policyId === current.ruleId
      )?.hitsByCount

      if (current.isDeleted && !aggregateReportHitCount) {
        return filteredPolicies
      }

      filteredPolicies.push(mapToPolicyWithReport(current, policyReports))

      return filteredPolicies
    },
    []
  )

  return {
    otherPolicies: otherFilteredPolicies,
    publishedPolicies: publishedPolicies.map((item) =>
      mapToPolicyWithReport(item, policyReports)
    ),
  }
}
