import { FunctionComponent } from 'react'
import moment from 'moment-timezone'
import { DATE_TIME_FORMAT } from 'core/constants'

export interface FormatDateProps {
  UTCTimestamp: string
  format?: string
  showTimeZoneAbbr?: boolean
  timeZone?: string
}

const FormatDate: FunctionComponent<FormatDateProps> = ({
  UTCTimestamp,
  format = DATE_TIME_FORMAT,
  showTimeZoneAbbr = false,
  timeZone,
}) => {
  const localTime = moment.utc(UTCTimestamp).tz(timeZone || moment.tz.guess())
  const formattedTime = localTime.format(format)
  const zoneAbbr = showTimeZoneAbbr ? localTime.zoneAbbr() : ''

  return (
    <span>
      {UTCTimestamp ? formattedTime : ''}
      {zoneAbbr ? ` ${zoneAbbr}` : ''}
    </span>
  )
}

export default FormatDate
