import { SELECTED_RULE_ACTION } from 'pages/DashboardPage/DashboardPage.types'

export enum VIEW_TYPE {
  COUNT = 'COUNT',
  GMV = 'GMV',
}

export enum DISTRIBUTION_TYPE {
  GRAPH = 'GRAPH',
  TABLE = 'TABLE',
}

export enum POLICY_IMPACT_SORT_BY {
  RANK = 'RANK',
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  CHANGE = 'CHANGE',
}

export enum POLICY_IMPACT_SORT_DIRECTION {
  ASCEND = 'ascend',
  DESCEND = 'descend',
}

export enum SPLIT_SCREEN_STEP {
  STEP_ONE = '1',
  STEP_TWO = '2',
}

export enum DROPPABLE_ID {
  PUBLISHED = 'PUBLISHED',
  OTHERS = 'OTHERS',
}

export interface ChangeSchedulePayload {
  scheduleStart: string | null
  scheduleEnd: string | null
  index: number
}

export type StepsGridSpan = [number, number]

export interface SearchParams {
  viewType?: VIEW_TYPE
  distributionType?: DISTRIBUTION_TYPE
  policyImpactSortBy?: POLICY_IMPACT_SORT_BY
  policyImpactSortDirection?: POLICY_IMPACT_SORT_DIRECTION
  step: SPLIT_SCREEN_STEP
  ruleSetId?: number
  selectedRuleId?: number
  selectedRuleAction?: SELECTED_RULE_ACTION
}
