import { FC, useState } from 'react'
import { Col, Input, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Space, Text } from '@signifyd/components'
import styles from './AddressEntryForm.less'

export interface Props {
  onChangeManualInputValue: (param: Array<string>) => void
  onPartialEntry: (value: boolean) => void
}

interface AddressEntry {
  streetAddress: string
  unit: string
  city: string
  province: string
  postalCode: string
  country: string
}

const AddressEntrySchema = Yup.object().shape({
  streetAddress: Yup.string().required('streetAddress-error').trim(),
  unit: Yup.string().trim(),
  city: Yup.string().required('city').trim(),
  province: Yup.string().required('province').trim(),
  postalCode: Yup.string().required('postalCode').trim(),
  country: Yup.string().required('country').trim(),
})

const AddressEntryForm: FC<Props> = ({
  onChangeManualInputValue,
  onPartialEntry,
}) => {
  const { t } = useTranslation()
  const [address, setAddress] = useState<AddressEntry>({
    streetAddress: '',
    unit: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
  })

  const handleAddressChange = (address: AddressEntry): void => {
    const concatedAddress = Object.values(address).filter(Boolean).join(' ')

    try {
      AddressEntrySchema.validateSync(address)
      onChangeManualInputValue([concatedAddress])
    } catch {
      onPartialEntry(!!concatedAddress)
    }
  }

  const onInputChange = (property: string, value: string): void => {
    setAddress((current) => ({
      ...current,
      [property]: value,
    }))

    handleAddressChange({
      ...address,
      [property]: value,
    })
  }

  return (
    <>
      <Text type="secondary" size="sm" block>
        {t('listDetailsPage.listEntriesForm.address.header')}
      </Text>
      <Space size={8} />
      <Row gutter={[16, 8]}>
        <Col span={14}>
          <Text className={styles.inputTitle} size="sm" block>
            {t('listDetailsPage.listEntriesForm.address.streetAddressTitle')}
          </Text>
          <Space size={4} />
          <Input
            placeholder={t(
              'listDetailsPage.listEntriesForm.address.streetAddressPlaceholder'
            )}
            onChange={(event) =>
              onInputChange('streetAddress', event.target.value)
            }
          />
        </Col>
        <Col span={7}>
          <Text className={styles.inputTitle} size="sm" block>
            {t('listDetailsPage.listEntriesForm.address.unitTitle')}
          </Text>
          <Space size={4} />
          <Input
            placeholder={t(
              'listDetailsPage.listEntriesForm.address.unitPlaceholder'
            )}
            onChange={(event) => onInputChange('unit', event.target.value)}
          />
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={7}>
          <Text className={styles.inputTitle} size="sm" block>
            {t('listDetailsPage.listEntriesForm.address.cityTitle')}
          </Text>
          <Space size={4} />
          <Input
            placeholder={t(
              'listDetailsPage.listEntriesForm.address.cityPlaceholder'
            )}
            onChange={(event) => onInputChange('city', event.target.value)}
          />
        </Col>
        <Col span={8}>
          <Text className={styles.inputTitle} size="sm" block>
            {t('listDetailsPage.listEntriesForm.address.provinceTitle')}
          </Text>
          <Space size={4} />
          <Input
            placeholder={t(
              'listDetailsPage.listEntriesForm.address.provincePlaceholder'
            )}
            onChange={(event) => onInputChange('province', event.target.value)}
          />
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={7}>
          <Text className={styles.inputTitle} size="sm" block>
            {t('listDetailsPage.listEntriesForm.address.postalCodeTitle')}
          </Text>
          <Space size={4} />
          <Input
            placeholder={t(
              'listDetailsPage.listEntriesForm.address.postalCodePlaceholder'
            )}
            onChange={(event) =>
              onInputChange('postalCode', event.target.value)
            }
          />
        </Col>
        <Col span={14}>
          <Text className={styles.inputTitle} size="sm" block>
            {t('listDetailsPage.listEntriesForm.address.countryTitle')}
          </Text>
          <Space size={4} />
          <Input
            placeholder={t(
              'listDetailsPage.listEntriesForm.address.countryPlaceholder'
            )}
            onChange={(event) => onInputChange('country', event.target.value)}
          />
        </Col>
      </Row>
    </>
  )
}

export default AddressEntryForm
