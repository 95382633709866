import { Button, Input, Modal, Select } from 'antd'
import { FC, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Space } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { useAddVariableGroup } from 'pages/AdminPages/queries/useGetDecisionCenterGroups'
import useGetDecisionCenterVariables, {
  useAddVariableToGroup,
} from 'pages/AdminPages/queries/useGetDecisionCenterVariables'
import styles from './AddVariableGroupModal.less'

const AddVariableGroupModal: FC = () => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const { data: variables = [] } = useGetDecisionCenterVariables()

  const addVariableGroup = useAddVariableGroup()
  const addVariableToGroup = useAddVariableToGroup()

  const [isCreatingNewGroup, setIsCreatingNewGroup] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [newVariableGroupName, setNewVariableGroupName] = useState<string>()
  const [selectedVariables, setSelectedVariables] = useState<Array<number>>([])

  return (
    <>
      <Button onClick={() => setIsVisible(true)}>
        {t('variableGroupPage.addVariableGroupModal.toggleButton')}
      </Button>
      <Modal
        width={800}
        title={t('variableGroupPage.addVariableGroupModal.title')}
        visible={isVisible}
        confirmLoading={isCreatingNewGroup}
        okButtonProps={{
          disabled: !newVariableGroupName?.trim().length || isCreatingNewGroup,
        }}
        onOk={async () => {
          setIsCreatingNewGroup(true)

          const {
            data: { id },
          } = await addVariableGroup.mutateAsync(newVariableGroupName!)

          const promises = selectedVariables.map((variableId) => {
            const currentGroupIds =
              variables.find((variable) => variable.id === variableId)
                ?.groupIds ?? []

            const groupIds = [...new Set([...currentGroupIds, id])]

            return addVariableToGroup.mutateAsync({
              groupIds,
              variableId,
            })
          })

          await Promise.all(promises)

          queryClient.invalidateQueries(['allVariableGroups'])

          setIsCreatingNewGroup(false)
          setIsVisible(false)
          setNewVariableGroupName(undefined)
          setSelectedVariables([])
        }}
        onCancel={() => {
          setSelectedVariables([])
          setIsVisible(false)
        }}
      >
        <Input
          placeholder={t(
            'variableGroupPage.addVariableGroupModal.namePlaceholder'
          )}
          value={newVariableGroupName}
          onChange={(e) => {
            setNewVariableGroupName(e.target.value)
          }}
        />
        <Space size="md" />
        <Select
          value={selectedVariables}
          mode="multiple"
          className={styles.variableSelect}
          placeholder={t(
            'variableGroupPage.addVariableGroupModal.variablesPlaceholder'
          )}
          onChange={(value) => {
            setSelectedVariables(value)
          }}
        >
          {variables.map(({ id, name }) => (
            <Select.Option value={id} key={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </>
  )
}

export default AddVariableGroupModal
