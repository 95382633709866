import { FC, useMemo } from 'react'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import { Space, Text } from '@signifyd/components'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { useTypedRouteParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage'
import { VIEW_TYPE } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import { useStoreState } from 'stores'
import { getDistributionTableColumns } from './SimulationDistributionTable.config'
import { getDistributionTableData } from './SimulationDistributionTable.data'
import styles from './SimulationDistributionTable.less'

const SimulationDistributionTable: FC = () => {
  const { t } = useTranslation()

  const routeParams = useTypedRouteParams()
  const [{ viewType }] = useQueryParams(PublishPageQueryParams)

  const ruleSimulation = useStoreState(
    (state) => state.simulationModel.ruleSimulation
  )

  const data = useMemo(() => {
    if (!routeParams?.checkpoint) {
      return null
    }

    return getDistributionTableData(
      ruleSimulation,
      viewType as VIEW_TYPE,
      routeParams.checkpoint
    )
  }, [routeParams, ruleSimulation, viewType])

  if (!routeParams?.checkpoint || !data) {
    return null
  }

  const columns = getDistributionTableColumns(routeParams.checkpoint)

  return (
    <div
      className={styles.container}
      data-test-id="simulationDistributionTable"
    >
      <Text
        className={styles.before}
        block
        type="secondary"
        size="xs"
        transform="uppercase"
      >
        {t('publishWithSimulatorPage.simulationDistributionTable.before')}
      </Text>
      <div className={styles.tableContainer}>
        <Space size="xs" />
        <Text
          className={styles.title}
          block
          type="secondary"
          size="xs"
          transform="uppercase"
        >
          {t('publishWithSimulatorPage.simulationDistributionTable.title')}
        </Text>
        <Space size="xs" />
        <Table
          columns={columns}
          dataSource={data}
          size="small"
          bordered={false}
          showHeader={false}
          pagination={false}
        />
      </div>
    </div>
  )
}

export default SimulationDistributionTable
