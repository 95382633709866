import { FC } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import { useDownloadPredictionListEntries } from 'core/queries/predictionListEntries/useDownloadPredictionListEntries'

interface Props {
  disabled?: boolean
  predictionListId: number
}

const DownloadListEntriesButton: FC<Props> = ({
  disabled,
  predictionListId,
}) => {
  const { t } = useTranslation()

  const downloadPredictionListEntries = useDownloadPredictionListEntries()
  const debounceDownloadMutate = debounce(
    downloadPredictionListEntries.mutate,
    1000
  )

  return disabled ? (
    <Tooltip
      placement="left"
      title={t('listDetailsPage.sidePanel.noEntriesTooltip')}
    >
      <Button
        block
        icon={<DownloadOutlined />}
        data-test-id="disabledDownloadListEntriesButton"
        disabled
      >
        {t('listDetailsPage.sidePanel.downloadCSV')}
      </Button>
    </Tooltip>
  ) : (
    <Button
      block
      icon={<DownloadOutlined />}
      loading={downloadPredictionListEntries.isLoading}
      onClick={() => {
        debounceDownloadMutate({ predictionListId })
      }}
      data-test-id="enabledDownloadListEntriesButton"
    >
      {t('listDetailsPage.sidePanel.downloadCSV')}
    </Button>
  )
}

export default DownloadListEntriesButton
