import { FC } from 'react'
import { Button, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './MultiStageModalFooter.less'

interface Props {
  onOk: () => void
  onCancel: () => void
  onPrevious: () => void
  hasPreviousState: boolean
  canContinue: boolean
  loading: boolean
}

/**
 * Renders buttons to allow a user to navigate a multi stage modal (next / ok, back, cancel)
 */
const MultiStageModalFooter: FC<Props> = ({
  hasPreviousState,
  onPrevious,
  onCancel,
  onOk,
  canContinue,
  loading,
}) => {
  const { t } = useTranslation()

  const previousText = t('common.multiStageModalFooter.previous')
  const cancelText = t('common.multiStageModalFooter.cancel')
  const okText = t('common.multiStageModalFooter.ok')

  return (
    <Row>
      <Col span={4} className={styles.leftButtonContainer}>
        <Button
          type="link"
          onClick={hasPreviousState ? onPrevious : onCancel}
          data-test-id={`multi-stage-modal-footer-${
            hasPreviousState ? 'previous' : 'cancel'
          }-button`}
        >
          {hasPreviousState ? previousText : cancelText}
        </Button>
      </Col>
      <Col span={20}>
        {hasPreviousState && (
          <Button
            onClick={onCancel}
            type="link"
            data-test-id="multi-stage-modal-footer-cancel-button"
          >
            {cancelText}
          </Button>
        )}
        <Button
          onClick={onOk}
          type="link"
          loading={loading}
          disabled={!canContinue}
          data-test-id="multiStageModalFooterOkButton"
        >
          {okText}
        </Button>
      </Col>
    </Row>
  )
}

export default MultiStageModalFooter
