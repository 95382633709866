import { FC } from 'react'
import { Button, Popconfirm, Tooltip } from 'antd'
import { ButtonProps } from 'antd/es/button'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { Space, Text } from '@signifyd/components'
import { RuleSet } from '@signifyd/http'
import { useStoreState } from 'stores'
import {
  canPublish,
  hasMatchingRuleIds,
} from 'stores/simulation/simulation.store.utils'
import styles from './HeaderExtra.less'

interface Props {
  onClick: () => void
  publishRuleSetError: AxiosError<unknown> | null
  publishRuleSetLoading: boolean
  ruleSet?: RuleSet
}

const PublishButton: FC<Props> = ({
  onClick,
  publishRuleSetError,
  publishRuleSetLoading,
  ruleSet,
}) => {
  const { t } = useTranslation()

  const { noInvestigations: canPublishWithConfirm, ruleSimulation } =
    useStoreState((state) => state.simulationModel)

  const matchingRuleIds = hasMatchingRuleIds(ruleSimulation, ruleSet)
  const canPublishRuleSet = canPublish(
    matchingRuleIds,
    ruleSimulation,
    publishRuleSetLoading,
    publishRuleSetError?.message
  )

  if (canPublishWithConfirm) {
    return (
      <Popconfirm
        title={
          <div data-test-id="publishPopConfirm">
            <Text block weight="semibold" strong size="lg">
              {t('publishWithSimulatorPage.header.publishConfirmation')}
            </Text>
            <Space size={8} />
            <Text block type="secondary" size="md">
              {t('publishWithSimulatorPage.header.publishRecommendation')}
            </Text>
            <Space size={8} />
          </div>
        }
        overlayClassName="sig-popconfirm"
        overlayStyle={{ width: '292px' }}
        icon={null}
        onConfirm={onClick}
        okText={t('publishWithSimulatorPage.header.publishPopconfirmOkText')}
        cancelText={t(
          'publishWithSimulatorPage.header.publishPopconfirmCancelText'
        )}
        placement="bottomLeft"
        okButtonProps={
          {
            'data-analytics-id': 'confirm-publish-simulation-button',
          } as ButtonProps
        }
        cancelButtonProps={
          {
            type: 'link',
            'data-analytics-id': 'discard-publish-simulation-button',
          } as ButtonProps
        }
      >
        <Button
          type="default"
          className={styles.btn}
          loading={publishRuleSetLoading}
          data-test-id="publishPolicyConfirmationButton"
          data-analytics-id="publish-policy-confirmation-button"
        >
          {t('publishWithSimulatorPage.header.publishBtn')}
        </Button>
      </Popconfirm>
    )
  }

  return (
    <Tooltip
      placement="topRight"
      title={
        canPublishRuleSet
          ? ''
          : t('publishWithSimulatorPage.header.disabledPublishTooltip')
      }
    >
      <Button
        type="default"
        className={styles.btn}
        loading={publishRuleSetLoading}
        disabled={!canPublishRuleSet}
        onClick={onClick}
        data-test-id="publishPolicyButton"
        data-analytics-id="publish-policy-button"
      >
        {t('publishWithSimulatorPage.header.publishBtn')}
      </Button>
    </Tooltip>
  )
}

export default PublishButton
