import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { GetRulesParams, listRules, RuleResponse } from '@signifyd/http'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'

const useListRules = (
  listRulesPayload: Partial<GetRulesParams>,
  { enabled }: { enabled: boolean }
): UseQueryResult<Array<RuleResponse> | null> => {
  const { t } = useTranslation()

  return useQuery({
    queryKey: ['rules', listRulesPayload],
    enabled,
    queryFn: async () => {
      const { data: rulesList } = await listRules(listRulesPayload)

      return rulesList.data
    },
    onError: () => {
      message.error(t('ruleCommon.fallbackErrorMessage.listPredictionLists'))
    },
  })
}

export default useListRules
