import { NavigateFunction } from 'react-router'

function backOrDefault(
  state = window.history?.state,
  defaultUrl: string,
  navigate: NavigateFunction
): void | Promise<void> {
  if (state?.backToPreviousPage) {
    return window.history.back()
  }

  return navigate(`${defaultUrl}`)
}

export default backOrDefault
