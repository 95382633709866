import { FC } from 'react'
import { Col } from 'antd'
import { PredictionList } from '@signifyd/http'
import { PredictionListCard } from 'pages/ListPage/components/PredictionListCard'
import styles from './ListGrids.less'

interface Props {
  predictionLists: Array<PredictionList>
}

const ListGrids: FC<Props> = ({ predictionLists }) => (
  <div className={styles.container}>
    {predictionLists.map((predictionList) => (
      <Col key={predictionList.id}>
        <PredictionListCard predictionList={predictionList} />
      </Col>
    ))}
  </div>
)

export default ListGrids
