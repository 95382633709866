import { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryParams } from 'use-query-params'
import { CHECKPOINT, RuleResponse, SIMULATION_STATUS } from '@signifyd/http'
import { message } from 'antd'
import moment from 'moment-timezone'
import { partition } from 'lodash'
import { SearchParamsFromType } from 'core/utils/searchParams'
import TestTeamBanner from 'core/components/TestTeamBanner'
import useCreateRuleSet from 'core/queries/useCreateRuleSet/useCreateRuleSet'
import useGetRuleSet from 'core/queries/useGetRuleSet/useGetRuleSet'
import useUpdateRuleSet from 'core/queries/useUpdateRuleSet/useUpdateRuleSet'
import useUserTeams from 'core/hooks/useUserTeams'
import { useStoreActions, useStoreState } from 'stores'
import useGetAllRules from 'pages/PublishWithSimulatorPage/hooks/useGetAllRules'
import PublishWithSimulatorPageContainer from './containers/PublishWithSimulatorPageContainer'
import { PublishPageQueryParams } from './PublishWithSimulatorPage.config'
import { SPLIT_SCREEN_STEP } from './PublishWithSimulatorPage.types'
import {
  getExpiredListMessage,
  getTypedRouteParams,
} from './PublishWithSimulatorPage.utils'

export interface PublishWithSimulatorPageParams {
  teamId?: number
  checkpoint?: CHECKPOINT
}

export type PublishWithSimulatorPageUrlParams =
  SearchParamsFromType<PublishWithSimulatorPageParams>

export const useTypedRouteParams = (): PublishWithSimulatorPageParams => {
  const { teamId, checkpoint } = useParams()

  const selectedTeamId = useUserTeams().getSelectedTeamId(Number(teamId))

  const typedRouteParams = getTypedRouteParams({
    teamId: selectedTeamId?.toString(),
    checkpoint,
  })

  return typedRouteParams
}

export const useGetRulesByRuleSet = (): {
  publishedRules: Array<RuleResponse>
  otherRules: Array<RuleResponse>
  expiredRules: Array<RuleResponse>
  isFetching: boolean
} => {
  const [{ ruleSetId }] = useQueryParams(PublishPageQueryParams)
  const { teamId, checkpoint } = useTypedRouteParams()

  const { data: rules, isFetching: areRulesFetching } = useGetAllRules(
    teamId,
    checkpoint
  )
  const { data: ruleSet, isFetching: isRuleSetFetching } =
    useGetRuleSet(ruleSetId)

  const [publishedRules, otherRules] = partition(rules, (rule) =>
    ruleSet?.ruleIds.includes(rule.ruleId)
  )

  const isFetching = areRulesFetching && isRuleSetFetching

  return useMemo(() => {
    publishedRules.sort((a, b) => {
      const first =
        ruleSet?.ruleIds.findIndex((ruleId) => ruleId === a.ruleId) ?? 0
      const second =
        ruleSet?.ruleIds.findIndex((ruleId) => ruleId === b.ruleId) ?? 0

      return first - second
    })

    const expiredRules = publishedRules.filter(
      ({ scheduleEnd }) =>
        scheduleEnd && moment(scheduleEnd).isBefore(new Date(), 'second')
    )

    return {
      isFetching,
      publishedRules,
      otherRules,
      expiredRules,
    }
  }, [isFetching, otherRules, publishedRules, ruleSet?.ruleIds])
}

const PublishWithSimulatorPage: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { teamId, checkpoint } = useTypedRouteParams()

  const [queryParams, setQueryParams] = useQueryParams(PublishPageQueryParams)
  const { step, editedPolicyId, ruleSetId } = queryParams
  const isEditing = !!editedPolicyId

  const { data: rules, isInitialLoading: isloadingAllRules } = useGetAllRules(
    teamId,
    checkpoint
  )
  const { data: ruleSet, isInitialLoading: isLoadingRuleSet } =
    useGetRuleSet(ruleSetId)
  const { expiredRules } = useGetRulesByRuleSet()

  const { mutate: createRuleSetMutate, isLoading: isCreateRuleSetLoading } =
    useCreateRuleSet()
  const { isError: updateRuleSetError } = useUpdateRuleSet()

  const simulationStatus = useStoreState(
    (state) => state.simulationModel.ruleSimulation?.status
  )
  const { stopPollAction, resetState: resetSimulationStore } = useStoreActions(
    (state) => state.simulationModel
  )

  // Actually put the defaults into the query string on page load (have to do this manually for now)
  // https://github.com/pbeshai/use-query-params/issues/138
  useEffect(() => {
    setQueryParams(queryParams, 'replaceIn')
  }, [queryParams, setQueryParams])

  // Initial rule set creation
  useEffect(() => {
    if (teamId && !ruleSetId && rules && !isCreateRuleSetLoading) {
      createRuleSetMutate({
        teamId,
        checkpoint,
        rules,
      })
    }
  }, [
    checkpoint,
    isCreateRuleSetLoading,
    createRuleSetMutate,
    rules,
    ruleSetId,
    teamId,
  ])

  useEffect(() => {
    return () => {
      // resetState all stores and stop polling when navigate to another page
      stopPollAction()
      resetSimulationStore()
    }
  }, [resetSimulationStore, stopPollAction])

  useEffect(() => {
    if (!teamId || !checkpoint) {
      navigate('/policies/dashboard')

      return
    }

    const simulationIsRunning =
      simulationStatus === SIMULATION_STATUS.RUNNING &&
      step === SPLIT_SCREEN_STEP.STEP_ONE
    const cannotRunSimulation =
      updateRuleSetError && step === SPLIT_SCREEN_STEP.STEP_TWO

    if (simulationIsRunning || isEditing) {
      setQueryParams({ step: SPLIT_SCREEN_STEP.STEP_TWO })
    } else if (cannotRunSimulation) {
      setQueryParams({ step: SPLIT_SCREEN_STEP.STEP_ONE })
    }
  }, [
    checkpoint,
    isEditing,
    navigate,
    setQueryParams,
    simulationStatus,
    step,
    teamId,
    updateRuleSetError,
  ])

  // If any expired rules on page load, fire off same error message as API
  const expiryMessage = getExpiredListMessage(expiredRules)

  useEffect(() => {
    if (expiryMessage) {
      message.error(expiryMessage)
    }
  }, [expiryMessage])

  const isInitialLoading =
    isloadingAllRules || isLoadingRuleSet || isCreateRuleSetLoading

  return (
    <div data-test-id="publishWithSimulatorPage">
      <TestTeamBanner
        teamId={teamId}
        text={t('common.testTeamBanner.policy')}
      />
      <PublishWithSimulatorPageContainer
        ruleSet={ruleSet}
        isLoading={isInitialLoading}
        expiredRules={expiredRules}
      />
    </div>
  )
}

export default PublishWithSimulatorPage
