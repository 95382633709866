import { nanoid } from 'nanoid'
import { PREDICTION_LIST_FEATURES } from './conditionTree.constants'
import {
  ConditionTreeNode,
  ConditionTreeLeafNode,
  RuleFeaturesByName,
  RuleFeatureWithPredictionListMapping,
} from './types'

export function isConditionTreeNode(
  node: ConditionTreeNode | ConditionTreeLeafNode
): node is ConditionTreeNode {
  return 'children' in node && 'operator' in node
}

export function getEmptyLeafNode(): ConditionTreeLeafNode {
  return {
    id: nanoid(),
    ruleFeature: null,
    operator: null,
    value: null,
    isNot: false,
  }
}

export function getEmptyNode(): ConditionTreeNode {
  return {
    id: nanoid(),
    operator: null,
    children: [getEmptyLeafNode()],
  }
}

export function findNodeById(
  root: ConditionTreeNode,
  nodeId: string
): ConditionTreeNode | ConditionTreeLeafNode | undefined {
  if (root.id === nodeId) {
    return root
  }

  const nodes: Array<ConditionTreeNode | ConditionTreeLeafNode> = [root]

  while (nodes.length > 0) {
    const node = nodes.pop()

    if (!node) {
      return undefined
    }

    if (isConditionTreeNode(node)) {
      nodes.push(...node.children)
    }
    if (node.id === nodeId) {
      return node
    }
  }

  return undefined
}

export const getRuleFeaturesByName = (
  ruleFeatures: Array<RuleFeatureWithPredictionListMapping>
): RuleFeaturesByName => {
  return ruleFeatures.reduce((acc, ruleFeature) => {
    const { name: ruleFeatureName = '' } = ruleFeature

    const feature =
      ruleFeatureName in PREDICTION_LIST_FEATURES
        ? {
            ...ruleFeature,
            predictionListFeature: PREDICTION_LIST_FEATURES[ruleFeatureName],
          }
        : ruleFeature

    acc.set(ruleFeatureName, feature)

    return acc
  }, new Map())
}
