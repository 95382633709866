import { FC, useCallback, useRef, useState } from 'react'
import { Input } from 'antd'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { useClickOutside } from '@signifyd/components'
import { Variable } from '@signifyd/http'
import {
  CloseCircleFilled,
  DownOutlined,
  SearchOutlined,
  UpOutlined,
} from '@ant-design/icons'
import styles from './GroupFilterSelect.less'
import GroupMenu from './components/GroupMenu/GroupMenu'
import GroupContent from './components/GroupContent/GroupContent'

export interface Group {
  id: number
  name: string
}

export interface GroupListItem {
  id: number
  name: string
  description: string
}

interface VariableGroup {
  id: number
  name: string
  description: string
  items: Array<Variable>
}

export type VariableGroups = Record<string, VariableGroup>

export interface GroupFilterProps {
  data: VariableGroups
  truncateLength?: number
  onChange: (item: GroupListItem | null) => void
  value: GroupListItem | null
  config?: {
    dropdownPlaceholder?: string
    searchInputPlaceholder?: string
    categoryHeader?: string
  }
  dataAnalyticsPrefix?: string
  defaultTab: string
}
const { Search } = Input

const appRoot = document.getElementById('root')

const GroupFilterSelect: FC<GroupFilterProps> = ({
  data,
  defaultTab,
  value,
  onChange,
  truncateLength,
  config,
  dataAnalyticsPrefix = 'groupFilter',
}) => {
  const { t } = useTranslation()
  const defaultItems = data[defaultTab]?.items
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<string>(defaultTab)
  const [activeContent, setActiveContent] =
    useState<Array<Variable>>(defaultItems)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const groupFilter = useRef(null)

  const onClickOutside = useCallback(() => {
    setShowMenu(false)
    setActiveTab(defaultTab)
    setActiveContent(defaultItems)
  }, [setShowMenu, setActiveTab, setActiveContent, defaultItems, defaultTab])

  useClickOutside([groupFilter], onClickOutside, appRoot)

  const handleClick = (item: GroupListItem): void => {
    if (value?.name === item.name) {
      onChange(null)
    }

    if (value?.name !== item.name) {
      onChange(item)
      setShowMenu(false)
    }

    setSearchTerm('')
  }

  const handleGroupClick = (tabName: string): void => {
    setActiveTab(tabName)
    setActiveContent(data[tabName].items)
  }

  const handleClear = (): void => {
    const { items } = data[defaultTab]

    setActiveContent(items)
    setActiveTab(defaultTab)
    onChange(null)
  }

  const filterDataBySearchTerm = (
    data: VariableGroups,
    searchTerm: string,
    activeTab: string
  ): Array<Variable> => {
    if (searchTerm.length && !!data[activeTab]?.items.length) {
      const searchItems = data[activeTab].items
        .filter((item) => {
          return item.description
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        })
        .flat()

      return searchItems
    }

    return data[activeTab].items
  }

  const handleSearch = (searchTerm: string): void => {
    const filteredData = filterDataBySearchTerm(data, searchTerm, activeTab)

    setSearchTerm(searchTerm)
    setActiveContent(filteredData)
    setActiveTab(activeTab)
  }
  const searchInputPlaceholderText =
    defaultTab === data[activeTab]?.name
      ? t('groupFilterSelect.all')
      : data[activeTab].description

  return (
    <div ref={groupFilter} className={styles.container}>
      <Input
        onClick={() => setShowMenu(!showMenu)}
        className={styles.dropdown}
        value={value?.description}
        data-analytics-id={`${dataAnalyticsPrefix}-dropdown-categories`}
        readOnly
        placeholder={
          config?.dropdownPlaceholder ??
          t('groupFilterSelect.dropdownPlaceholder')
        }
        data-test-id="featureSelectInput"
        suffix={
          <>
            {value && (
              <CloseCircleFilled
                onClick={handleClear}
                className={styles.clearIcon}
                data-test-id="clearInput"
              />
            )}
            {showMenu && <UpOutlined className={styles.indicator} />}
            {!showMenu && <DownOutlined className={styles.indicator} />}
          </>
        }
      />
      {showMenu && (
        <div className={styles.menuContainer}>
          <GroupMenu
            data={data}
            defaultTab={defaultTab}
            handleTabClick={handleGroupClick}
            activeTab={activeTab}
            dataAnalyticsPrefix={dataAnalyticsPrefix}
          />
          <div className={cx([styles.column, styles.groupContent])}>
            <Search
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              onSearch={(value) => handleSearch(value)}
              allowClear
              data-test-id="searchInput"
              placeholder={t('groupFilterSelect.searchInputPlaceholder', {
                type:
                  config?.searchInputPlaceholder ?? searchInputPlaceholderText,
              })}
              data-analytics-id={`${dataAnalyticsPrefix}-search-filter-variables`}
              className={styles.searchInput}
              enterButton={<SearchOutlined />}
            />
            <GroupContent
              items={activeContent}
              handleClick={handleClick}
              truncateLength={truncateLength}
              selectedItem={value}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default GroupFilterSelect
