import { CHECKPOINT } from '@signifyd/http'
import { useState } from 'react'
import useGetRule from 'core/hooks/useGetRule/useGetRule'
import RuleSuccessModal from './RuleSuccessModal'

interface Props {
  teamId: string
  checkpoint: CHECKPOINT
  onClickLater: () => void
  onClickCreateRule: () => void
  editedPolicyId: number
}

const RuleEditSuccessModal: React.FC<Props> = ({
  teamId,
  onClickLater,
  onClickCreateRule,
  checkpoint,
  editedPolicyId,
}) => {
  const [visible, setVisible] = useState(true)

  const { data } = useGetRule(editedPolicyId)

  const handleCancel = (): void => {
    setVisible(false)
    onClickLater()
  }

  if (!data) {
    return null
  }

  return (
    <RuleSuccessModal
      visible={visible}
      handleCancel={handleCancel}
      rules={[data]}
      setVisible={() => setVisible}
      onClickCreateRule={onClickCreateRule}
      teamId={teamId}
      checkpoint={checkpoint}
      isCreate={false}
    />
  )
}

export default RuleEditSuccessModal
