import { FC } from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons'
import { CHECKPOINT } from '@signifyd/http'
import styles from './PublishSettingsButton.less'

interface Props {
  teamId: number | null
  teamCheckpoints: Array<CHECKPOINT>
}

const PublishSettingsButton: FC<Props> = ({ teamId, teamCheckpoints }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onNavigate = (checkpoint: CHECKPOINT): void => {
    navigate(`/policies/publish/${teamId}/${checkpoint.toLowerCase()}`, {
      state: { backToPreviousPage: true },
    })
  }

  if (teamCheckpoints.length > 1) {
    const CheckpointMenu = (
      <Menu
        onClick={(param) => {
          const checkpoint = param.key as CHECKPOINT

          onNavigate(checkpoint)
        }}
      >
        {teamCheckpoints.map((checkpoint) => (
          <Menu.Item
            key={checkpoint}
            data-test-id={`${checkpoint}-publishOption`}
          >
            {t(`common.checkpoint.${checkpoint}`)}
          </Menu.Item>
        ))}
      </Menu>
    )

    return (
      <Dropdown overlay={CheckpointMenu} className={styles.button}>
        <Button data-test-id="publishSettingsDropdown">
          {t('ruleDashboardPage.publishSettingsBtn')} <DownOutlined />
        </Button>
      </Dropdown>
    )
  }

  return (
    <Button
      data-test-id="publishSettingsButton"
      onClick={() => onNavigate(teamCheckpoints[0])}
      className={styles.button}
    >
      {t('ruleDashboardPage.publishSettingsBtn')}
    </Button>
  )
}

export default PublishSettingsButton
