import { CHECKPOINT_ACTION } from '@signifyd/http'

interface CreditKeys {
  originalNumber: 'originalCredit'
  percentage: 'creditChange'
  value: 'newCredit'
}

interface ChallengeKeys {
  originalNumber: 'originalChallenge'
  percentage: 'challengeChange'
  value: 'newChallenge'
}

interface HoldKeys {
  originalNumber: 'originalHold'
  percentage: 'holdChange'
  value: 'newHold'
}

interface RejectKeys {
  originalNumber: 'originalReject'
  percentage: 'rejectChange'
  value: 'newReject'
}

interface AcceptKeys {
  originalNumber: 'originalAccept'
  percentage: 'acceptChange'
  value: 'newAccept'
}

interface ActionKeys extends Record<CHECKPOINT_ACTION, any> {
  ACCEPT: AcceptKeys
  REJECT: RejectKeys
  HOLD: HoldKeys
  CHALLENGE: ChallengeKeys
  CREDIT: CreditKeys
}

export const checkpointActionToImpactKeysMap: ActionKeys = {
  ACCEPT: {
    originalNumber: 'originalAccept',
    percentage: 'acceptChange',
    value: 'newAccept',
  },
  REJECT: {
    originalNumber: 'originalReject',
    percentage: 'rejectChange',
    value: 'newReject',
  },
  HOLD: {
    originalNumber: 'originalHold',
    percentage: 'holdChange',
    value: 'newHold',
  },
  CHALLENGE: {
    originalNumber: 'originalChallenge',
    percentage: 'challengeChange',
    value: 'newChallenge',
  },
  CREDIT: {
    originalNumber: 'originalCredit',
    percentage: 'creditChange',
    value: 'newCredit',
  },
}
