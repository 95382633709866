import { i18nInstance } from '@signifyd/components'
import { CHECKPOINT, RuleResponse } from '@signifyd/http'
import { SearchParamsFromType } from 'core/utils/searchParams'
import { isValidCheckpoint } from 'core/utils/searchParams/typeGuards'

export interface PublishWithSimulatorPageParams {
  teamId?: number
  checkpoint?: CHECKPOINT
}

export type PublishWithSimulatorPageUrlParams =
  SearchParamsFromType<PublishWithSimulatorPageParams>

export const getTypedRouteParams = ({
  teamId,
  checkpoint,
}: PublishWithSimulatorPageUrlParams): PublishWithSimulatorPageParams => {
  // TODO FET-1827 https://signifyd.atlassian.net/browse/FET-1827
  // This could probably use zod too, just a separate function (one for the route params, another for the search params)
  return {
    teamId: Number(teamId) ?? undefined,
    checkpoint: isValidCheckpoint(checkpoint)
      ? (checkpoint.toUpperCase() as CHECKPOINT)
      : undefined,
  }
}

export const reorderRules = (
  rules: Array<RuleResponse>,
  prevIndex: number,
  index: number
): Array<RuleResponse> => {
  const result = [...rules]
  const indexNotValid =
    prevIndex < 0 ||
    prevIndex >= result.length ||
    index < 0 ||
    index > result.length

  if (indexNotValid) {
    return result
  }

  const [removed] = result.splice(prevIndex, 1)

  result.splice(index, 0, removed)

  return result
}

export const moveToDestList = ({
  src,
  dest,
  srcIndex,
  destIndex,
}: {
  src: Array<RuleResponse>
  dest: Array<RuleResponse>
  srcIndex: number
  destIndex: number
}): {
  updatedSrc: Array<RuleResponse>
  updatedDest: Array<RuleResponse>
} => {
  const updatedSrc = [...src]
  const updatedDest = [...dest]
  const indexNotValid =
    srcIndex < 0 ||
    srcIndex >= updatedSrc.length ||
    destIndex < 0 ||
    destIndex > updatedDest.length

  if (indexNotValid) {
    return {
      updatedSrc,
      updatedDest,
    }
  }

  const [removed] = updatedSrc.splice(srcIndex, 1)

  updatedDest.splice(destIndex, 0, removed)

  return {
    updatedSrc,
    updatedDest,
  }
}

export const moveToPublishedRules = (
  rules: {
    publishedRules: Array<RuleResponse>
    otherRules: Array<RuleResponse>
  },
  srcIndex: number,
  destIndex: number
): {
  publishedRules: Array<RuleResponse>
  otherRules: Array<RuleResponse>
} => {
  const { publishedRules, otherRules } = rules

  const { updatedSrc, updatedDest } = moveToDestList({
    src: otherRules,
    dest: publishedRules,
    srcIndex,
    destIndex,
  })

  return { publishedRules: updatedDest, otherRules: updatedSrc }
}

export const moveToOtherRules = (
  rules: {
    publishedRules: Array<RuleResponse>
    otherRules: Array<RuleResponse>
  },
  srcIndex: number,
  destIndex: number
): {
  publishedRules: Array<RuleResponse>
  otherRules: Array<RuleResponse>
} => {
  const { publishedRules, otherRules } = rules

  const { updatedSrc, updatedDest } = moveToDestList({
    src: publishedRules,
    dest: otherRules,
    srcIndex,
    destIndex,
  })

  return { publishedRules: updatedSrc, otherRules: updatedDest }
}

export const changeRuleSchedule = (
  publishedRules: Array<RuleResponse>,
  updateIndex: number,
  scheduleStart: string | null,
  scheduleEnd: string | null
): Array<RuleResponse> => {
  const newPublishedRules = publishedRules.map((rule, index) => {
    if (index === updateIndex) {
      return {
        ...rule,
        scheduleStart,
        scheduleEnd,
      }
    }

    return rule
  })

  return newPublishedRules
}

export const getExpiredListMessage = (
  expiredRules: Array<RuleResponse>
): string | null => {
  const { t } = i18nInstance

  if (!expiredRules?.length) {
    return null
  }

  const expiredRuleNames = expiredRules?.map((rule) => rule.name)

  return t('ruleCommon.fallbackErrorMessage.expiredRules', {
    expiredRuleNames: expiredRuleNames.join(', '),
  })
}
