import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import { Layout, PageSpinner } from '@signifyd/components'
import { RuleResponse, RuleSet } from '@signifyd/http'
import { useTypedRouteParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { SPLIT_SCREEN_STEP } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import useUpdateRuleSet from 'core/queries/useUpdateRuleSet/useUpdateRuleSet'
import HeaderExtra from './HeaderExtra'
import PublishWithSimulatorPageContent from './PublishWithSimulatorPageContent'
import StepsBar from './StepsBar'
import styles from './PublishWithSimulatorContainer.less'

const stepStylesMap: Record<SPLIT_SCREEN_STEP, { contentClassName: string }> = {
  [SPLIT_SCREEN_STEP.STEP_ONE]: {
    contentClassName: styles.contentStepOne,
  },
  [SPLIT_SCREEN_STEP.STEP_TWO]: {
    contentClassName: styles.contentStepTwo,
  },
}

const { Header, Content } = Layout

interface Props {
  isLoading: boolean
  expiredRules: Array<RuleResponse>
  ruleSet?: RuleSet
}

const PublishWithSimulatorPageContainer: FC<Props> = ({
  isLoading,
  expiredRules,
  ruleSet,
}) => {
  const { t } = useTranslation()

  const routeParams = useTypedRouteParams()
  const [filters] = useQueryParams(PublishPageQueryParams)
  const { error, isError, mutate } = useUpdateRuleSet()
  const { step } = filters

  if (!routeParams?.checkpoint || !step || !ruleSet) {
    return null
  }

  const { checkpoint } = routeParams
  const { contentClassName } = stepStylesMap[step]

  if (isLoading) {
    return (
      <div data-test-id="publishWithSimulatorPageContainer-spinner">
        <Header
          productName={t('ruleCommon.appName')}
          title={t(`publishWithSimulatorPage.header.title.${checkpoint}`)}
          theme="dark"
          marginBottom="none"
          extra=""
        />
        <PageSpinner />
      </div>
    )
  }

  return (
    <>
      <Header
        productName={t('ruleCommon.appName')}
        title={t(`publishWithSimulatorPage.header.title.${checkpoint}`)}
        theme="dark"
        marginBottom="none"
        extra={<HeaderExtra />}
      />

      <Header paddingTop="none" paddingBottom="none" marginBottom="none">
        <StepsBar
          current={step}
          expiredRules={expiredRules}
          error={error}
          isError={isError}
        />
      </Header>
      <Content
        className={contentClassName}
        innerClassName={styles.innerContent}
      >
        <PublishWithSimulatorPageContent
          currentStep={step}
          isEditing={!!filters.editedPolicyId}
          mutate={mutate}
        />
      </Content>
    </>
  )
}

export default PublishWithSimulatorPageContainer
