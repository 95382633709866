import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from 'antd'
import { Space } from '@signifyd/components'
import ImpactPerPolicyTable from 'pages/PublishWithSimulatorPage/components/ImpactPerPolicyTable'
import styles from './ImpactPerPolicyCard.less'

const ImpactPerPolicyCard: FC = () => {
  const { t } = useTranslation()

  return (
    <Card
      className={styles.card}
      title={t(
        'publishWithSimulatorPage.simulationResult.impactPerPolicy.title'
      )}
      data-test-id="impactPerPolicyCard"
    >
      <Space size="sm" />
      <ImpactPerPolicyTable />
    </Card>
  )
}

export default ImpactPerPolicyCard
