import { FC } from 'react'
import { Card, Skeleton } from 'antd'
import styles from './PredictionListSkeletonCard.less'

const PredictionListSkeletonCard: FC = () => {
  return (
    <Card hoverable>
      <div className={styles.wrapper}>
        <Skeleton loading active />
      </div>
    </Card>
  )
}

export default PredictionListSkeletonCard
