import { isEmpty, isNaN } from 'lodash'
import {
  COMPARISON_OPERATOR,
  ConditionTreeLeafNode,
  ConditionTreeNode,
  MIN_VALID_PARTIAL_SUBCONDITIONS,
} from 'stores/conditionTree'
import {
  SubConditionNode,
  ConditionTree,
  ConditionNode,
  LOGICAL_OPERATOR,
} from './components/ConditionTreeRule/ConditionTreeRule.types'

const isSubConditionNodeValid = (
  node: SubConditionNode | undefined
): boolean => {
  if (!node) {
    return false
  }

  const { ruleFeature, operator, value } = node

  const conditionIsValid = ruleFeature !== null && operator !== null

  if (operator === COMPARISON_OPERATOR.isEmptyAndMeaningless) {
    return conditionIsValid && isEmpty(value)
  }

  if (operator === COMPARISON_OPERATOR.isBetween) {
    const valuesMatch = Array.isArray(value) ? value[0] !== value[1] : false
    const validateValues = Array.isArray(value)
      ? !value.some((val) => !val || isNaN(val))
      : false

    return conditionIsValid && valuesMatch && validateValues
  }

  if (Array.isArray(value)) {
    return conditionIsValid && !!value.length
  }

  return conditionIsValid && !isEmpty(value)
}

export const isConditionTreeValid = (
  conditionTree: ConditionTree | null
): boolean => {
  if (!conditionTree || conditionTree?.nodes.size < 1) {
    return false
  }

  const isValid = Array.from(conditionTree.nodes.values()).every(
    (condition) => {
      if (!condition?.children) {
        return false
      }

      const subConditions = Array.from(condition.children.values())

      if (
        condition.operator === LOGICAL_OPERATOR.numberOfConditions &&
        (!condition.numberOfConditions ||
          subConditions.length < MIN_VALID_PARTIAL_SUBCONDITIONS)
      ) {
        return false
      }

      return subConditions.every((subCondition) => {
        return isSubConditionNodeValid(subCondition)
      })
    }
  )

  return isValid
}

// This is to make it work with serialize/deserialize in the store but will be replaced in future
export const convertToLegacyConditionTreeStructure = (
  conditionTree: ConditionTree
): ConditionTreeNode | ConditionTreeLeafNode => {
  const conditionNodes = Array.from(conditionTree.nodes.values())

  const conditions = conditionNodes.reduce(
    (
      accumulator: Array<ConditionTreeNode | ConditionTreeLeafNode>,
      currentValue: ConditionNode
    ) => {
      const subConditions = Array.from(currentValue.children, ([, value]) => ({
        ...value,
      }))

      const subCondition: ConditionTreeNode = {
        id: currentValue.id,
        operator: currentValue.operator,
        children: subConditions,
        numberOfConditions: currentValue.numberOfConditions ?? undefined,
      }

      return [...accumulator, subCondition]
    },
    []
  )

  return {
    id: conditionTree.id,
    operator: conditionTree.operator,
    children: conditions,
  }
}
