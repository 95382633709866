import { Icon } from '@ant-design/compatible'
import { IconProps } from '@ant-design/compatible/lib/icon'
import { FC } from 'react'
import cx from 'classnames'
import styles from './HeaderMenuIcon.less'

export const HeaderMenuIcon: FC<IconProps> = (props) => (
  <Icon
    {...props}
    type="more"
    rotate={90}
    className={cx([styles.tableDropdownIcon, props.className])}
  />
)
