import {
  PredictionListEntry,
  PREDICTION_LIST_ENTRY_SORT_BY,
} from '@signifyd/http'
import {
  SorterResult,
  SortOrder,
  TablePaginationConfig,
} from 'antd/lib/table/interface'
import { useListDetailsPageQueryParams } from 'pages/ListDetailsPage/useListDetailsPageQueryParams'
import { DATA_INDEX } from './ListEntriesTable.config'

type TableChangeFunction = (
  pagination: TablePaginationConfig,
  _filter: Partial<Record<keyof PredictionListEntry, Array<string>>>,
  sorter:
    | SorterResult<PredictionListEntry>
    | Array<SorterResult<PredictionListEntry>>
) => void

const getOrderByKey = (
  sorter: SorterResult<PredictionListEntry>,
  dataIndex: DATA_INDEX | undefined
): PREDICTION_LIST_ENTRY_SORT_BY | null => {
  if (!sorter.order || !dataIndex) {
    return null
  }

  const OrderMap = new Map<DATA_INDEX, PREDICTION_LIST_ENTRY_SORT_BY>()

  OrderMap.set(
    DATA_INDEX.createdAt,
    PREDICTION_LIST_ENTRY_SORT_BY.SORT_CREATED_AT
  )
  OrderMap.set(
    DATA_INDEX.createdByName,
    PREDICTION_LIST_ENTRY_SORT_BY.SORT_CREATED_BY_NAME
  )

  return OrderMap.get(dataIndex) ?? null
}

const getAscendingKey = (sortOrder: SortOrder | undefined): boolean | null => {
  if (!sortOrder) {
    return null
  }

  return sortOrder === 'ascend'
}

const useSetQueryOnTableChange = (): TableChangeFunction => {
  const [, setQuery] = useListDetailsPageQueryParams()

  const onTableChange: TableChangeFunction = (pagination, _filter, sorter) => {
    const tableSorter = Array.isArray(sorter) ? sorter[0] : sorter

    setQuery({
      ascending: getAscendingKey(tableSorter.order) ? 'true' : 'false',
      orderBy: getOrderByKey(
        tableSorter,
        tableSorter.column?.dataIndex as DATA_INDEX
      ),
      page: pagination.current ? pagination.current - 1 : undefined,
      pageSize: pagination.pageSize || undefined,
    })
  }

  return onTableChange
}

export default useSetQueryOnTableChange
