import { FC, useState, useEffect } from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { CHECKPOINT, RuleResponse } from '@signifyd/http'
import PolicyDetailsForm, {
  usePolicyDetailsFormInstance,
} from 'pages/DashboardPage/components/PolicyDetailsForm/PolicyDetailsForm'
import PolicyCheckpointForm from 'pages/DashboardPage/components/PolicyCheckpointForm'
import { useStoreState } from 'stores'
import MultiStageModalFooter from 'core/components/MultiStageModalFooter'
import { useDashboardPageFilters } from 'pages/DashboardPage/hooks/useDashboardPageFilters'
import {
  CREATE_POLICY_MODAL_STATE,
  PolicyModalStateMap,
} from './CreatePolicyModal.types'
import { getStartingModalState } from './CreatePolicyModal.utils'
import useSelectedCheckpoint from './useSelectedCheckpoint'
import { defaultPolicyDetails } from './constants'

type CreatePolicyPayload = Pick<
  RuleResponse,
  'name' | 'description' | 'teamId' | 'checkpoint'
>

interface Props {
  open: boolean
  onCancel: () => void
  onSubmit: (payload: {
    policy: CreatePolicyPayload
    navigate: NavigateFunction
  }) => void
  teamCheckpoints: Array<CHECKPOINT>
}

const CreatePolicyModal: FC<Props> = ({
  open,
  onCancel,
  onSubmit,
  teamCheckpoints,
}: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [filters] = useDashboardPageFilters()

  const { teamId } = filters

  const { error, pending: loading } = useStoreState(
    (state) => state.ruleModel.policyHTTPStatuses.createPolicy
  )

  const [policyModalState, setPolicyModalState] =
    useState<CREATE_POLICY_MODAL_STATE>(getStartingModalState(teamCheckpoints))

  // Handle switching between teams
  useEffect(() => {
    setPolicyModalState(getStartingModalState(teamCheckpoints))
  }, [teamCheckpoints])

  const [selectedCheckpoint, setSelectedCheckpoint, refreshSelectedCheckpoint] =
    useSelectedCheckpoint()

  const createPolicyFormInstance = usePolicyDetailsFormInstance(
    defaultPolicyDetails,
    (editedPolicyDetails) => {
      onSubmit({
        policy: {
          ...editedPolicyDetails,
          teamId: Number(teamId),
          checkpoint: selectedCheckpoint,
        },
        navigate,
      })
    }
  )

  // This modal can be in one of two sequential states with different content & actions
  const policyModalMap: PolicyModalStateMap = {
    [CREATE_POLICY_MODAL_STATE.selectCheckpoint]: {
      Content: (
        <PolicyCheckpointForm
          selectedCheckpoint={selectedCheckpoint}
          onSelectCheckpoint={setSelectedCheckpoint}
        />
      ),
      onOk: () => {
        setPolicyModalState(CREATE_POLICY_MODAL_STATE.enterDetails)
      },
      canContinue: !!selectedCheckpoint,
    },

    [CREATE_POLICY_MODAL_STATE.enterDetails]: {
      Content: (
        <PolicyDetailsForm
          formInstance={createPolicyFormInstance}
          loading={loading}
          error={error}
        />
      ),
      onOk: () => {
        if (createPolicyFormInstance.isValid) {
          createPolicyFormInstance.handleSubmit()
        }
      },
      canContinue:
        !!createPolicyFormInstance.values.name &&
        createPolicyFormInstance.touched &&
        createPolicyFormInstance.isValid,
      previousState:
        teamCheckpoints.length > 1
          ? CREATE_POLICY_MODAL_STATE.selectCheckpoint
          : undefined,
    },
  }

  const currentModalState = policyModalMap[policyModalState]

  const hasPreviousState = currentModalState.previousState !== undefined

  const onPrevious = (): void => {
    if (hasPreviousState) {
      setPolicyModalState(
        currentModalState.previousState as CREATE_POLICY_MODAL_STATE
      )
    }
  }

  const onAfterClose = (): void => {
    createPolicyFormInstance.resetForm()
    setPolicyModalState(getStartingModalState(teamCheckpoints))
    refreshSelectedCheckpoint()
  }

  if (!currentModalState) {
    return null
  }

  return (
    <Modal
      width={604}
      open={open}
      closable={false}
      title={t('ruleCommon.createRuleModal.title')}
      maskClosable={false}
      afterClose={onAfterClose}
      footer={
        <MultiStageModalFooter
          onOk={currentModalState.onOk}
          onCancel={onCancel}
          onPrevious={onPrevious}
          loading={loading}
          canContinue={currentModalState.canContinue}
          hasPreviousState={hasPreviousState}
        />
      }
    >
      {currentModalState.Content}
    </Modal>
  )
}

export default CreatePolicyModal
