import { FC, ComponentPropsWithoutRef } from 'react'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { colorSlate } from '@signifyd/colors'
import { QuestionCircleIcon, Text } from '@signifyd/components'
import { CHECKPOINT, PolicyReportResponse } from '@signifyd/http'
import styles from './TextTooltip.less'

type TextProps = ComponentPropsWithoutRef<typeof Text>

interface TextTooltipProps {
  block: TextProps['block']
  checkpoint: Exclude<CHECKPOINT, CHECKPOINT.LOGIN>
  className?: TextProps['className']
  field:
    | Exclude<
        keyof PolicyReportResponse,
        'policyId' | 'history' | 'startDate' | 'endDate'
      >
    | 'timezone'
  size: TextProps['size']
}

const TextTooltip: FC<TextTooltipProps> = ({
  block,
  checkpoint,
  className,
  field,
  size,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <Text className={className} block={block} size={size}>
        {t(`ruleSummaryPage.policyReports.${field}.label.${checkpoint}`)}
        <Tooltip
          placement="top"
          title={t(
            `ruleSummaryPage.policyReports.${field}.tooltip.${checkpoint}`
          )}
          destroyTooltipOnHide
        >
          <span
            className={styles.tooltipIcon}
            data-test-id={`${field}-tooltip`}
          >
            <QuestionCircleIcon fill={colorSlate} />
          </span>
        </Tooltip>
      </Text>
    </div>
  )
}

export default TextTooltip
