import {
  CHECKPOINT,
  GET_RULES_ORDER_BY,
  listRules,
  RULE_STAGE,
  RuleResponse,
} from '@signifyd/http'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { GET_RULES_LIMIT } from 'core/constants'

export const listOtherRules = async (
  teamId: number,
  checkpoint: CHECKPOINT
): Promise<Array<RuleResponse>> => {
  const filters = {
    teamIds: `${teamId}`,
    checkpoints: `${checkpoint}`,
    orderBy: GET_RULES_ORDER_BY.SORT_CREATED_AT,
    ascending: false,
    limit: GET_RULES_LIMIT,
    stages: `${RULE_STAGE.NEW},${RULE_STAGE.PUBLISHED}`,
    live: false,
  }

  const {
    data: { data: rules },
  } = await listRules(filters)

  return rules
}

export const listLiveRules = async (
  teamId: number,
  checkpoint: CHECKPOINT
): Promise<Array<RuleResponse>> => {
  const filters = {
    teamIds: `${teamId}`,
    checkpoints: `${checkpoint}`,
    limit: GET_RULES_LIMIT,
    live: true,
    ascending: true,
    orderBy: GET_RULES_ORDER_BY.SORT_RANK,
  }

  const {
    data: { data: rules },
  } = await listRules(filters)

  return rules
}

export const LIST_ALL_RULES = 'listAllRules'

export const useGetAllRules = (
  teamId?: number,
  checkpoint?: CHECKPOINT
): UseQueryResult<Array<RuleResponse>> => {
  return useQuery<unknown, unknown, Array<RuleResponse>>({
    queryKey: [LIST_ALL_RULES, teamId, checkpoint],
    enabled: !!teamId && !!checkpoint,
    queryFn: async () => {
      const [publishedRules, otherRules] = await Promise.all([
        listLiveRules(teamId!, checkpoint!),
        listOtherRules(teamId!, checkpoint!),
      ])

      return [...publishedRules, ...otherRules]
    },
  })
}
