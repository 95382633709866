import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from '@signifyd/components'
import { RuleResponse } from '@signifyd/http'
import * as Yup from 'yup'
import { FormikContextType, FormikProvider, useFormik } from 'formik'
import FormError from 'core/components/FormError'
import Form, { FormItem, TextArea, Input } from 'core/components/Form'

export const NAME_MAX_LEN = 80
export const DESCRIPTION_MAX_LEN = 255

interface Props {
  formInstance: FormikContextType<PolicyDetails>
  loading: boolean
  error?: string
}

export type PolicyDetails = Pick<RuleResponse, 'name' | 'description'>

const PolicyDetailsSchema = Yup.object().shape({
  name: Yup.string().required().max(NAME_MAX_LEN).trim(),
  description: Yup.string().max(DESCRIPTION_MAX_LEN).trim(),
})

export const usePolicyDetailsFormInstance = (
  initialPolicyDetails: PolicyDetails,
  onSubmit: (editedPolicyDetails: PolicyDetails) => void
): FormikContextType<PolicyDetails> => {
  const policyDetailsFormik = useFormik<PolicyDetails>({
    initialValues: {
      name: initialPolicyDetails.name,
      description: initialPolicyDetails.description,
    },
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit,
    validationSchema: PolicyDetailsSchema,
  })

  return policyDetailsFormik
}

const PolicyDetailsForm: FC<Props> = ({
  loading,
  error,
  formInstance,
}: Props) => {
  const { t } = useTranslation()

  return (
    <FormikProvider value={formInstance}>
      <Form data-test-id="policy-details-form" layout="vertical">
        <FormItem label={t('ruleCommon.ruleDetailsModal.nameLabel')}>
          <Input
            disabled={loading}
            name="name"
            placeholder={t('ruleCommon.ruleDetailsModal.namePlaceholder')}
            maxLength={NAME_MAX_LEN}
            data-test-id="policyDetailsName"
          />
        </FormItem>
        <Space size="sm" />
        <FormItem label={t('ruleCommon.ruleDetailsModal.descriptionLabel')}>
          <TextArea
            name="description"
            data-test-id="policy-details-description"
            maxLength={DESCRIPTION_MAX_LEN}
            disabled={loading}
            placeholder={t(
              'ruleCommon.ruleDetailsModal.descriptionPlaceholder'
            )}
          />
        </FormItem>

        <FormError error={error} />
      </Form>
    </FormikProvider>
  )
}

export default PolicyDetailsForm
