import { FC } from 'react'
import { Badge, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { RULE_STATUS } from '@signifyd/http'
import {
  colorBondi,
  colorGold,
  colorJade,
  colorRed,
  colorYonder,
} from '@signifyd/colors'

interface Props {
  ruleStatus: RULE_STATUS
}

export const STATUS_TO_COLOR = {
  [RULE_STATUS.ACTIVE]: colorJade,
  [RULE_STATUS.DRAFT]: colorYonder,
  [RULE_STATUS.EXPIRED]: colorYonder,
  [RULE_STATUS.INACTIVE]: colorYonder,
  [RULE_STATUS.NEW]: colorGold,
  [RULE_STATUS.SCHEDULED]: colorBondi,
  [RULE_STATUS.DELETED]: colorRed,
}

const RuleStatusBadge: FC<Props> = ({ ruleStatus }) => {
  const { t } = useTranslation()

  return (
    <Tooltip title={t('ruleCommon.ruleStatusBadge.tooltipTitle')}>
      <Badge
        color={STATUS_TO_COLOR[ruleStatus]}
        text={t(`ruleCommon.ruleStatusBadge.status.${ruleStatus}`)}
      />
    </Tooltip>
  )
}

export default RuleStatusBadge
