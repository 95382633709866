import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import {
  PREDICTION_LIST_TYPE,
  replacePredictionListEntriesWithJson,
} from '@signifyd/http'
import { message } from 'antd'
import { i18nInstance } from '@signifyd/components'
import { AxiosError, AxiosResponse } from 'axios'
import {
  JSON_REPLACE_PREDICTION_LIST_ENTRY_QUERY_KEY,
  PREDICTION_LIST_ENTRIES_QUERY_KEY,
} from 'core/queries/queryKeys'
import { getHTTPErrorStatus } from 'core/http'

export interface ReplacePredictionListEntriesWithJSONParams {
  predictionListId: number
  values: Array<string>
  type: PREDICTION_LIST_TYPE
  onSuccessCallback?: () => void
}

export const useReplacePredictionListEntriesWithJSON = (): UseMutationResult<
  AxiosResponse<Array<number>>,
  AxiosError,
  ReplacePredictionListEntriesWithJSONParams
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [[JSON_REPLACE_PREDICTION_LIST_ENTRY_QUERY_KEY]],
    mutationFn: async ({ predictionListId, values, type }) => {
      return replacePredictionListEntriesWithJson(predictionListId, {
        values,
        type,
      })
    },
    onSuccess: (_, { predictionListId, onSuccessCallback }) => {
      queryClient.invalidateQueries([
        PREDICTION_LIST_ENTRIES_QUERY_KEY,
        predictionListId.toString(),
      ])

      onSuccessCallback?.()
    },
    onError: (error) => {
      const status = getHTTPErrorStatus(
        error,
        i18nInstance.t('listCommon.fallbackErrorMessage.replaceListEntries')
      )

      message.error({ content: status.error })
    },
  })
}

export default useReplacePredictionListEntriesWithJSON
