import {
  RULE_TYPE,
  RULE_TARGET_DISPOSITION,
  PREDICTION_LIST_TYPE,
  PredictionList,
} from '@signifyd/http'

export const DEFAULT_RULE_PROPS = {
  ruleOutcome: {
    ruleGuaranteeOutcome: null,
    ruleRecommendedAction: null,
  },
  ruleType: RULE_TYPE.EXTERNAL,
  targetDispositions: [
    RULE_TARGET_DISPOSITION.PENDING,
    RULE_TARGET_DISPOSITION.APPROVED,
    RULE_TARGET_DISPOSITION.DECLINED,
  ],
}

export type PredictionListMap = Record<
  PREDICTION_LIST_TYPE,
  Array<PredictionList>
>

export const getDefaultPredictionListMap = (): PredictionListMap => {
  const predictionListMap: PredictionListMap = {} as PredictionListMap

  Object.values(PREDICTION_LIST_TYPE).forEach((predictionListType) => {
    predictionListMap[predictionListType] = []
  })

  return predictionListMap
}
