import { Button, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import CreateListExpirationForm from './CreateListExpirationForm'
import styles from './ListExpiration.less'

interface Props {
  predictionListId: number
  listType: PREDICTION_LIST_TYPE
}

const NoExpirations: FC<Props> = ({ predictionListId, listType }) => {
  const { t } = useTranslation()
  const [isCreateFormVisible, setIsCreateFormVisible] = useState(false)

  return (
    <div data-test-id="listHasNoExpirations">
      <div>{t('listDetailsPage.sidePanel.listHasNoExpirations')}</div>

      {isCreateFormVisible ? (
        <>
          <Divider />
          <CreateListExpirationForm
            predictionListId={predictionListId}
            listType={listType}
            setVisible={setIsCreateFormVisible}
          />
        </>
      ) : (
        <Button
          type="link"
          className={styles.addExpirationLink}
          onClick={() => setIsCreateFormVisible(true)}
        >
          {t('listDetailsPage.sidePanel.addExpiration')}
        </Button>
      )}
    </div>
  )
}

export default NoExpirations
