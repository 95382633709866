import { FC } from 'react'
import { Steps } from 'antd'
import { useTranslation } from 'react-i18next'

interface Props {
  current: 0 | 1
}

const NewRuleSteps: FC<Props> = ({ current }) => {
  const { t } = useTranslation()

  return (
    <Steps
      direction="vertical"
      size="small"
      current={current}
      items={[
        {
          title: t('ruleCommon.createRuleSteps.conditions.title'),
          description: t('ruleCommon.createRuleSteps.conditions.description'),
        },
        {
          title: t('ruleCommon.createRuleSteps.validation.title'),
          description: t('ruleCommon.createRuleSteps.validation.description'),
        },
      ]}
    />
  )
}

export default NewRuleSteps
