import { FC } from 'react'
import { Table } from 'antd'
import { ColumnProps } from 'antd/es/table'
import { Variable } from '@signifyd/http'
import { i18nInstance } from '@signifyd/components'
import AdditionalItemsPopover from 'core/components/AdditionalItemsPopover/AdditionalItemsPopover'
import { ExtendedVariableGroup } from 'pages/AdminPages/queries/adminVariables.types'
import useGetVariableGroups from 'pages/AdminPages/queries/useGetDecisionCenterGroups'
import useGetDecisionCenterVariables from 'pages/AdminPages/queries/useGetDecisionCenterVariables'
import TableHeader from './TableHeader/TableHeader'
import useVariableTableFiltering from './useVariableTableFiltering'
import AddVariableToGroupPopover from './AddVariableToGroupPopover/AddVariableToGroupPopover'
import styles from './VariableTable.less'

const { t } = i18nInstance

type ColumnConfig = ColumnProps<Variable>

const getColumns = (
  groups: Array<ExtendedVariableGroup>
): Array<ColumnConfig> => [
  {
    key: 'id',
    dataIndex: 'id',
    sorter: (a, b) => a.id - b.id,
    title: t('variablePage.columnTitles.id'),
  },
  {
    title: (
      <TableHeader
        title={t('variablePage.columnTitles.name')}
        columnKey="name"
      />
    ),
    key: 'name',
    dataIndex: 'name',
    width: 500,
    // sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: (
      <TableHeader
        title={t('variablePage.columnTitles.description')}
        columnKey="description"
      />
    ),
    key: 'description',
    dataIndex: 'description',
    // sorter: (a, b) => a.description.localeCompare(b.description),
  },
  {
    title: t('variablePage.columnTitles.checkpoint'),
    key: 'checkpoint',
    dataIndex: 'checkpoint',
  },
  {
    title: t('variablePage.columnTitles.dateAdded'),
    key: 'dateAdded',
    dataIndex: 'createdAt',
  },
  {
    title: t('variablePage.columnTitles.frontEndType'),
    key: 'frontEndType',
    dataIndex: 'frontEndType',
  },
  {
    title: t('variablePage.columnTitles.teamIds'),
    key: 'teamIds',
    dataIndex: 'teamIds',
    render: (teamIds: Array<string>) => (
      <AdditionalItemsPopover items={teamIds} />
    ),
  },
  {
    title: t('variablePage.columnTitles.isReadOnly'),
    key: 'isReadOnly',
    dataIndex: 'isReadOnly',
  },
  {
    title: (
      <TableHeader
        title={t('variablePage.columnTitles.groupIds')}
        columnKey="groupIds"
      />
    ),
    key: 'groupIds',
    dataIndex: 'groupIds',
    render: (groupIds: Array<number>, { id, name }) => {
      const groupNames = groupIds
        .map((groupId) => groups.find((group) => group.id === groupId)?.name)
        .filter((groupName): groupName is string => !!groupName)

      return (
        <div className={styles.groupColumn}>
          <div>
            <AdditionalItemsPopover items={groupNames} />
          </div>
          <AddVariableToGroupPopover variableId={id} variableName={name} />
        </div>
      )
    },
  },
]

const VariableTable: FC = () => {
  const { data: variables = [], isLoading } = useGetDecisionCenterVariables()
  const { data: groups = [], isLoading: isVariableGroupsLoading } =
    useGetVariableGroups()

  const columns = getColumns(groups)

  const filteredVariables = useVariableTableFiltering(variables, groups)

  return (
    <div>
      <Table
        dataSource={filteredVariables}
        loading={isLoading || isVariableGroupsLoading}
        columns={columns}
        rowKey="id"
        pagination={{
          pageSizeOptions: ['10', '25', '50'],
          defaultPageSize: 50,
          showSizeChanger: true,
        }}
      />
    </div>
  )
}

export default VariableTable
