import { CHECKPOINT } from '@signifyd/http'
import { CheckpointDistributionsMap, SankeyData } from './simulation.types'

export const rangePickerDateFormat = 'M/D/YYYY'

export const runningSimulationDateFormat = 'MMM. D, YYYY'

export const simulationDateFormat = 'YYYY-MM-DD[T]HH:mm:ss.[000Z]'

export const DEFAULT_POLL_INTERVAL_MS = 1000

export const EMPTY_SANKEY_DATA: SankeyData = {
  nodes: [],
  links: [],
  colors: [],
}

export const checkpointDistributionsMap: CheckpointDistributionsMap = {
  [CHECKPOINT.CHECKOUT]: ['accept', 'reject', 'hold', 'unaffected'],
  [CHECKPOINT.LOGIN]: ['accept', 'reject', 'challenge', 'unaffected'],
  [CHECKPOINT.RETURN]: ['accept', 'reject', 'hold', 'credit', 'unaffected'],
}
