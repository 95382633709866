import { FC, ReactNode } from 'react'
import { Button, Col, Row, Tooltip } from 'antd'
import { Space, IconProps, Text } from '@signifyd/components'
import cx from 'classnames'
import styles from './GridSelect.less'

interface RadioButtonItem {
  icon: FC<IconProps>
  key: string
  label: ReactNode
  testId?: string
  disabled?: boolean
  tooltip?: string
}

export interface Props {
  centered?: boolean
  items: Array<RadioButtonItem>
  span: 8 | 12
  width?: 'Full' | 'Auto'
  value?: string
  onChange: (value: any) => void
}

const GRID_GUTTER: [number, number] = [16, 16]

const GridSelect: FC<Props> = ({
  centered,
  items,
  value,
  span,
  onChange,
  width = 'Auto',
}) => {
  return (
    <div
      className={
        centered ? `${styles.wrapper} ${styles.centered}` : styles.wrapper
      }
    >
      <Row gutter={GRID_GUTTER} className={styles[`rowSpan${width}${span}`]}>
        {items.map(({ icon: Icon, key, label, testId, disabled, tooltip }) => (
          <Col key={key} span={span} className={styles.col}>
            <Tooltip title={tooltip} arrow={{ pointAtCenter: true }}>
              <Button
                data-test-id={testId}
                className={cx(
                  styles.item,
                  disabled && styles.disabled,
                  key === value && styles.active
                )}
                onClick={() => onChange(key)}
                disabled={disabled}
                icon={<Icon className={styles.icon} />}
              >
                <Space size="xs" />
                <Text className={styles.label}>{label}</Text>
              </Button>
            </Tooltip>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default GridSelect
