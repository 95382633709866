import { SankeyNodeDatum } from '@nivo/sankey'
import { abbreviateBigNumber } from '@signifyd/utils'
import { VIEW_TYPE } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import { SankeyLink, SankeyNode } from 'stores/simulation'
import { getNodeData } from './DistributionSankey.data'

const MAXIMUM_FRACTION_DIGITS = 2

export const mapIdToLabel = (
  node: Omit<SankeyNodeDatum<SankeyNode, SankeyLink>, 'label' | 'color'>
): string => {
  return getNodeData(node.id).label
}

export const getValueByViewType = (
  viewType: VIEW_TYPE,
  value: number
): string => {
  return viewType === VIEW_TYPE.COUNT
    ? `${abbreviateBigNumber(value, MAXIMUM_FRACTION_DIGITS)}`
    : `$${abbreviateBigNumber(value, MAXIMUM_FRACTION_DIGITS)}`
}
