import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import StretchedNoResults from 'core/components/StretchedNoResults'

interface Props {
  loading: boolean
}

const EmptyTableText: FC<Props> = ({ loading }) => {
  const { t } = useTranslation()

  let title = null
  let description = null
  if (loading) {
    title = t(`listDetailsPage.listEntriesTable.loading.title`)
    description = t('listDetailsPage.listEntriesTable.loading.description')
  } else {
    title = t('listDetailsPage.listEntriesTable.noSearchResults.title')
    description = t(
      'listDetailsPage.listEntriesTable.noSearchResults.description'
    )
  }

  return <StretchedNoResults title={title} description={description} />
}

export default EmptyTableText
