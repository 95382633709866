import { Button } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useVariableGroupModalContext } from '../VariableGroupTable/variableGroupModalContext'

interface Props {
  groupId: number
}

const EditVariableGroupButton: FC<Props> = ({ groupId }) => {
  const { t } = useTranslation()

  const { setSelectedVariableGroupId } = useVariableGroupModalContext()

  return (
    <Button onClick={() => setSelectedVariableGroupId(groupId)}>
      {t('variableGroupPage.editGroupModal.toggleButton')}
    </Button>
  )
}

export default EditVariableGroupButton
