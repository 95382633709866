import { FC, useState } from 'react'
import { Button, Modal } from 'antd'
import { ModalProps } from 'antd/es/modal'
import { RcFile } from 'antd/es/upload'
import { useTranslation } from 'react-i18next'
import {
  CreatePredictionListPayload,
  PREDICTION_LIST_TYPE,
} from '@signifyd/http'
import { Space, Text } from '@signifyd/components'
import CloseListEntriesModalPopconfirm from 'core/components/CloseListEntriesModalPopconfirm'
import ListDetailsForm from 'core/components/ListDetailsForm'
import ListEntriesForm from 'pages/ListDetailsPage/components/ListEntriesForm'
import {
  MAX_MANUAL_LIST_ENTRY_ITEMS,
  SIG_MODAL_PROPS,
  UPLOAD_ENTRIES_METHOD,
} from 'core/constants'

type PartialCreateListPayload = Omit<CreatePredictionListPayload, 'teamId'>

type Step = 'One' | 'Two'

interface StatusProps {
  isLoading: boolean
  errorMessage?: string
}

export type PredictionListOperationStatuses = Record<
  'createEntriesCsv' | 'createEntriesJson' | 'createPredictionList',
  StatusProps
>

interface Props {
  listType: PREDICTION_LIST_TYPE
  visible: boolean
  listId?: number
  predictionListOperationStatuses: PredictionListOperationStatuses
  onCreateList: (param: PartialCreateListPayload) => void
  onUploadWithJSON: (entries: Array<string>) => void
  onUploadWithCSV: (file: RcFile) => void
  onClose: () => void
}

const CreateListFromPolicyModal: FC<Props> = ({
  listType,
  visible,
  listId,
  predictionListOperationStatuses,
  onCreateList,
  onUploadWithJSON,
  onUploadWithCSV,
  onClose,
}) => {
  const { t } = useTranslation()
  const [isPartialComplete, setIsPartialComplete] = useState(false)
  const [csvFile, setCsvFile] = useState<RcFile>()
  const [description, setDescription] = useState<string>()
  const [manualInputValue, setManualInputValue] = useState<Array<string>>([])
  const [name, setName] = useState<string>()
  const [uploadEntriesMethod, setUploadEntriesMethod] =
    useState<UPLOAD_ENTRIES_METHOD>()

  const step: Step = !listId ? 'One' : 'Two'

  const isValidManualInput =
    uploadEntriesMethod === UPLOAD_ENTRIES_METHOD.MANUAL &&
    manualInputValue.length > 0 &&
    manualInputValue.length <= MAX_MANUAL_LIST_ENTRY_ITEMS
  const isValidCSV =
    uploadEntriesMethod === UPLOAD_ENTRIES_METHOD.CSV && !!csvFile

  const { createEntriesJson, createEntriesCsv, createPredictionList } =
    predictionListOperationStatuses

  const resetForm = (): void => {
    setName('')
    setDescription('')
    setUploadEntriesMethod(undefined)
    setCsvFile(undefined)
  }

  const onCancel = (): void => {
    if (!isPartialComplete) {
      onClose()
    }

    resetForm()
  }

  const onOkStepOne = (): void => {
    if (!name) {
      return
    }
    const payload = description
      ? {
          type: listType,
          name,
          description,
        }
      : {
          type: listType,
          name,
        }

    onCreateList(payload)
  }

  const onOkStepTwo = (): void => {
    if (isValidManualInput) {
      onUploadWithJSON(manualInputValue)
    } else if (uploadEntriesMethod === UPLOAD_ENTRIES_METHOD.CSV && !!csvFile) {
      onUploadWithCSV(csvFile)
    }

    resetForm()
  }

  const stepOneModalProps: Partial<ModalProps> = {
    okButtonProps: {
      disabled: !name?.trim(),
      loading: createPredictionList.isLoading,
    },
  }

  const stepTwoModalProps: Partial<ModalProps> = {
    okButtonProps: {
      disabled: !isValidManualInput && !isValidCSV,
      loading: createEntriesJson.isLoading || createEntriesCsv.isLoading,
    },
    closable: true,
  }

  const modalProps = !listId ? stepOneModalProps : stepTwoModalProps

  return (
    <Modal
      {...SIG_MODAL_PROPS}
      title={t('listCommon.createListFromPolicyModal.title')}
      open={visible}
      afterClose={(): void => {
        onClose()
      }}
      footer={
        <>
          <CloseListEntriesModalPopconfirm
            disabled={!isPartialComplete}
            onConfirm={onCancel}
          >
            <Button onClick={onCancel} type="link">
              {t(`listCommon.createListFromPolicyModal.step${step}Cancel`)}
            </Button>
          </CloseListEntriesModalPopconfirm>
          <Button
            disabled={
              step === 'One'
                ? !name?.trim()
                : !isValidManualInput && !isValidCSV
            }
            type="link"
            onClick={step === 'One' ? onOkStepOne : onOkStepTwo}
          >
            {t(`listCommon.createListFromPolicyModal.step${step}Ok`)}
          </Button>
        </>
      }
      {...modalProps}
    >
      {step === 'One' && (
        <>
          <Text size="sm" weight="semibold" block>
            {t('listCommon.createListFromPolicyModal.stepOneTitle', {
              listType: t(`listCommon.createListModal.listTypes.${listType}`),
            })}
          </Text>
          <Space size="sm" />
          <ListDetailsForm
            disabled={createPredictionList.isLoading}
            name={name}
            description={description}
            onChangeName={(name) => setName(name)}
            onChangeDescription={(description) => setDescription(description)}
            errorMsg={createPredictionList.errorMessage}
            usedInPolicy={false}
          />
        </>
      )}
      {step === 'Two' && (
        <ListEntriesForm
          csvError={createEntriesCsv.errorMessage}
          csvFile={csvFile}
          disabled={createEntriesJson.isLoading || createEntriesCsv.isLoading}
          manualInputError={createEntriesJson.errorMessage}
          manualInputValue={manualInputValue}
          manualInputValueCountLimit={MAX_MANUAL_LIST_ENTRY_ITEMS}
          onChangeCSVFile={setCsvFile}
          onChangeManualInputValue={setManualInputValue}
          onChangeUploadEntriesMethod={setUploadEntriesMethod}
          onPartialEntry={setIsPartialComplete}
          predictionListType={listType}
          title={t('listCommon.createListFromPolicyModal.stepTwoTitle', {
            listType: t(`listCommon.listTypeLowerCase.${listType}`),
          })}
          uploadEntriesMethod={uploadEntriesMethod}
        />
      )}
    </Modal>
  )
}

export default CreateListFromPolicyModal
