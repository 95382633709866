import {
  GetPredictionListsParams,
  PREDICTION_LIST_SORT_BY,
} from '@signifyd/http'

export function getPredictionListParamsForTeam(
  teamId: number
): GetPredictionListsParams {
  return {
    orderBy: PREDICTION_LIST_SORT_BY.SORT_NAME,
    ascending: true,
    teamIds: `${teamId}`,
  }
}
