import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import { i18nInstance } from '@signifyd/components'

type ValidationProps = {
  predictionListType: PREDICTION_LIST_TYPE
  manualInputValue: Array<string>
}

export const handleClientValidation = ({
  predictionListType,
  manualInputValue,
}: ValidationProps): string | undefined => {
  if (predictionListType !== PREDICTION_LIST_TYPE.EMAIL_DOMAIN) {
    return undefined
  }

  const hasError = manualInputValue.some((value) => value.includes('@'))

  return hasError
    ? i18nInstance.t('listDetailsPage.listEntriesForm.customError.EMAIL_DOMAIN')
    : undefined
}
