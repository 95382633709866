import { SankeyNodeDatum } from '@nivo/sankey'
import {
  colorJade,
  colorRed,
  colorYonder,
  colorBondi,
  colorAmethyst,
  colorGold,
} from '@signifyd/colors'
import { i18nInstance } from '@signifyd/components'
import { CHECKPOINT, RuleSimulation } from '@signifyd/http'
import { VIEW_TYPE } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import {
  SimulationDistributions,
  SimulationResultDistributionKeys,
  checkpointDistributionsMap,
  SankeyData,
  EMPTY_SANKEY_DATA,
  SimulationDistribution,
  SankeyNode,
  SankeyLink,
} from 'stores/simulation'

export const NODE_ID_DELIMITER = '_'

export const INPUT_NODE_ID = 'i'

export const OUTPUT_NODE_ID = 'o'

interface SankeyNodeData {
  color: any
  label: string
}

type SankeyNodeMap = Record<SimulationDistribution, SankeyNodeData>

// Needs to be in a function so that i18n is working when this is called
export const getSankeyNodeMap = (): SankeyNodeMap => {
  const sankeyNodeMap: SankeyNodeMap = {
    accept: {
      color: colorJade,
      label: i18nInstance.t(
        'publishWithSimulatorPage.distributionSankey.accept'
      ),
    },
    reject: {
      color: colorRed,
      label: i18nInstance.t(
        'publishWithSimulatorPage.distributionSankey.reject'
      ),
    },
    hold: {
      color: colorYonder,
      label: i18nInstance.t('publishWithSimulatorPage.distributionSankey.hold'),
    },
    unaffected: {
      color: colorBondi,
      label: i18nInstance.t(
        'publishWithSimulatorPage.distributionSankey.unaffected'
      ),
    },
    credit: {
      color: colorAmethyst,
      label: i18nInstance.t(
        'publishWithSimulatorPage.distributionSankey.credit'
      ),
    },
    challenge: {
      color: colorGold,
      label: i18nInstance.t(
        'publishWithSimulatorPage.distributionSankey.challenge'
      ),
    },
  }

  return sankeyNodeMap
}

const getNodeDistribution = (nodeId: string): SimulationDistribution => {
  return nodeId.split(NODE_ID_DELIMITER)[0] as unknown as SimulationDistribution
}

export const getNodeData = (nodeId: string): SankeyNodeData => {
  const nodeDistribution = getNodeDistribution(nodeId)
  const sankeyNodeMap = getSankeyNodeMap()

  return sankeyNodeMap[nodeDistribution]
}

export const getNodeColumn = (
  node: SankeyNodeDatum<SankeyNode, SankeyLink>
): string => {
  const column = node.id.split(NODE_ID_DELIMITER)[1]
  if (column === INPUT_NODE_ID) {
    return i18nInstance.t('publishWithSimulatorPage.distributionSankey.before')
  }

  return i18nInstance.t('publishWithSimulatorPage.distributionSankey.after')
}

const filterInputNodes = (
  distributionData: SimulationDistributions,
  checkpoint: CHECKPOINT
): Array<SimulationResultDistributionKeys> => {
  const distributions = checkpointDistributionsMap[checkpoint]

  return distributions.filter((inputDistribution) => {
    return Object.values(distributionData[inputDistribution]).some(
      (distribution) => distribution > 0
    )
  })
}

const filterOutputNodes = (
  distributionData: SimulationDistributions,
  checkpoint: CHECKPOINT
): Array<SimulationResultDistributionKeys> => {
  const distributions = checkpointDistributionsMap[checkpoint]

  return distributions.filter((outputDistribution) => {
    return distributions.some(
      (inputDistribution) =>
        distributionData[inputDistribution][outputDistribution] > 0
    )
  })
}

const getFilteredSankeyNodes = (
  distributionData: SimulationDistributions,
  checkpoint: CHECKPOINT
): Array<SankeyNode> => {
  const filteredInput = filterInputNodes(distributionData, checkpoint)
  const filteredOutput = filterOutputNodes(distributionData, checkpoint)

  const inputNodes = filteredInput.map((name) => {
    const node = `${name}${NODE_ID_DELIMITER}${INPUT_NODE_ID}`

    return {
      id: node,
      color: getNodeData(node).color,
    }
  })
  const outputNodes = filteredOutput.map((name) => {
    const node = `${name}${NODE_ID_DELIMITER}${OUTPUT_NODE_ID}`

    return {
      id: node,
      color: getNodeData(node).color,
    }
  })

  return [...inputNodes, ...outputNodes]
}

const getFilteredSankeyLinks = (
  distributionData: SimulationDistributions,
  checkpoint: CHECKPOINT
): Array<SankeyLink> => {
  const distributions = checkpointDistributionsMap[checkpoint]
  const sankeyLinks: Array<SankeyLink> = []

  distributions.forEach((source: SimulationResultDistributionKeys) => {
    distributions.forEach((target) => {
      const value = distributionData[source][target]
      if (value > 0) {
        const inputNode = `${source}${NODE_ID_DELIMITER}${INPUT_NODE_ID}`
        const link = {
          source: inputNode,
          target: `${target}${NODE_ID_DELIMITER}${OUTPUT_NODE_ID}`,
          value,
          color: getNodeData(inputNode).color,
        }

        sankeyLinks.push(link)
      }
    })
  })

  return sankeyLinks
}

export const getDistributionSankeyData = (
  simulation: RuleSimulation | null,
  viewType: VIEW_TYPE,
  checkpoint: CHECKPOINT
): SankeyData => {
  if (!simulation) {
    return EMPTY_SANKEY_DATA
  }

  const distributions =
    viewType === VIEW_TYPE.COUNT
      ? simulation.distributionsByCount
      : simulation.distributionsByGmv

  const nodes = getFilteredSankeyNodes(distributions, checkpoint)
  const links = getFilteredSankeyLinks(distributions, checkpoint)
  const colors = [...new Set([...links, ...nodes].flatMap((x) => x.color))]

  return { nodes, links, colors }
}
