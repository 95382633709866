import React, { useEffect, useState } from 'react'
import { PAGE_PADDING } from 'core/constants'

const useScrollingTableBody = (
  rootElementRef: React.RefObject<HTMLDivElement>
): string | undefined => {
  const [tableScrollY, setTableScrollY] = useState<string | undefined>()

  // There's a lot of problems with this hook - document.querySelector() is an
  // antipattern as that will not only potentially break with react but
  // it could select another table if multiple are currently mounted
  // Also if any of these elements resize this will not re-run
  useEffect(() => {
    if (rootElementRef.current) {
      const { top } = rootElementRef.current.getBoundingClientRect()
      const tableHeader = document.querySelector('.ant-table-header')
      const tableHeaderHeight =
        tableHeader?.getBoundingClientRect().height || 47
      const tablePagination = document.querySelector('.ant-table-pagination')
      const tablePaginationHeight =
        tablePagination?.getBoundingClientRect().height || 24
      const tablePaginationMarginTop = tablePagination
        ? window.getComputedStyle(tablePagination).marginTop
        : '16px'

      const height = [
        '100vh',
        `${top}px`,
        `${tableHeaderHeight}px`,
        tablePaginationMarginTop,
        `${tablePaginationHeight}px`,
        `${PAGE_PADDING}px`,
      ].join(' - ')

      setTableScrollY(`calc(${height})`)
    }
  }, [rootElementRef])

  return tableScrollY
}

export default useScrollingTableBody
