import {
  ZodEffects,
  ZodOptional,
  ZodDefault,
  ZodString,
  z,
  ZodNullable,
} from 'zod'

// Zod should export this, maybe it will in the future
export declare type EnumLike = {
  [k: string]: string | number
  [nu: number]: string
}

/**
 * Attempts to parse a value using the given enum with a defaultValue as a fallback
 */
export const validateEnum = <T extends EnumLike, K extends T[keyof T]>(
  baseEnum: T,
  defaultValue: K,
  customValidationFunction?: (val?: string) => boolean
): ZodEffects<ZodDefault<ZodOptional<ZodString>>, K> => {
  return z
    .string()
    .optional()
    .default(defaultValue.toString())
    .transform((val) => {
      const validated = z.nativeEnum(baseEnum).safeParse(val)

      if (
        validated.success &&
        (!customValidationFunction || customValidationFunction(val))
      ) {
        return validated.data as K
      }

      return defaultValue
    })
}

/**
 * Returns either a valid key of enum T or null
 */
export const validateNullableEnum = <T extends EnumLike, K extends T[keyof T]>(
  baseEnum: T,
  defaultValue?: K
): ZodEffects<
  ZodDefault<ZodNullable<ZodOptional<ZodString>>>,
  T[keyof T] | null
> => {
  return z
    .string()
    .optional()
    .nullable()
    .default(defaultValue?.toString() ?? null)
    .transform((val) => {
      const defaultReturnValue = defaultValue ?? null

      const validated = z.nativeEnum(baseEnum).safeParse(val)

      return validated.success ? validated.data : defaultReturnValue
    })
}
