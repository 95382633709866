import { FC } from 'react'
import { Alert } from 'antd'
import { useTranslation } from 'react-i18next'
import { Space, T3, useUserContext } from '@signifyd/components'
import { CHECKPOINT } from '@signifyd/http'
import {
  getPolicyReportDateRange,
  TIMEFRAME,
} from 'core/components/PolicyReporting/PolicyReporting.utils'
import TimeframeSelect from 'core/components/PolicyReporting/TimeframeSelect/TimeframeSelect'
import ViewAffectedOrdersButton from 'core/components/PolicyReporting/ViewAffectedOrdersButton/ViewAffectedOrdersButton'
import useGetPolicyReport from 'pages/PolicySummaryPage/queries/useGetPolicyReport'
import ErrorComponent from 'core/components/ErrorComponent/ErrorComponent'
import HitReport from './HitReport/HitReport'
import TextTooltip from './TextTooltip/TextTooltip'
import styles from './PolicyReports.less'

interface PolicyReportsProps {
  policyId: number
  checkpoint: Exclude<CHECKPOINT, CHECKPOINT.LOGIN>
  timeframe: TIMEFRAME
  setTimeframe: (timeframe: TIMEFRAME) => void
}

const PolicyReports: FC<PolicyReportsProps> = ({
  policyId,
  checkpoint,
  timeframe,
  setTimeframe,
}) => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = useGetPolicyReport(
    policyId,
    checkpoint,
    timeframe
  )

  const {
    user: {
      features: { POWER_SEARCH },
    },
  } = useUserContext()

  const isPowerSearchEnabled = !!POWER_SEARCH?.featureFlag

  return (
    <div data-test-id="policyReports">
      <TimeframeSelect setTimeframe={setTimeframe} timeframe={timeframe} />
      <Space size="sm" />
      <Alert
        className={styles.timezoneMessage}
        data-test-id="timezoneMessage"
        message={
          <TextTooltip
            block
            checkpoint={checkpoint}
            field="timezone"
            size="md"
          />
        }
        type="info"
      />
      <Space size="lg" />
      <T3 className={styles.title}>
        <div>{t(`ruleSummaryPage.policyReports.title.${checkpoint}`)}</div>
        <ViewAffectedOrdersButton
          checkpoint={checkpoint}
          policyId={policyId}
          dateRange={getPolicyReportDateRange(timeframe)}
          isPowerSearchEnabled={isPowerSearchEnabled}
        />
      </T3>
      <Space size="sm" />
      {isError ? (
        <ErrorComponent text={t('ruleSummaryPage.policyReports.error')} />
      ) : (
        <div className={styles.reportContainer}>
          <HitReport
            checkpoint={checkpoint}
            data={data}
            isLoading={isLoading}
            timeframe={timeframe}
            type="Count"
          />
          <HitReport
            checkpoint={checkpoint}
            data={data}
            isLoading={isLoading}
            timeframe={timeframe}
            type="Gmv"
          />
        </div>
      )}
      <Space size="lg" />
    </div>
  )
}

export default PolicyReports
