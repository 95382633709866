import { COMPARISON_OPERATOR } from 'stores/conditionTree'

const comparisonOperator: Record<
  COMPARISON_OPERATOR,
  { is: string; isNot?: string }
> = {
  equals: {
    is: 'Is equal to',
    isNot: 'Is not equal to',
  },
  equalsIgnoreCase: {
    is: 'Is equal to',
    isNot: 'Is not equal to',
  },
  inIgnoreCase: {
    is: 'Is equal to',
    isNot: 'Is not equal to',
  },
  in: {
    is: 'Is in list',
    isNot: 'Is not in list',
  },
  isSubstringIgnoreCase: {
    is: 'Contains',
    isNot: 'Does not contain',
  },
  largerThan: {
    is: 'Is larger than',
    isNot: 'Is not larger than',
  },
  largerThanOrEqualTo: {
    is: 'Is larger than or equal to',
    isNot: 'Is not larger than or equal to',
  },
  lessThan: {
    is: 'Is less than',
  },
  lessThanOrEqualTo: {
    is: 'Is less than or equal to',
  },
  startsWithIgnoreCase: {
    is: 'Starts with',
    isNot: 'Does not start with',
  },
  endsWithIgnoreCase: {
    is: 'Ends with',
    isNot: 'Does not end with',
  },
  isBetween: {
    is: 'Is between',
  },
  isEmptyAndMeaningless: {
    is: 'Is empty',
    isNot: 'Is not empty',
  },
}

export default comparisonOperator
