import { FC } from 'react'
import { Spin } from 'antd'
import { useTranslation, Trans } from 'react-i18next'
import { Layout, Space, ListsIcon, Text } from '@signifyd/components'
import StretchedNoResults from 'core/components/StretchedNoResults'
import AppNavBar from 'core/containers/AppNavBar'
import CreateListButtonWithModal from 'pages/ListPage/containers/CreateListButtonWithModal'
import ListFilters from 'pages/ListPage/containers/ListFilters'
import { ListGrids, ListLoadingGrid } from 'pages/ListPage/containers/ListGrids'
import { useGetLists } from 'pages/ListPage/useGetLists'
import { Filters } from 'stores/predictionList'
import { useListPageQueryParams } from 'pages/ListPage/useListPageQueryParams'
import TeamSelect from 'core/components/TeamSelect'
import useUserTeams from 'core/hooks/useUserTeams'
import TestTeamBanner from 'core/components/TestTeamBanner'
import styles from './ListPageContainer.less'

const { Header, Content, NavBar } = Layout

interface Props {
  filters: Filters
}

const ListPageContainer: FC<Props> = ({ filters }) => {
  const { t } = useTranslation()

  const [, setQuery] = useListPageQueryParams()
  const { liveTeams } = useUserTeams()

  const { data, isLoading, fetchStatus } = useGetLists(filters)

  const headerExtra = (
    <div className={styles.headerExtra}>
      {!!liveTeams?.length && (
        <TeamSelect
          className={styles.teamSelect}
          label={t('common.teamSelect.label')}
          selectedTeamId={filters.teamId}
          onChange={(teamId) => {
            setQuery({ teamId: +teamId })
          }}
        />
      )}
      <CreateListButtonWithModal filters={filters} />
    </div>
  )

  let pageContent

  if (!data || isLoading) {
    pageContent = <ListLoadingGrid />
  } else if (data.predictionLists.length === 0) {
    // Show no lists page if the team doesn't have any lists at all.
    pageContent = (
      <StretchedNoResults
        icon={ListsIcon}
        title={t('listPage.placeholderTitle')}
        description={
          <Trans
            i18nKey="listPage.placeholderDescription"
            components={{ bold: <Text weight="semibold" /> }}
          />
        }
      />
    )
  } else if (data.filteredPredictionLists.length === 0) {
    // Show no results page when the GET call finishes but no lists returned.
    pageContent = (
      <StretchedNoResults
        title={t('listPage.noResultsTitle')}
        description={t('listPage.noResultsDescription')}
      />
    )
  } else {
    // Show real lists when the GET call finishes and there are lists returned.
    pageContent = <ListGrids predictionLists={data.filteredPredictionLists} />
  }

  const { listPageSortByFilter, listPageTypeFilter, listPageExpirationFilter } =
    filters

  return (
    <>
      <NavBar>
        <AppNavBar teamId={Number(filters.teamId)} />
      </NavBar>
      <TestTeamBanner teamId={Number(filters.teamId)} />
      <Header title={t('listCommon.appName')} extra={headerExtra}>
        <div data-test-id="listPage">
          <Space size="sm" />
          {!!data?.predictionLists && (
            <ListFilters
              predictionLists={data.predictionLists}
              predictionListSortBy={listPageSortByFilter}
              predictionListType={listPageTypeFilter}
              predictionListExpiration={listPageExpirationFilter}
              onChangeSortBy={(predictionListSortBy) =>
                setQuery({ sortBy: predictionListSortBy })
              }
              onChangeType={(predictionListType) =>
                setQuery({ type: predictionListType })
              }
              onChangeExpiration={(predictionListExpiration) =>
                setQuery({
                  hasExpiration: predictionListExpiration,
                })
              }
            />
          )}
          <Space size="md" />
        </div>
      </Header>
      {fetchStatus === 'fetching' && data ? (
        <Spin>
          <Content>{pageContent}</Content>
        </Spin>
      ) : (
        <Content>{pageContent}</Content>
      )}
    </>
  )
}

export default ListPageContainer
