import { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import {
  Text,
  Space,
  DiscountCodeIcon,
  ResellerIcon,
} from '@signifyd/components'
import { Modal, Tooltip } from 'antd'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import { InfoCircleOutlined } from '@ant-design/icons'
import ListDetailsForm from 'core/components/ListDetailsForm'
import { HTTPStatus } from 'core/http'
import { SIG_MODAL_PROPS } from 'core/constants'
import GridSelect from 'core/components/GridSelect'
import { useGetLists } from 'pages/ListPage/useGetLists'
import { Filters, LIST_PAGE_TYPE_FILTER } from 'stores/predictionList'
import {
  GetModalPropsByStep,
  PartialCreateListPayload,
  STEP,
} from '../CreateListModal.utils'
import { getStepTrackingText } from '../CreateListModal'
import styles from '../CreateListModal.less'
import { getCountsForTypeFilters } from '../../ListFilters/ListFilters.utils'

const MAX_LIST_ALLOWANCE = 5

interface CreateAbusePreventionListProps {
  visible: boolean
  httpStatus: HTTPStatus
  onOk: (param: PartialCreateListPayload) => void
  onCancel: () => void
  filters: Filters
  setStep: Dispatch<SetStateAction<STEP | undefined>>
  step: STEP
}

const CreateAbusePreventionList: FC<CreateAbusePreventionListProps> = ({
  visible,
  httpStatus,
  onOk,
  onCancel,
  filters,
  setStep,
  step,
}) => {
  const { t } = useTranslation()
  const [name, setName] = useState<string>()
  const [description, setDescription] = useState<string>()
  const [abuseType, setAbuseType] = useState<PREDICTION_LIST_TYPE | undefined>(
    undefined
  )
  const { data, isLoading } = useGetLists(filters, 'abusePreventionModal')

  const resetState = (): void => {
    setAbuseType(undefined)
    setName(undefined)
    setDescription(undefined)
    setStep(STEP.one)
  }

  const getModalPropsByStep: Partial<GetModalPropsByStep> = {
    [STEP.two]: {
      title: (
        <div className={styles.abuseSelectTitleWrapper}>
          {getStepTrackingText(step)}
          <div>
            {t('listCommon.createAbusePreventionListModal.typeSelectionTitle')}
            <Tooltip
              trigger="hover"
              placement="topRight"
              className={styles.helpIcon}
              arrow={{ pointAtCenter: true }}
              data-test-id="titleTooltip"
              title={t(
                'listCommon.createAbusePreventionListModal.typeSelectionTitleTooltip'
              )}
            >
              <InfoCircleOutlined
                style={{ fontSize: '13px' }}
                data-test-id="typeSelectTitleTooltip"
                data-analytics-id="type-select-title-tooltip"
              />
            </Tooltip>
          </div>
        </div>
      ),
      okText: t('listCommon.createListModal.next'),
      cancelText: t('listCommon.createListModal.previous'),
      closable: false,
      okButtonProps: { disabled: !abuseType },
      onCancel: () => {
        onCancel()
      },
      onOk: () => {
        setStep(STEP.three)
      },
    },
    [STEP.three]: {
      title: (
        <>
          {getStepTrackingText(step)}
          <div>
            {abuseType &&
              t('listCommon.createAbusePreventionListModal.listDetailsTitle', {
                abuseType: t(`listCommon.listTypeLowerCase.${abuseType}`),
              })}
          </div>
        </>
      ),
      okText: t('listCommon.createListModal.finish'),
      cancelText: t('listCommon.createListModal.previous'),
      closable: false,
      okButtonProps: {
        disabled: !name?.trim(),
        loading: httpStatus.pending,
      },
      onCancel: () => {
        setStep(STEP.two)
      },
      onOk: () => {
        if (abuseType && name) {
          const payload = description
            ? { type: abuseType, name, description }
            : { type: abuseType, name }

          onOk(payload)
        }
      },
    },
  }

  const typeCountMap = getCountsForTypeFilters(data?.predictionLists ?? [])

  const isPromoAbuseDisabled: boolean =
    (typeCountMap[LIST_PAGE_TYPE_FILTER.PROMO_ABUSE] ?? 0) >= MAX_LIST_ALLOWANCE
  const isResellerAbuseDisabled: boolean =
    (typeCountMap[LIST_PAGE_TYPE_FILTER.RESELLER_ABUSE] ?? 0) >=
    MAX_LIST_ALLOWANCE

  const getStepFormSection: Record<string, ReactNode> = {
    [STEP.two]: (
      <>
        <Text size="sm" block weight="semibold" className={styles.modalText}>
          {t('listCommon.createAbusePreventionListModal.typeSelectionSubTitle')}
        </Text>
        <Space size="sm" />
        <Text
          size="sm"
          fontStyle="italic"
          type="secondary"
          className={styles.modalText}
        >
          <Trans i18nKey="listCommon.createAbusePreventionListModal.typeSelectionDescription" />
        </Text>
        <Space size="lg" />
        <GridSelect
          value={abuseType}
          centered
          items={[
            {
              key: 'PROMO_ABUSE',
              label: t(`listCommon.listTypeStartCase.PROMO_ABUSE`),
              icon: DiscountCodeIcon,
              testId: 'promoAbuseListGridTile',
              disabled: isPromoAbuseDisabled,
              tooltip: isPromoAbuseDisabled
                ? t(
                    'listCommon.createAbusePreventionListModal.maxLimitWarning',
                    {
                      listType: t(`listCommon.listTypeStartCase.PROMO_ABUSE`),
                    }
                  )
                : undefined,
            },
            {
              key: 'RESELLER_ABUSE',
              label: t(`listCommon.listTypeStartCase.RESELLER_ABUSE`),
              icon: ResellerIcon,
              testId: 'resellerAbuseListGridTile',
              disabled: isResellerAbuseDisabled,
              tooltip: isResellerAbuseDisabled
                ? t(
                    'listCommon.createAbusePreventionListModal.maxLimitWarning',
                    {
                      listType: t(
                        `listCommon.listTypeStartCase.RESELLER_ABUSE`
                      ),
                    }
                  )
                : undefined,
            },
          ]}
          span={12}
          width="Full"
          onChange={setAbuseType}
        />
      </>
    ),
    [STEP.three]: (
      <>
        <Text size="sm" weight="semibold" block>
          {abuseType &&
            t('listCommon.createAbusePreventionListModal.listDetailsSubTitle', {
              abuseType: t(`listCommon.listTypeLowerCase.${abuseType}`),
            })}
        </Text>
        <Space size="sm" />
        <Text size="sm" block>
          {abuseType && t('listCommon.createListModal.listDetailsDescription')}
        </Text>
        <Space size="md" />
        <ListDetailsForm
          disabled={httpStatus.pending}
          name={name}
          description={description}
          onChangeName={setName}
          onChangeDescription={setDescription}
          errorMsg={httpStatus.error}
          usedInPolicy={false}
        />
      </>
    ),
  }

  return (
    <Modal
      className={styles.createAbusePreventionModal}
      {...SIG_MODAL_PROPS}
      open={visible}
      afterClose={resetState}
      {...getModalPropsByStep[step]}
    >
      {!isLoading && getStepFormSection[step]}
    </Modal>
  )
}

export default CreateAbusePreventionList
