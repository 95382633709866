import { Moment } from 'moment-timezone'
import { RangePickerValue } from '@signifyd/components'

export const disabledSimulationDate = (
  startDate: Moment,
  endDate: Moment,
  current?: Moment | null
): boolean => {
  if (!current) {
    return false
  }

  return current < startDate || current > endDate
}

export interface DisabledDateConfig {
  oneMonthAgo: Moment
  endOfToday: Moment
  from: Moment | undefined
}

const MAX_DATE_RANGE = 14

export const isDateDisabled = (
  date: Moment | null,
  value: RangePickerValue,
  { endOfToday, oneMonthAgo, from }: DisabledDateConfig
): boolean => {
  if (!date || !value) {
    return false
  }

  if (date.isBefore(oneMonthAgo) || date.isAfter(endOfToday)) {
    return true
  }

  if (from) {
    return (
      date.diff(from, 'days') >= MAX_DATE_RANGE ||
      from.diff(date, 'days') >= MAX_DATE_RANGE
    )
  }

  return false
}
