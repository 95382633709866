import { ReactNode } from 'react'
import { ColumnProps } from 'antd/es/table'
import { PolicyMatchId } from '@signifyd/http'
import { i18nInstance } from '@signifyd/components'
import ExternalLink from 'core/components/ExternalLink'

function renderInvestigationLink(investigationId: number): ReactNode {
  const baseUrl =
    process.env.BACKEND_ENV === 'production'
      ? 'https://app.signifyd.com'
      : 'https://app.staging.signifyd.com'

  return (
    <ExternalLink url={`${baseUrl}/cases/${investigationId}`}>
      {investigationId}
    </ExternalLink>
  )
}

type GetColumns = ({
  isReturnPolicy,
}: {
  isReturnPolicy: boolean
}) => Array<ColumnProps<PolicyMatchId>>

const getColumns: GetColumns = ({ isReturnPolicy }) => {
  if (isReturnPolicy) {
    return [
      {
        title: i18nInstance.t(`ruleValidationPage.columnTitles.returnID`),
        dataIndex: 'returnId',
        key: 'returnId',
      },
    ]
  }

  return [
    {
      title: i18nInstance.t(`ruleValidationPage.columnTitles.orderID`),
      dataIndex: 'orderId',
      key: 'orderId',
    },
    {
      title: i18nInstance.t(`ruleValidationPage.columnTitles.investigationId`),
      dataIndex: 'investigationId',
      key: 'investigationId',
      render: renderInvestigationLink,
    },
  ]
}

export default getColumns
