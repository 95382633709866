import { FC } from 'react'
import { Text } from '@signifyd/components'
import FilterPopover from '../FilterPopover/FilterPopover'
import { columnFilterConfig } from '../VariableTableFilterConfig'
import styles from './TableHeader.less'

export interface TableHeaderProps {
  title: string
  columnKey: keyof typeof columnFilterConfig
}

const TableHeader: FC<TableHeaderProps> = ({ title, columnKey }) => {
  const filter = columnFilterConfig[columnKey]

  return (
    <div className={styles.tableHeader}>
      <Text>{title}</Text>
      <div className={styles.iconWrapper}>
        {!!filter && <FilterPopover filter={filter} />}
      </div>
    </div>
  )
}

export default TableHeader
