import { FC } from 'react'
import { Table } from 'antd'
import { ColumnProps } from 'antd/es/table'
import { Variable } from '@signifyd/http'
import { i18nInstance } from '@signifyd/components'
import AdditionalItemsPopover from 'core/components/AdditionalItemsPopover/AdditionalItemsPopover'
import { ExtendedVariableGroup } from 'pages/AdminPages/queries/adminVariables.types'
import useGetVariableGroups from 'pages/AdminPages/queries/useGetDecisionCenterGroups'
import useGetDecisionCenterVariables from 'pages/AdminPages/queries/useGetDecisionCenterVariables'
import styles from './VariableGroupTable.less'
import AddVariableGroupModal from '../AddVariableGroupModal/AddVariableGroupModal'
import DeleteVariableGroupModal from '../DeleteVariableGroupModal/DeleteVariableGroupModal'
import { VariableGroupModalProvider } from './variableGroupModalContext'
import EditVariableGroupButton from '../EditVariableGroupModal/EditVariableGroupButton'
import EditVariableGroupModal from '../EditVariableGroupModal/EditVariableGroupModal'

const { t } = i18nInstance

type ColumnConfig = ColumnProps<ExtendedVariableGroup>

const getColumns = (variables: Array<Variable>): Array<ColumnConfig> => [
  {
    title: t('variableGroupPage.columnTitles.id'),
    key: 'id',
    dataIndex: 'id',
  },
  {
    title: t('variableGroupPage.columnTitles.name'),
    key: 'name',
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: t('variableGroupPage.columnTitles.variableIds'),
    key: 'variableIds',
    dataIndex: 'variableIds',
    render: (variableIds: Array<number>, { id }) => {
      const variableNames = variableIds
        .map((id) => variables.find((variable) => variable.id === id)?.name)
        .filter((id): id is string => !!id)

      return (
        <div className={styles.variableContainer}>
          <AdditionalItemsPopover items={variableNames} />

          <div>
            <EditVariableGroupButton groupId={id} />
          </div>
        </div>
      )
    },
  },
  {
    title: t('variableGroupPage.columnTitles.delete'),
    key: 'deleteVariableGroup',
    dataIndex: 'id',
    render: (id: number) => <DeleteVariableGroupModal id={id} />,
  },
]

const VariableGroupTable: FC = () => {
  const { data: variables = [], isLoading: isLoadingVariables } =
    useGetDecisionCenterVariables()
  const { data: groups = [], isLoading: isLoadingGroups } =
    useGetVariableGroups()

  const columns = getColumns(variables)

  return (
    <VariableGroupModalProvider>
      <AddVariableGroupModal />
      <Table
        dataSource={groups}
        loading={isLoadingVariables || isLoadingGroups}
        columns={columns}
        rowKey="id"
      />
      <EditVariableGroupModal />
    </VariableGroupModalProvider>
  )
}

export default VariableGroupTable
