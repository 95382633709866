import { Variable } from '@signifyd/http'
import { StringParam } from 'use-query-params'
import { ExtendedVariableGroup } from 'pages/AdminPages/queries/adminVariables.types'

type FilterType = 'string'

type FilterValue = string | number | null | undefined

export interface FilterConfig {
  queryKey: keyof typeof variableTableQueryParams
  filter: (
    item: Variable,
    value: FilterValue,
    groups: Array<ExtendedVariableGroup>
  ) => boolean
  type: FilterType
}

export const variableTableQueryParams = {
  name: StringParam,
  groupName: StringParam,
  description: StringParam,
}

export const columnFilterConfig: Partial<Record<keyof Variable, FilterConfig>> =
  {
    name: {
      queryKey: 'name',
      type: 'string',
      filter: (item, value) =>
        typeof value === 'string' &&
        item.name.toLowerCase().includes(value.toLowerCase()),
    },
    description: {
      queryKey: 'description',
      type: 'string',
      filter: (item, value) =>
        typeof value === 'string' &&
        item.description.toLowerCase().includes(value.toLowerCase()),
    },
    groupIds: {
      queryKey: 'groupName',
      type: 'string',
      filter: (item, value, groups) => {
        if (!value) {
          return false
        }

        const searchValue = value.toString().toLowerCase()

        // this could be expensive
        const matchingGroupIds = groups
          .filter((group) => group.name.toLowerCase().includes(searchValue))
          .map(({ id }) => id)

        return !!item.groupIds?.some((groupId) =>
          matchingGroupIds.includes(groupId)
        )
      },
    },
  }
