import {
  listPredictionLists,
  PredictionList,
  PREDICTION_LIST_TYPE,
} from '@signifyd/http'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import {
  Filters,
  LIST_PAGE_EXPIRATION_FILTER,
  LIST_PAGE_TYPE_FILTER,
  MAP_HAS_EXPIRATION_FILTER_TO_PARAMS,
  MAP_SORT_BY_FILTER_TO_PARAMS,
} from 'stores/predictionList'

const filterByType = (
  predictionList: PredictionList | null,
  type: LIST_PAGE_TYPE_FILTER
): boolean => {
  if (type === 'ALL') {
    return true
  }

  return predictionList?.type === (type as unknown as PREDICTION_LIST_TYPE)
}

const filterByExpiration = (
  predictionList: PredictionList | null,
  expiration: LIST_PAGE_EXPIRATION_FILTER
): boolean => {
  if (!predictionList) {
    return false
  }

  if (expiration === 'ALL') {
    return true
  }

  const { hasExpiration: shouldDisplayExpirations } =
    MAP_HAS_EXPIRATION_FILTER_TO_PARAMS[expiration]

  const listHasExpiration = Object.values(predictionList.expiration).some(
    (expiration) => {
      return expiration !== null
    }
  )

  return listHasExpiration === shouldDisplayExpirations
}

export const filterPredictionLists = (
  filters: Filters,
  predictionLists: Array<PredictionList> | null
): Array<PredictionList> => {
  const { listPageExpirationFilter, listPageTypeFilter } = filters

  if (!filters || !predictionLists) {
    return []
  }

  return predictionLists.filter(
    (predictionList) =>
      filterByType(predictionList, listPageTypeFilter) &&
      filterByExpiration(predictionList, listPageExpirationFilter)
  )
}

interface ReturnType {
  filteredPredictionLists: Array<PredictionList>
  predictionLists: Array<PredictionList>
}

// TODO FET-1817 https://signifyd.atlassian.net/browse/FET-1817
// figure out how to not run filters through react query, expiration and list type are not actually doing a server side filter
export function useGetLists(
  filters: Filters,
  queryName?: string
): UseQueryResult<ReturnType> {
  const { teamId, listPageSortByFilter, listPageExpirationFilter } = filters

  return useQuery<ReturnType>(
    ['predictionLists', filters, queryName],
    async () => {
      const listPredictionListsParams = {
        teamIds: teamId,
        limit: 75,
        ...MAP_SORT_BY_FILTER_TO_PARAMS[listPageSortByFilter],
      }

      const {
        data: { data },
      } = await listPredictionLists(listPredictionListsParams)

      return {
        filteredPredictionLists: filterPredictionLists(filters, data),
        predictionLists: data,
      }
    },
    {
      enabled: !!teamId && !!listPageSortByFilter && !!listPageExpirationFilter,
      keepPreviousData: true,
    }
  )
}
