import { ReactNode } from 'react'
import { Tooltip } from 'antd'
import { ColumnProps } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { i18nInstance, CheckpointActionTag } from '@signifyd/components'
import { CHECKPOINT_ACTION } from '@signifyd/http'
import {
  POLICY_IMPACT_SORT_BY,
  POLICY_IMPACT_SORT_DIRECTION,
} from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import { FormattedImpactPerRule } from 'stores/simulation/simulation.types'

const FIXED_COLUMN_WIDTH = 140

export enum DATA_INDEX {
  RANK = 'rank',
  NAME = 'name',
  ACTION = 'action',
  ORIGINAL_VALUE = 'formattedOriginalValue',
  NEW_VALUE = 'formattedNewValue',
  CHANGE = 'formattedChange',
}

interface MapDataIndexToSortBy {
  [key: string]: POLICY_IMPACT_SORT_BY
}

export const DATA_INDEX_TO_SORT_BY: MapDataIndexToSortBy = {
  [DATA_INDEX.RANK]: POLICY_IMPACT_SORT_BY.RANK,
  [DATA_INDEX.ORIGINAL_VALUE]: POLICY_IMPACT_SORT_BY.BEFORE,
  [DATA_INDEX.NEW_VALUE]: POLICY_IMPACT_SORT_BY.AFTER,
  [DATA_INDEX.CHANGE]: POLICY_IMPACT_SORT_BY.CHANGE,
}

export const SORT_ORDER_TO_SORT_DIRECTION = {
  ascend: POLICY_IMPACT_SORT_DIRECTION.ASCEND,
  descend: POLICY_IMPACT_SORT_DIRECTION.DESCEND,
}

export const getSortOrder = (
  currentColumn: POLICY_IMPACT_SORT_BY,
  colAscending: SortOrder | undefined,
  orderByColumn?: POLICY_IMPACT_SORT_BY
): SortOrder | undefined => {
  return orderByColumn === currentColumn ? colAscending : undefined
}

interface GetColumnProps {
  titles: {
    rank: string
    name: string
    action: string
    before: string
    after: string
    change: string
  }
  policyImpactSortBy?: POLICY_IMPACT_SORT_BY
  policyImpactSortDirection?: POLICY_IMPACT_SORT_DIRECTION
}

type ColumnConfig = ColumnProps<FormattedImpactPerRule>

const renderRuleName = (name: string): ReactNode => (
  <Tooltip placement="topLeft" title={name}>
    {name}
  </Tooltip>
)
const renderRuleAction = (action: CHECKPOINT_ACTION): ReactNode => (
  <CheckpointActionTag action={action} hasTooltip />
)

const renderChangePercentage = (value: string): ReactNode => (
  <Tooltip
    placement="topLeft"
    title={i18nInstance.t(
      'publishWithSimulatorPage.simulationResult.impactPerPolicy.changeTooltip'
    )}
  >
    {value}
  </Tooltip>
)

const getColumns = ({
  titles,
  policyImpactSortBy,
  policyImpactSortDirection,
}: GetColumnProps): Array<ColumnConfig> => {
  const rankCol: ColumnConfig = {
    title: titles.rank,
    key: DATA_INDEX.RANK,
    dataIndex: DATA_INDEX.RANK,
    width: FIXED_COLUMN_WIDTH,
    sorter: true,
    sortDirections: [
      SORT_ORDER_TO_SORT_DIRECTION.ascend,
      SORT_ORDER_TO_SORT_DIRECTION.descend,
      SORT_ORDER_TO_SORT_DIRECTION.ascend,
    ],
    sortOrder: getSortOrder(
      POLICY_IMPACT_SORT_BY.RANK,
      policyImpactSortDirection,
      policyImpactSortBy
    ),
  }

  const nameCol: ColumnConfig = {
    title: titles.name,
    key: DATA_INDEX.NAME,
    dataIndex: DATA_INDEX.NAME,
    ellipsis: true,
    render: renderRuleName,
  }

  const actionCol: ColumnConfig = {
    title: titles.action,
    key: DATA_INDEX.ACTION,
    dataIndex: DATA_INDEX.ACTION,
    width: FIXED_COLUMN_WIDTH,
    render: renderRuleAction,
  }

  const beforeCol: ColumnConfig = {
    title: titles.before,
    key: DATA_INDEX.ORIGINAL_VALUE,
    dataIndex: DATA_INDEX.ORIGINAL_VALUE,
    align: 'right',
    width: FIXED_COLUMN_WIDTH,
    sorter: true,
    sortDirections: [
      SORT_ORDER_TO_SORT_DIRECTION.ascend,
      SORT_ORDER_TO_SORT_DIRECTION.descend,
      SORT_ORDER_TO_SORT_DIRECTION.ascend,
    ],
    sortOrder: getSortOrder(
      POLICY_IMPACT_SORT_BY.BEFORE,
      policyImpactSortDirection,
      policyImpactSortBy
    ),
  }

  const afterCol: ColumnConfig = {
    title: titles.after,
    key: DATA_INDEX.NEW_VALUE,
    dataIndex: DATA_INDEX.NEW_VALUE,
    align: 'right',
    width: FIXED_COLUMN_WIDTH,
    sorter: true,
    sortDirections: [
      SORT_ORDER_TO_SORT_DIRECTION.ascend,
      SORT_ORDER_TO_SORT_DIRECTION.descend,
      SORT_ORDER_TO_SORT_DIRECTION.ascend,
    ],
    sortOrder: getSortOrder(
      POLICY_IMPACT_SORT_BY.AFTER,
      policyImpactSortDirection,
      policyImpactSortBy
    ),
  }

  const changeCol: ColumnConfig = {
    title: titles.change,
    key: DATA_INDEX.CHANGE,
    dataIndex: DATA_INDEX.CHANGE,
    align: 'right',
    width: FIXED_COLUMN_WIDTH,
    sorter: true,
    sortDirections: [
      SORT_ORDER_TO_SORT_DIRECTION.ascend,
      SORT_ORDER_TO_SORT_DIRECTION.descend,
      SORT_ORDER_TO_SORT_DIRECTION.ascend,
    ],
    sortOrder: getSortOrder(
      POLICY_IMPACT_SORT_BY.CHANGE,
      policyImpactSortDirection,
      policyImpactSortBy
    ),
    render: renderChangePercentage,
  }

  return [rankCol, nameCol, actionCol, beforeCol, afterCol, changeCol]
}

export default getColumns
