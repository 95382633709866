export default {
  ruleSummaryCard: {
    title: 'Review policy configuration',
  },
  bestPracticesPanel: {
    title: 'Best practices',
    description1:
      'To ensure the conditions configured will impact the intended orders, validate the policy.',
    description2:
      "We recommend you validate using orders that you expect will match the policy, as well as orders that you don't expect to match. A <bold>match</bold> helps you confirm the policy satisfies its purpose, while a <bold>no-match</bold> confirms the policy is not over-reaching.",
  },
  tooltipTitles: {
    validationFailure:
      'To proceed, the validation must be successful. Please verify the conditions and orders.',
    dirtyInputs: 'Run validation to proceed',
  },
  inputPlaceholders: {
    ORDER_ID: 'Enter order ID separated by commas',
    INVESTIGATION_ID: 'Enter case ID separated by commas',
    RETURN_ID: 'Enter return ID separated by commas',
  },
  idType: {
    order: 'order',
    orderId: 'Order ID',
    case: 'case',
    caseId: 'Case ID',
    return: 'return',
    returnId: 'Return ID',
    none: '',
  },
  refreshResult: 'Refresh results',
  validationSuccess_one: 'Great! Order passed',
  validationSuccess_other: 'Great! All {{count}} orders passed',
  validationFailed_one: 'out of {{count}} order failed',
  validationFailed_other: 'out of {{count}} orders failed',
  matchingValidationTitle: 'Validate condition match',
  nonMatchingValidationTitle: 'Validate condition non-match',
  durationNotice:
    'To ensure the conditions configured will impact the intended orders, validate the policy using orders from the last 30 days.',
  finish: 'Finish',
  next: 'Next step',
  previous: 'Previous',
  invalidIdMessage: 'No case{{pluralSuffix}} found for "{{invalidIds}}"',
  invalidResultsAlertMessage_one: 'No {{idType}} found for "{{invalidIds}}"',
  invalidResultsAlertMessage_other:
    'No {{idType}}s found for "{{invalidIds}}" and "{{lastInvalidId}}"',
  outdatedResultsAlertMessage_one:
    'The {{idType}} ({{outdatedIds}}) entered is more than 30 days old. Validation is only available for {{idType}}s from the last 30 days.',
  outdatedResultsAlertMessage_other:
    'The {{idType}}s ({{outdatedIds}}) entered are more than 30 days old. Validation is only available for {{idType}}s from the last 30 days.',
  columnTitles: {
    orderID: 'ORDER ID',
    caseID: 'CASE ID',
    returnID: 'RETURN ID',
    result: 'RESULT',
    investigationId: 'INVESTIGATION ID',
  },
  itemCount_one: '{{count}} total item',
  itemCount_other: '{{count}} total items',
  optional: 'Optional',
  failureTooltip: {
    NOT_EXPECTED: 'Failed. To proceed, please edit conditions on the left.',
    TOO_OLD:
      'Failed. Validation is only available for {{idType}}s from the last 30 days.',
  },
  editWarning:
    'Since the policy conditions were edited, it is recommended to validate for condition matching and non-matching before proceeding to next step.',
}
