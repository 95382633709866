import { CustomLayerProps } from '@nivo/line'
import {
  isValidPolicyReportingLine,
  policyReportingLineStyles,
} from 'pages/DashboardPage/components/PolicyReporting/PolicyReportingChart.utils'

const DashedLine = ({
  series,
  lineGenerator,
}: CustomLayerProps): Array<JSX.Element> => {
  return series.map(({ id, data, color }) => {
    if (!isValidPolicyReportingLine(id)) {
      return <></>
    }

    return (
      <path
        key={id}
        d={lineGenerator(
          data.map((datum) => ({
            x: datum.position.x,
            y: datum.position.y,
          }))
        )}
        fill="none"
        stroke={color}
        style={policyReportingLineStyles[id]}
      />
    )
  })
}

export default DashedLine
