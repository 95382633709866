import { FC } from 'react'
import { Alert } from 'antd'
import { useTranslation } from 'react-i18next'
import { Space } from '@signifyd/components'

const SimulationResultWarning: FC<{ hasMatchingRuleIds: boolean }> = ({
  hasMatchingRuleIds,
}) => {
  const { t } = useTranslation()

  if (hasMatchingRuleIds) {
    return null
  }

  return (
    <>
      <Space size="md" />
      <Alert
        data-test-id="rule-ids-warning-message"
        message={t(
          'publishWithSimulatorPage.simulationResult.ruleIdsWarningMessage'
        )}
        type="warning"
        showIcon
      />
      <Space size="md" />
    </>
  )
}

export default SimulationResultWarning
