export default {
  header: {
    title: 'Manage variables',
    link: 'Switch to manage groups',
    teamSelectLabel: 'Team',
    checkpointSelectLabel: 'Checkpoint',
    checkpointSelectPlaceholder: 'Choose a checkpoint',
  },
  addVariableToGroupPopover: {
    title: 'Edit group membership for: ',
    updateButton: 'Update',
    cancelButton: 'Cancel',
    editButton: 'Edit',
  },
  columnTitles: {
    id: 'ID',
    name: 'Name',
    description: 'Description',
    checkpoint: 'Checkpoint',
    dateAdded: 'Date added',
    frontEndType: 'Front End Type',
    teamIds: 'Teams',
    isReadOnly: 'Readonly',
    groupIds: 'Variable Groups',
  },
  variableTable: {
    filterPopover: {
      saveButton: 'Save',
      cancelButton: 'Cancel',
    },
    textFilter: {
      placeholder: 'Type to filter',
    },
  },
}
