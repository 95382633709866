export const listGridsColumnProperties = {
  xs: 8,
  sm: 8,
  md: 8,
  lg: 8,
  xl: 6,
  xxl: 4,
}

export const listGridsGutter: [number, number] = [32, 32]
