import { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import { Text, Space } from '@signifyd/components'
import { Modal } from 'antd'
import ListDetailsForm from 'core/components/ListDetailsForm'
import { HTTPStatus } from 'core/http'
import { SIG_MODAL_PROPS } from 'core/constants'
import {
  GetModalPropsByStep,
  PartialCreateListPayload,
  STEP,
} from '../CreateListModal.utils'
import ListTypeModalContent from '../ListTypeModalContent'
import { getStepTrackingText } from '../CreateListModal'

interface CreateStandardListProps {
  visible: boolean
  httpStatus: HTTPStatus
  onOk: (param: PartialCreateListPayload) => void
  onCancel: () => void
  setStep: Dispatch<SetStateAction<STEP | undefined>>
  step: STEP
}

const CreateStandardList: FC<CreateStandardListProps> = ({
  visible,
  httpStatus,
  onOk,
  onCancel,
  setStep,
  step,
}) => {
  const { t } = useTranslation()
  const [listType, setListType] = useState<PREDICTION_LIST_TYPE>()
  const [name, setName] = useState<string>()
  const [description, setDescription] = useState<string>()

  const resetState = (): void => {
    setListType(undefined)
    setName(undefined)
    setDescription(undefined)
    setStep(STEP.one)
  }

  const getModalPropsByStep: Partial<GetModalPropsByStep> = {
    [STEP.two]: {
      okText: t('listCommon.createListModal.continue'),
      cancelText: t('listCommon.createListModal.previous'),
      closable: false,
      okButtonProps: { disabled: !listType },
      onCancel: () => {
        onCancel()
      },
      onOk: () => {
        setStep(STEP.three)
      },
      title: (
        <>
          {getStepTrackingText(step)}
          <div>{t('listCommon.createListModal.title')}</div>
        </>
      ),
    },
    [STEP.three]: {
      okText: t('listCommon.createListModal.finish'),
      cancelText: t('listCommon.createListModal.previous'),
      closable: false,
      okButtonProps: {
        disabled: !name?.trim(),
        loading: httpStatus.pending,
      },
      onCancel: () => {
        setStep(STEP.two)
      },
      onOk: () => {
        if (listType && name) {
          const payload = description
            ? { type: listType, name, description }
            : { type: listType, name }

          onOk(payload)
        }
      },
      title: (
        <>
          {getStepTrackingText(step)}
          <div>
            {listType &&
              t('listCommon.createStandardListModal.listDetailsTitle', {
                listType: t(`listCommon.listTypeLowerCase.${listType}`),
                interpolation: { escapeValue: false },
              })}
          </div>
        </>
      ),
    },
  }

  const getStepFormSection: Record<string, ReactNode> = {
    [STEP.two]: (
      <ListTypeModalContent listType={listType} onChange={setListType} />
    ),
    [STEP.three]: (
      <>
        <Text size="sm" weight="semibold" block>
          {t('listCommon.createListModal.listDetailsDescription')}
        </Text>
        <Space size="sm" />
        <ListDetailsForm
          disabled={httpStatus.pending}
          name={name}
          description={description}
          onChangeName={setName}
          onChangeDescription={setDescription}
          errorMsg={httpStatus.error}
          usedInPolicy={false}
        />
      </>
    ),
  }

  return (
    <Modal
      {...SIG_MODAL_PROPS}
      open={visible}
      afterClose={resetState}
      {...getModalPropsByStep[step]}
    >
      {getStepFormSection[step]}
    </Modal>
  )
}

export default CreateStandardList
