import useUserTeams from 'core/hooks/useUserTeams'
import { useDashboardPageFilters } from './useDashboardPageFilters'

const useTeamId = (): number | undefined => {
  const [{ teamId }] = useDashboardPageFilters()

  const selectedTeamId = useUserTeams().getSelectedTeamId(teamId)

  return selectedTeamId
}

export default useTeamId
