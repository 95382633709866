import { FC, useState } from 'react'
import { Flex, Radio, Skeleton, Space, Tooltip } from 'antd'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { colorSlate } from '@signifyd/colors'
import { InputLabel, T2, Text, useUserContext } from '@signifyd/components'
import { AggregatePolicyReportResponse, CHECKPOINT } from '@signifyd/http'
import {
  formatNumber,
  getPolicyReportDateRange,
  POLICY_REPORTING_TYPE,
  TIMEFRAME,
} from 'core/components/PolicyReporting/PolicyReporting.utils'
import TimeframeSelect from 'core/components/PolicyReporting/TimeframeSelect/TimeframeSelect'
import ViewAffectedOrdersButton from 'core/components/PolicyReporting/ViewAffectedOrdersButton/ViewAffectedOrdersButton'
import { EnhancedPolicies } from 'pages/DashboardPage/containers/DashboardContainer/queries/useGetDashboardContainerData'
import { POLICY_GROUP_TYPE } from 'pages/DashboardPage/DashboardPage.types'
import LineGraph from './LineGraph/LineGraph'
import {
  convertPolicyHistorytoChartData,
  POLICY_REPORTING_LINE,
  reportingLinesByCheckpoint,
} from './PolicyReportingChart.utils'
import styles from './PolicyReportingChart.less'

interface Props {
  checkpoint: CHECKPOINT
  data?: AggregatePolicyReportResponse
  isLoading: boolean
  policies: EnhancedPolicies
  policyGroupType: POLICY_GROUP_TYPE
  setTimeframe: (timeframe: TIMEFRAME) => void
  timeframe: TIMEFRAME
}

const PolicyReportingChart: FC<Props> = ({
  checkpoint,
  data,
  isLoading,
  setTimeframe,
  policies,
  policyGroupType,
  timeframe,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ruleDashboardPage.reporting',
  })

  const [policyReportingType, setPolicyReportingType] = useState(
    POLICY_REPORTING_TYPE.gmv
  )

  const { user } = useUserContext()

  const isPowerSearchEnabled = !!user?.features.POWER_SEARCH?.featureFlag

  const getFontColor = (tabValue: string): string | undefined =>
    tabValue !== policyReportingType ? colorSlate : undefined

  const policyReportingTabs = [
    {
      value: POLICY_REPORTING_TYPE.gmv,
      label: t('gmvTab'),
    },
    {
      value: POLICY_REPORTING_TYPE.count,
      label: t('countTab'),
    },
  ]

  if (checkpoint === CHECKPOINT.LOGIN) {
    return null
  }

  if (isLoading || !data) {
    return (
      <Space direction="vertical">
        <Flex justify="space-between">
          <Skeleton.Input active size="large" />
          <Skeleton.Button active size="default" />
          <Skeleton.Input active size="small" />
        </Flex>
        <Skeleton
          active
          paragraph={{
            rows: 2,
            width: '100%',
          }}
        />
        <Skeleton.Node active style={{ height: '320px', width: '90vw' }} />
      </Space>
    )
  }

  const chartData = convertPolicyHistorytoChartData(
    data,
    policyReportingType === POLICY_REPORTING_TYPE.count ? 'Count' : 'Gmv',
    policies,
    policyGroupType
  )

  return (
    <>
      <div className={styles.header}>
        <div>
          <TimeframeSelect timeframe={timeframe} setTimeframe={setTimeframe} />
          <InputLabel className={styles.timeZoneMessage}>
            {t('timezoneMessage')}
            <Tooltip
              title={t('timezoneTooltip')}
              arrow={{ pointAtCenter: true }}
              placement="topLeft"
              destroyTooltipOnHide
            >
              <QuestionCircleOutlined
                data-test-id="timezoneTooltip"
                className={styles.icon}
              />
            </Tooltip>
          </InputLabel>
        </div>

        <div>
          <Radio.Group
            onChange={(e) => setPolicyReportingType(e.target.value)}
            value={policyReportingType}
            buttonStyle="outline"
          >
            {policyReportingTabs.map(({ label, value }) => (
              <Radio.Button
                style={{ color: getFontColor(value) }}
                key={value}
                data-test-id={`${value}Tab`}
                data-analytics-id={`policy-reporting-${value}-tab`}
                value={value}
              >
                {label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
      </div>
      <div className={styles.titleGrid}>
        <T2 className={styles.title}>{t(`${policyReportingType}Header`)}</T2>
        <div className={styles.viewOrders}>
          <ViewAffectedOrdersButton
            checkpoint={checkpoint}
            dateRange={getPolicyReportDateRange(timeframe)}
            isPowerSearchEnabled={isPowerSearchEnabled}
          />
        </div>
        <div className={styles.hits} data-test-id="aggregateHitsValue">
          <Text block size="xs" className={styles.valueTitle}>
            {t(`values.${policyReportingType}Hits`)}
            <Tooltip
              title={t(
                `values.${policyReportingType}HitsTooltip.${checkpoint}`
              )}
              arrow={{ pointAtCenter: true }}
              placement="topLeft"
              destroyTooltipOnHide
            >
              <QuestionCircleOutlined
                data-test-id="hitsTooltip"
                className={styles.icon}
              />
            </Tooltip>
          </Text>
          <Text block className={styles.valueAmount}>
            {formatNumber(
              data[`hitsBy${policyReportingType === 'gmv' ? 'Gmv' : 'Count'}`],
              policyReportingType
            )}
          </Text>
        </div>
        <div
          className={styles.percentageOfTotal}
          data-test-id="aggregatePercentageValue"
        >
          <Text block size="xs" className={styles.valueTitle}>
            {t(`values.${policyReportingType}PercentageOfTotal.${checkpoint}`)}
            <Tooltip
              title={t(
                `values.${policyReportingType}PercentageOfTotalTooltip.${checkpoint}`
              )}
              arrow={{ pointAtCenter: true }}
              placement="topLeft"
              destroyTooltipOnHide
            >
              <QuestionCircleOutlined
                data-test-id="percentageOfTotalTooltip"
                className={styles.icon}
              />
            </Tooltip>
          </Text>
          <Text block className={styles.valueAmount}>
            {formatNumber(
              data[
                `percentageOfTotal${
                  policyReportingType === 'gmv' ? 'Gmv' : 'Count'
                }`
              ],
              undefined,
              2
            )}
            %
          </Text>
        </div>
        {reportingLinesByCheckpoint[checkpoint].map((line) => {
          if (line === POLICY_REPORTING_LINE.none) {
            return null
          }

          return (
            <div
              className={styles[line]}
              key={line}
              data-test-id={`aggregate${line}Value`}
            >
              <Text block size="xs" className={styles.valueTitle}>
                {t(`values.${line}`)}
              </Text>
              <Text block className={styles.valueAmount}>
                {formatNumber(
                  data[`${policyReportingType}${line}`],
                  policyReportingType
                )}
                <svg height="3" width="32">
                  <line
                    x1="0"
                    y1="0"
                    x2="32"
                    y2="0"
                    className={cx(styles.line, styles[`line-${line}`])}
                  />
                </svg>
              </Text>
            </div>
          )
        })}
      </div>

      <LineGraph
        data={chartData}
        policyReportingType={policyReportingType}
        timeframe={timeframe}
      />
    </>
  )
}

export default PolicyReportingChart
