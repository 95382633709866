import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getRuleSet, RuleSet } from '@signifyd/http'

export const GET_RULE_SET = 'getRuleSet'

const useGetRuleSet = (
  ruleSetId: number | undefined | null
): UseQueryResult<RuleSet> => {
  return useQuery({
    queryKey: [GET_RULE_SET, ruleSetId],
    queryFn: async () => {
      const { data: rule } = await getRuleSet(ruleSetId!)

      return rule
    },
    enabled: !!ruleSetId,
  })
}

export default useGetRuleSet
