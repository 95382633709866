export enum POLICY_GROUP_TYPE {
  published = 'published',
  others = 'others',
}

export enum SELECTED_RULE_ACTION {
  CHANGE_RANK = 'CHANGE_RANK',
  MOVE_TO_PUBLISHED = 'MOVE_TO_PUBLISHED',
  MOVE_TO_OTHERS = 'MOVE_TO_OTHERS',
}
