import { Unauthorized } from '@signifyd/components'
import NavBar from '@signifyd/components/src/components/Layout/_NavBar'
import AppNavBar from 'core/containers/AppNavBar'

interface UnauthorizedLayout {
  message?: string
}
export const UnauthorizedLayout: React.FC<UnauthorizedLayout> = ({
  message,
}) => (
  <NavBar>
    <AppNavBar />
    <Unauthorized message={message} />
  </NavBar>
)
