import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { deletePolicy, RuleResponse } from '@signifyd/http'
import { AxiosError, AxiosResponse } from 'axios'
import { i18nInstance } from '@signifyd/components'
import { message } from 'antd'
import { getHTTPErrorStatus } from 'core/http'
import { GET_DASHBOARD_POLICIES_QUERY_KEY } from './useGetDashboardPolicies/useGetDashboardPolicies'

export const useDeletePolicyInDashboard = (): UseMutationResult<
  AxiosResponse<RuleResponse>,
  AxiosError,
  string | number
> => {
  const queryClient = useQueryClient()

  return useMutation(deletePolicy, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_DASHBOARD_POLICIES_QUERY_KEY])
    },
    onError: (error) => {
      const status = getHTTPErrorStatus(
        error,
        i18nInstance.t('ruleCommon.fallbackErrorMessage.deletePolicy')
      )

      message.error({ content: status.error })
    },
  })
}
