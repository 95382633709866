import { i18nInstance } from '@signifyd/components'

export const maxRuleLimitExceededMessage = (
  maxRuleLimit?: number,
  excessRuleCount?: number
): string => {
  return i18nInstance.t(
    'ruleCommon.fallbackErrorMessage.maxRuleLimitExceededPolicies',
    {
      count: maxRuleLimit,
      movePoliciesText: i18nInstance.t(
        'ruleCommon.fallbackErrorMessage.moveExcessRules',
        {
          count: excessRuleCount,
        }
      ),
      interpolation: {
        escapeValue: false,
      },
    }
  )
}
