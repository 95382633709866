import { message } from 'antd'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  RULE_SET_STAGE,
  RULE_SET_UPDATABLE_FIELD,
  RuleSet,
  updateRuleSet,
} from '@signifyd/http'
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { messageOnError } from 'core/http'
import { GET_RULE_SET } from 'core/queries/useGetRuleSet/useGetRuleSet'
import { LIST_ALL_RULES } from 'pages/PublishWithSimulatorPage/hooks/useGetAllRules/useGetAllRules'

const usePublishRuleSet = (
  editedRuleId?: number | null
): UseMutationResult<
  RuleSet,
  AxiosError<unknown>,
  {
    ruleSet: RuleSet
    editedRuleId?: number | null
  }
> => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async ({ ruleSet }) => {
      const publishRuleSetPayload = {
        ruleSetStage: RULE_SET_STAGE.PUBLISHED,
        ruleIds: ruleSet.ruleIds,
        updateMask: [RULE_SET_UPDATABLE_FIELD.RULE_SET_STAGE],
        editedRuleId: editedRuleId ?? undefined,
      }

      const { data } = await updateRuleSet(
        ruleSet.ruleSetId,
        publishRuleSetPayload
      )

      return data
    },
    onSuccess: async (ruleSet) => {
      await queryClient.invalidateQueries([GET_RULE_SET, ruleSet.ruleSetId])
      await queryClient.invalidateQueries([LIST_ALL_RULES])

      navigate(
        `/policies/dashboard?teamId=${ruleSet.teamId}&checkpoint=${ruleSet.checkpoint}`
      )

      const translationSuffix = editedRuleId
        ? 'updatePolicyAndPublishRuleSet'
        : 'publishRuleSet'

      message.success({
        content: t(`ruleCommon.successMessage.${translationSuffix}`),
        duration: 10,
      })
    },
    onError: () => {
      messageOnError(t('ruleCommon.fallbackErrorMessage.publishRuleSet'))
    },
  })
}

export default usePublishRuleSet
