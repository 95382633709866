import { ReactElement } from 'react'
import { PREDICTION_LIST_TYPE, Variable, VariableType } from '@signifyd/http'

export enum COMPARISON_OPERATOR {
  equals = 'equals',
  equalsIgnoreCase = 'equalsIgnoreCase',
  inIgnoreCase = 'inIgnoreCase',
  in = 'in',
  isSubstringIgnoreCase = 'isSubstringIgnoreCase',
  largerThan = 'largerThan',
  largerThanOrEqualTo = 'largerThanOrEqualTo',
  lessThan = 'lessThan',
  lessThanOrEqualTo = 'lessThanOrEqualTo',
  startsWithIgnoreCase = 'startsWithIgnoreCase',
  endsWithIgnoreCase = 'endsWithIgnoreCase',
  isBetween = 'isBetween',
  isEmptyAndMeaningless = 'isEmptyAndMeaningless',
}

export enum LOGICAL_OPERATOR {
  and = 'and',
  or = 'or',
  not = 'not',
  numberOfConditions = 'numberOfConditions',
}

export interface ComparisonOperatorWithNot {
  name: COMPARISON_OPERATOR
  isNot?: boolean
  icon?: ReactElement
  optionGroup?: string
}

interface LeafProperty {
  property: string
}

export type PredicateLeafValue = boolean | string | number | Array<string>

interface LeafValue {
  value: PredicateLeafValue
}

export type ComparisonPredicate = Record<
  string,
  [LeafProperty, LeafValue] | [LeafValue, LeafProperty]
>

export interface BooleanPredicate {
  property: string
}

export type EmptyPredicate = Record<string, [LeafProperty]>

export type AndPredicate = Record<LOGICAL_OPERATOR.and, Array<Predicate>>

export type OrPredicate = Record<LOGICAL_OPERATOR.or, Array<Predicate>>

export type NumberOfConditionsPredicate = Record<
  LOGICAL_OPERATOR.numberOfConditions,
  Array<Predicate>
>

export type NotPredicate = Record<LOGICAL_OPERATOR.not, Array<Predicate>>

export type Predicate = Record<string, any>

export enum BOOLEAN_AS_STRING {
  true = 'true',
  false = 'false',
}

export type ConditionTreeLeafNodeValue =
  | BOOLEAN_AS_STRING
  | string
  | Array<string>
  | null

export interface PredictionListFeature {
  name: string
  deprecatedName: string
  type: PREDICTION_LIST_TYPE
}

export interface RuleFeatureWithPredictionListMapping extends Variable {
  predictionListFeature?: PredictionListFeature
}

export interface ConditionTreeLeafNode {
  id: string
  ruleFeature: RuleFeatureWithPredictionListMapping | null
  operator: COMPARISON_OPERATOR | null
  value: ConditionTreeLeafNodeValue
  isNot: boolean
}

export interface ConditionTreeNode {
  id: string
  operator: LOGICAL_OPERATOR | null
  children: Array<ConditionTreeNode | ConditionTreeLeafNode>
  numberOfConditions?: number
}

export type NodeById = Record<string, ConditionTreeNode | ConditionTreeLeafNode>

export interface SetNodePayload {
  nodeId: string
  partialNode: Partial<ConditionTreeNode> | Partial<ConditionTreeLeafNode>
}

export interface UpdateOperatorAndAppendPayload {
  nodeId: string
  operator: LOGICAL_OPERATOR
}

export interface DeleteChildPayload {
  nodeId: string
  childNodeIndex: number
}

export type RuleFeaturesByName = Map<
  string,
  RuleFeatureWithPredictionListMapping
>

export type NumberVariableTypes = Extract<
  VariableType,
  'DOUBLE' | 'OPTION_DOUBLE' | 'LONG' | 'OPTION_LONG'
>
