import { useState, FC } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import CreateListModal from 'pages/ListPage/containers/CreateListModal'
import useCreatePredictionList from 'core/hooks/useCreatePredictionList'
import { HTTPStatus } from 'core/http'
import { Filters } from 'stores/predictionList'
import CreateStandardList from '../CreateListModal/CreateStandardListModal/CreateStandardListModal'
import CreateAbusePreventionList from '../CreateListModal/CreateAbusePreventionListModal/CreateAbusePreventionListModal'
import { STEP } from '../CreateListModal/CreateListModal.utils'

interface Props {
  filters: Filters
}

const CreateListButtonWithModal: FC<Props> = ({ filters }) => {
  const { t } = useTranslation()
  const [listType, setListType] = useState<string | undefined>(undefined)
  const [step, setStep] = useState<STEP | undefined>()

  const {
    mutateAsync: createPredictionList,
    isLoading,
    isSuccess,
    error,
  } = useCreatePredictionList()

  const createPredictionListHTTPStatus: HTTPStatus = {
    pending: isLoading,
    error: error?.message,
    success: isSuccess,
  }

  const isModalVisible = (type: 'standard' | 'abusePrevention'): boolean =>
    (step === STEP.two || step === STEP.three) && listType === type

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setStep(STEP.one)}
        data-test-id="showCreateListModalButton"
      >
        {t('listCommon.createListBtn')}
      </Button>
      <CreateListModal
        visible={step === STEP.one}
        onCancel={() => {
          setStep(undefined)
          setListType(undefined)
        }}
        onOk={() => {
          setStep(STEP.two)
        }}
        listType={listType}
        setListType={setListType}
      />
      <CreateStandardList
        visible={isModalVisible('standard')}
        httpStatus={createPredictionListHTTPStatus}
        onOk={async (listDetails) => {
          await createPredictionList({
            teamId: +filters.teamId,
            ...listDetails,
          })
        }}
        setStep={setStep}
        step={step!}
        onCancel={() => setStep(STEP.one)}
      />
      <CreateAbusePreventionList
        visible={isModalVisible('abusePrevention')}
        httpStatus={createPredictionListHTTPStatus}
        onOk={async (listDetails) => {
          await createPredictionList({
            teamId: +filters.teamId,
            ...listDetails,
          })
        }}
        setStep={setStep}
        step={step!}
        onCancel={() => setStep(STEP.one)}
        filters={filters}
      />
    </>
  )
}

export default CreateListButtonWithModal
