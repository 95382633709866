import { useMemo } from 'react'
import { toFinite } from 'lodash'
import moment from 'moment-timezone'
import { UserConfigs } from '@signifyd/app-feature-configs'
import { useUserContext, useUpdateUserState } from '@signifyd/components'
import { User, UserTeam } from '@signifyd/http'

interface UserTeamsResult {
  getFilteredTeams: (
    search: string | undefined,
    selectedTeamId: string
  ) => Array<UserTeam> | undefined
  getSelectedTeamId: (teamId?: number | null) => number | undefined
  liveTeams?: Array<UserTeam>
  setHomepageToDashboard: () => void
  updateUiState: (uiStateToUpdate: any) => void
  user?: User
  userConfigs?: UserConfigs
  userTimeZone: string
}

// TODO FET-1773 Look at cleaning this up https://signifyd.atlassian.net/browse/FET-1773
// Break this up into multiple hooks, there doesn't need to be a single hook wrapping all of this up
const useUserTeams = (): UserTeamsResult => {
  const { user, teams, userConfigs } = useUserContext()
  const updateUserUiState = useUpdateUserState(user)

  const userTimeZone = user?.uiState?.timeZone?.name || moment.tz.guess()

  const liveTeams = useMemo(
    () =>
      teams
        ?.filter(({ active }) => active)
        .sort((a, b) => Number(a.test) - Number(b.test)),
    [teams]
  )

  const getFilteredTeams = useMemo(
    () =>
      (search = '', selectedTeamId: string) => {
        const MAX_TEAMS = 1000

        return liveTeams
          ?.reduce((previous, current) => {
            const currentTeamId = current.teamId.toString()

            if (currentTeamId === selectedTeamId) {
              return [current, ...previous]
            }

            if (current.teamName.toLowerCase().includes(search.toLowerCase())) {
              return [...previous, current]
            }

            return previous
          }, [] as Array<UserTeam>)
          .slice(0, MAX_TEAMS)
      },
    [liveTeams]
  )

  const getSelectedTeamId = useMemo(
    () => (teamId?: number | null) => {
      if (!liveTeams?.length) {
        return undefined
      }

      const teamIdNum = toFinite(teamId)

      if (teamIdNum && teamIdNum > 0) {
        return teamIdNum
      }

      return liveTeams[0].teamId
    },
    [liveTeams]
  )

  const setHomepageToDashboard = (): void => {
    const homepageIsDashboard =
      user?.uiState?.decisionCenter?.homepageIsDashboard ?? false

    if (!user || homepageIsDashboard) {
      return
    }

    const currentUIState = user.uiState?.decisionCenter ?? {}
    const decisionCenterUIState = {
      ...currentUIState,
      homepageIsDashboard: true,
    }
    const uiState = { ...user.uiState, decisionCenter: decisionCenterUIState }

    updateUserUiState.mutate(uiState)
  }

  const updateUiState = (uiStateToUpdate: any): void => {
    const initialUiState = user?.uiState ?? {}

    updateUserUiState.mutate({
      ...initialUiState,
      ...uiStateToUpdate,
    })
  }

  return {
    getFilteredTeams,
    getSelectedTeamId,
    liveTeams,
    setHomepageToDashboard,
    updateUiState,
    user,
    userConfigs,
    userTimeZone,
  }
}

export default useUserTeams
