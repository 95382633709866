import { Alert, Modal } from 'antd'
import { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Text } from '@signifyd/components'
import { SIG_MODAL_PROPS } from 'core/constants'
import styles from './ListExpiration.less'

export interface Props {
  visible: boolean
  loading: boolean
  setVisible: (param: boolean) => void
  onClick: () => void
}

const DeleteExpirationModal: FC<Props> = ({
  visible,
  loading,
  setVisible,
  onClick,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      {...SIG_MODAL_PROPS}
      title={t('listDetailsPage.sidePanel.expirationForm.deleteModalTitle')}
      open={visible}
      okButtonProps={{
        loading,
      }}
      okText={t('listDetailsPage.sidePanel.expirationForm.deleteButton')}
      onCancel={() => setVisible(false)}
      onOk={onClick}
      closable={false}
      cancelText={t('listDetailsPage.sidePanel.expirationForm.cancelButton')}
    >
      <Alert
        message={t('listDetailsPage.sidePanel.expirationForm.deleteNotice')}
        type="info"
        showIcon
      />
      <div className={styles.expirationDeleteMessage}>
        <Trans
          i18nKey="listDetailsPage.sidePanel.expirationForm.deleteMessage"
          components={{ bold: <Text weight="semibold" /> }}
        />
      </div>
    </Modal>
  )
}

export default DeleteExpirationModal
