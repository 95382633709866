import { useNavigate } from 'react-router-dom'
import { RuleResponse, updateRule } from '@signifyd/http'
import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { Menu, Tooltip, message, MenuItemProps } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  policy: RuleResponse
  menuKey: string
}

const useUpdatePolicy = (
  ruleId: number,
  predicate: string
): UseMutationResult<void, unknown, void, unknown> => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async () => {
      await updateRule(ruleId, {
        draftMatchingPolicyMatchIds: [],
        draftNonMatchingPolicyMatchIds: [],
        draftPredicate: predicate,
      })
    },

    onSuccess: () => {
      navigate(`/policies/edit/${ruleId}/conditions`)
    },

    onError: () => {
      message.error(t('ruleCommon.fallbackErrorMessage.updateRule'))
    },
  })
}

const EditPolicyMenuItem: FC<Props> = ({ policy, menuKey, ...props }) => {
  const { predicate, ruleId, canModifyPolicy } = policy

  const { t } = useTranslation()
  const { mutate, isLoading } = useUpdatePolicy(ruleId, predicate)

  const handleClick: MenuItemProps['onClick'] = ({ domEvent }): void => {
    domEvent.stopPropagation()

    mutate()
  }

  return (
    <Menu.Item
      {...props} // passing through antd props from menu
      key={menuKey}
      data-test-id="editPolicyConfigurationMenuItem"
      onClick={handleClick}
      disabled={!canModifyPolicy || isLoading}
      data-analytics-id="edit-policy-configuration-menu-item"
    >
      <Tooltip title={!canModifyPolicy && t('ruleCommon.dropdown.editTooltip')}>
        {t('ruleCommon.dropdown.editConfiguration')}
      </Tooltip>
    </Menu.Item>
  )
}

export default EditPolicyMenuItem
