import { FC, ReactNode, useState, useEffect } from 'react'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Button } from 'antd'
import { CHECKPOINT } from '@signifyd/http'
import { useStoreActions } from 'stores'
import CreatePolicyModal from 'pages/DashboardPage/containers/CreatePolicyModal'

interface Props {
  children: ReactNode
  icon?: string
  defaultModalVisible?: boolean
  teamCheckpoints: Array<CHECKPOINT>
}

const CreatePolicyButton: FC<Props> = ({
  children,
  icon,
  defaultModalVisible = false,
  teamCheckpoints,
}) => {
  const [visible, setVisible] = useState(defaultModalVisible)

  const { createPolicy } = useStoreActions((actions) => actions.ruleModel)

  useEffect(() => {
    setVisible(defaultModalVisible)
  }, [defaultModalVisible, setVisible])

  return (
    <>
      <Button
        type="primary"
        data-test-id="create-policy-modal-trigger"
        onClick={() => setVisible(true)}
        icon={<LegacyIcon type={icon} />}
      >
        {children}
      </Button>
      <CreatePolicyModal
        open={visible}
        onCancel={() => setVisible(false)}
        onSubmit={createPolicy}
        teamCheckpoints={teamCheckpoints}
      />
    </>
  )
}

export default CreatePolicyButton
