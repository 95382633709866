import { FC, useState } from 'react'
import { RULE_STAGE, CHECKPOINT } from '@signifyd/http'
import { GET_RULES_LIMIT } from 'core/constants'
import useListRules from 'core/queries/useListRules/useListRules'
import RuleSuccessModal from './RuleSuccessModal'

export interface RuleCreationSuccessModalProps {
  teamId: string
  checkpoint: CHECKPOINT
  onClickLater: () => void
  onClickCreateRule: () => void
}

const RuleCreationSuccessModal: FC<RuleCreationSuccessModalProps> = ({
  teamId,
  onClickLater,
  onClickCreateRule,
  checkpoint,
}) => {
  const [visible, setVisible] = useState(true)

  const { data: rules } = useListRules(
    {
      teamIds: teamId,
      stages: RULE_STAGE.NEW,
      limit: GET_RULES_LIMIT,
      checkpoints: checkpoint,
    },
    { enabled: true }
  )

  const handleCancel = (): void => {
    setVisible(false)
    onClickLater()
  }

  if (!rules) {
    return null
  }

  return (
    <RuleSuccessModal
      visible={visible}
      handleCancel={handleCancel}
      rules={rules}
      setVisible={() => setVisible}
      onClickCreateRule={onClickCreateRule}
      teamId={teamId}
      checkpoint={checkpoint}
      isCreate
    />
  )
}

export default RuleCreationSuccessModal
