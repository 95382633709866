import { FC } from 'react'
import { Form } from '@ant-design/compatible'
import { FormItemProps } from '@ant-design/compatible/lib/form'
import { joinClassNames } from '@signifyd/utils'
import styles from './Form.less'

const { Item } = Form

const FormItem: FC<FormItemProps> = ({ label, ...props }) => {
  return (
    <Item
      label={label}
      className={joinClassNames([styles.formItem, props.className])}
      {...props}
    />
  )
}

export default FormItem
