import { FC } from 'react'
import { Col, Row } from 'antd'
import { listGridsGutter, listGridsColumnProperties } from 'pages/ListPage'
import { PredictionListSkeletonCard } from 'pages/ListPage/components/PredictionListCard'

const ListLoadingGrid: FC = () => {
  return (
    <div data-test-id="listLoadingGrid">
      <Row gutter={listGridsGutter}>
        <Col {...listGridsColumnProperties}>
          <PredictionListSkeletonCard />
        </Col>
        <Col {...listGridsColumnProperties}>
          <PredictionListSkeletonCard />
        </Col>
        <Col {...listGridsColumnProperties}>
          <PredictionListSkeletonCard />
        </Col>
      </Row>
    </div>
  )
}

export default ListLoadingGrid
