import { FC } from 'react'
import { Text } from '@signifyd/components'
import { WarningOutlined } from '@ant-design/icons'
import styles from './ErrorComponent.less'

interface Props {
  text: string
}

const ErrorComponent: FC<Props> = ({ text }: Props) => {
  return (
    <div className={styles.container}>
      <WarningOutlined className={styles.icon} />
      <Text className={styles.text} block size="sm">
        {text}
      </Text>
    </div>
  )
}

export default ErrorComponent
