import { FC, useMemo } from 'react'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import {
  POLICY_IMPACT_SORT_BY,
  POLICY_IMPACT_SORT_DIRECTION,
  VIEW_TYPE,
} from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import { useStoreState } from 'stores'
import { FormattedImpactPerRule } from 'stores/simulation/simulation.types'
import getColumns, {
  DATA_INDEX_TO_SORT_BY,
  SORT_ORDER_TO_SORT_DIRECTION,
} from './ImpactPerPolicyTable.config'

const ImpactPerPolicyTable: FC = () => {
  const { t } = useTranslation()
  const [filters, setFilters] = useQueryParams(PublishPageQueryParams)
  const { viewType, policyImpactSortBy, policyImpactSortDirection } = filters

  const formattedImpactPerRule = useStoreState((state) =>
    viewType === VIEW_TYPE.COUNT
      ? state.simulationModel.formattedCountImpactPerRule
      : state.simulationModel.formattedGmvImpactPerRule
  )

  const columns = useMemo(
    () =>
      getColumns({
        titles: {
          rank: t(
            'publishWithSimulatorPage.simulationResult.impactPerPolicy.rank'
          ),
          name: t(
            'publishWithSimulatorPage.simulationResult.impactPerPolicy.name'
          ),
          action: t(
            'publishWithSimulatorPage.simulationResult.impactPerPolicy.action'
          ),
          before: t(
            'publishWithSimulatorPage.simulationResult.impactPerPolicy.before'
          ),
          after: t(
            'publishWithSimulatorPage.simulationResult.impactPerPolicy.after'
          ),
          change: t(
            'publishWithSimulatorPage.simulationResult.impactPerPolicy.change'
          ),
        },
        policyImpactSortBy,
        policyImpactSortDirection,
      }),

    [t, policyImpactSortBy, policyImpactSortDirection]
  )

  type SortableKey = keyof Pick<
    FormattedImpactPerRule,
    'rank' | 'originalValue' | 'newValue' | 'change'
  >

  const getSortKey: Record<POLICY_IMPACT_SORT_BY, SortableKey> = {
    [POLICY_IMPACT_SORT_BY.RANK]: 'rank',
    [POLICY_IMPACT_SORT_BY.BEFORE]: 'originalValue',
    [POLICY_IMPACT_SORT_BY.AFTER]: 'newValue',
    [POLICY_IMPACT_SORT_BY.CHANGE]: 'change',
  }

  const sortImpactPerRule = (
    impactPerRule: Array<FormattedImpactPerRule>,
    sortBy?: POLICY_IMPACT_SORT_BY | null,
    sortDirection?: POLICY_IMPACT_SORT_DIRECTION | null
  ): Array<FormattedImpactPerRule> => {
    if (!sortBy || !sortDirection) {
      return impactPerRule
    }

    return impactPerRule.sort((impact1, impact2) => {
      const key = getSortKey[sortBy]

      return sortDirection === POLICY_IMPACT_SORT_DIRECTION.ASCEND
        ? impact1[key] - impact2[key]
        : impact2[key] - impact1[key]
    })
  }

  const onChangeTableFilter = (
    policyImpactSortBy?: POLICY_IMPACT_SORT_BY | null,
    policyImpactSortDirection?: POLICY_IMPACT_SORT_DIRECTION | null
  ): void => {
    setFilters({
      policyImpactSortBy: policyImpactSortBy ?? undefined,
      policyImpactSortDirection: policyImpactSortDirection ?? undefined,
    })
  }

  return (
    <Table
      rowKey="id"
      size="middle"
      pagination={false}
      columns={columns}
      dataSource={sortImpactPerRule(
        formattedImpactPerRule,
        policyImpactSortBy,
        policyImpactSortDirection
      )}
      onChange={(_pagination, _filter, sorter) => {
        // We don't currently support multi sorting, which was added in ant 5
        const sortData = Array.isArray(sorter) ? sorter[0] : sorter

        const sortBy = sortData.column?.dataIndex
          ? DATA_INDEX_TO_SORT_BY[
              sortData.column?.dataIndex as keyof typeof DATA_INDEX_TO_SORT_BY
            ]
          : undefined

        const sortDirection = sortData.order
          ? SORT_ORDER_TO_SORT_DIRECTION[sortData.order]
          : undefined

        onChangeTableFilter(sortBy, sortDirection)
      }}
    />
  )
}

export default ImpactPerPolicyTable
