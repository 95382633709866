import { isFinite } from 'lodash'

function isPositiveDecimal(value?: string | number | boolean): boolean {
  if (!value || isFinite(value)) {
    return false
  }
  const reg = /^\d+\.?\d*$/

  return reg.test(String(value))
}

export default isPositiveDecimal
