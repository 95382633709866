import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CATCH_ALL_PATH } from 'core/constants'
import { useStoreActions, useStoreState } from 'stores'
import { useValidatePolicies } from '../useValidatePolicies/useValidatePolicies'

/**
 * Loads a given policy for validation (runs initial validation if editing)
 */
const useLoadPolicyForValidation = (
  urlPolicyId: string | undefined,
  shouldRunInitialValidation = false,
  isEditing: boolean
): void => {
  const navigate = useNavigate()
  const policy = useStoreState((state) => state.ruleModel.policy)
  const { getPolicy, resetState: resetPolicyModelState } = useStoreActions(
    (actions) => actions.ruleModel
  )

  useValidatePolicies(
    shouldRunInitialValidation ? policy?.matchingPolicyMatchIds : [],
    true,
    isEditing,
    policy?.ruleId,
    policy?.teamId
  )

  useValidatePolicies(
    shouldRunInitialValidation ? policy?.nonMatchingPolicyMatchIds : [],
    false,
    isEditing,
    policy?.ruleId,
    policy?.teamId
  )

  useEffect(() => {
    if (!urlPolicyId) {
      navigate(CATCH_ALL_PATH)

      return
    }

    getPolicy({ policy: { ruleId: urlPolicyId, isEditing }, navigate })
  }, [
    getPolicy,
    isEditing,
    navigate,
    resetPolicyModelState,
    shouldRunInitialValidation,
    urlPolicyId,
  ])
}

export default useLoadPolicyForValidation
