import { FC } from 'react'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { T1, EntitySingleSelect, ENTITY_TYPE } from '@signifyd/components'
import { CHECKPOINT } from '@signifyd/http'
import useVariableManagerQueryParams from 'pages/AdminPages/queries/useVariableManagerQueryParams'
import styles from './VariablePageHeader.less'

const VariablePageHeader: FC = () => {
  const { t } = useTranslation()

  const [query, setQuery] = useVariableManagerQueryParams()

  return (
    <>
      <div className={styles.header}>
        <T1>{t('variablePage.header.title')}</T1>
        <Link to="/admin/groups">{t('variablePage.header.link')}</Link>
      </div>
      <div className={styles.header}>
        <div className={styles.select}>
          <span>{t('variablePage.header.teamSelectLabel')}</span>
          <EntitySingleSelect
            entityType={ENTITY_TYPE.TEAM}
            selectedKey={query.teamId ?? undefined}
            onSave={(teamId) => {
              setQuery({ teamId })
            }}
            allowClear
          />
        </div>
        <div className={styles.select}>
          <span>{t('variablePage.header.checkpointSelectLabel')}</span>
          <Select
            className={styles.checkpointSelect}
            placeholder={t('variablePage.header.checkpointSelectPlaceholder')}
            value={query.checkpoint}
            onChange={(checkpoint) => {
              setQuery({ checkpoint })
            }}
            allowClear
          >
            {Object.values(CHECKPOINT).map((checkpoint) => (
              <Select.Option key={checkpoint} value={checkpoint}>
                {t(`common.checkpoint.${checkpoint}`)}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </>
  )
}

export default VariablePageHeader
