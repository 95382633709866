import { i18nInstance } from '@signifyd/components'
import { CHECKPOINT, RuleSimulation } from '@signifyd/http'
import { abbreviateBigNumber } from '@signifyd/utils'
import { VIEW_TYPE } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import {
  checkpointDistributionsMap,
  DistributionTableData,
  RowDistributionKeys,
  SimulationDistributions,
} from 'stores/simulation'
import { MAXIMUM_FRACTION_DIGITS } from 'stores/simulation/simulation.store.utils'
import { getDistributionTitleRow } from './SimulationDistributionTable.config'

const formatForCountTable = (numberToFormat: number): string =>
  abbreviateBigNumber(numberToFormat, MAXIMUM_FRACTION_DIGITS)

const formatForGmvTable = (numberToFormat: number): string =>
  `$${abbreviateBigNumber(numberToFormat, MAXIMUM_FRACTION_DIGITS)}`

export const getDistributionColumnKey = (
  distribution: keyof SimulationDistributions
): string => `${distribution}Column`

/**
 * Dynamically generates rows based on an array of distributions
 */
const getDistributionRows = (
  tableDistributions: RowDistributionKeys,
  simulationDistributions: SimulationDistributions,
  formatter: (count: number) => string
): Array<DistributionTableData> => {
  const distributionRows = tableDistributions.map((row, i) => {
    let rowTotal = 0
    const distributionColumns = tableDistributions.reduce<
      Record<string, string>
    >((columnsSoFar, column) => {
      const resultForDistribution = simulationDistributions[row][column]

      rowTotal += resultForDistribution

      return {
        ...columnsSoFar,
        [getDistributionColumnKey(column)]: formatter(resultForDistribution),
      }
    }, {} as Record<string, string>)

    const rowIndex = i + 2

    return {
      key: rowIndex.toString(),
      titleColumn: i18nInstance.t(
        `publishWithSimulatorPage.simulationDistributionTable.${row}`
      ),
      distributionColumns,
      totalColumn: formatter(rowTotal),
    }
  })

  return distributionRows
}

const getTotalRow = (
  tableDistributions: RowDistributionKeys,
  simulationDistributions: SimulationDistributions,
  formatter: (count: number) => string
): DistributionTableData => {
  let totalRowTotal = 0
  const distributionColumns = tableDistributions.reduce<Record<string, string>>(
    (columnsSoFar, column) => {
      let totalForColumn = 0

      tableDistributions.forEach((row) => {
        totalForColumn += simulationDistributions[row][column]
      })
      totalRowTotal += totalForColumn

      return {
        ...columnsSoFar,
        [getDistributionColumnKey(column)]: formatter(totalForColumn),
      }
    },
    {}
  )

  const totalColumn = formatter(totalRowTotal)

  const rowIndex = tableDistributions.length + 2

  return {
    key: rowIndex.toString(),
    titleColumn: i18nInstance.t(
      'publishWithSimulatorPage.simulationDistributionTable.total'
    ),
    distributionColumns,
    totalColumn,
  }
}

/**
 * Given a simulation, generate rows of data for a distribution table based on the passed view type and checkpoint
 */
export const getDistributionTableData = (
  simulation: RuleSimulation | null,
  viewType: VIEW_TYPE,
  checkpoint: CHECKPOINT
): Array<DistributionTableData> => {
  const DISTRIBUTION_TABLE_TITLE_ROW = getDistributionTitleRow(checkpoint)
  if (!simulation) {
    return [DISTRIBUTION_TABLE_TITLE_ROW]
  }
  const simulationDistributions =
    viewType === VIEW_TYPE.GMV
      ? simulation.distributionsByGmv
      : simulation.distributionsByCount

  const formatter =
    viewType === VIEW_TYPE.GMV ? formatForGmvTable : formatForCountTable

  const tableDistributions = checkpointDistributionsMap[checkpoint]

  const distributionRows = getDistributionRows(
    tableDistributions,
    simulationDistributions,
    formatter
  )

  const totalRow = getTotalRow(
    tableDistributions,
    simulationDistributions,
    formatter
  )

  return [DISTRIBUTION_TABLE_TITLE_ROW, ...distributionRows, totalRow]
}
