import { DEFAULT_STATUS } from 'core/http'
import { RuleState } from './rule.types'

export const DEFAULT_STATE = {
  policy: null,
  conditionTree: null,
  policyFeatures: null,
  policyFeaturesByName: null,
  policyHTTPStatuses: {
    createPolicy: { ...DEFAULT_STATUS },
    updatePolicy: { ...DEFAULT_STATUS },
  },
  predictionListMap: null,
  predictionListsHTTPStatuses: {
    listPredictionLists: { ...DEFAULT_STATUS },
  },
}

const ruleState: RuleState = { ...DEFAULT_STATE }

export default ruleState
