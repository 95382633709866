import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query'
import {
  getDecisionCenterVariableGroups,
  addDecisionCenterVariableGroup,
  deleteDecisionCenterVariableGroup,
  VariableGroup,
} from '@signifyd/http'
import { AxiosResponse } from 'axios'
import useGetDecisionCenterVariables from './useGetDecisionCenterVariables'
import { ExtendedVariableGroup } from './adminVariables.types'

const useGetVariableGroups = (): UseQueryResult<
  Array<ExtendedVariableGroup>
> => {
  const { data: variables } = useGetDecisionCenterVariables()

  return useQuery({
    queryKey: ['allVariableGroups', variables],
    queryFn: async () => {
      const {
        data: { data },
      } = await getDecisionCenterVariableGroups()

      return data.map((group) => {
        const variableIds = variables!
          .filter((variable) => {
            return variable.groupIds?.includes(group.id)
          })
          .map(({ id }) => id)

        return {
          ...group,
          variableIds,
        }
      })
    },
    enabled: !!variables,
  })
}

export default useGetVariableGroups

export const useAddVariableGroup = (): UseMutationResult<
  AxiosResponse<VariableGroup>,
  unknown,
  string
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (name: string) => {
      const res = await addDecisionCenterVariableGroup(name)

      return res
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['allVariableGroups'])
    },
  })
}

export const useDeleteVariableGroup = (): UseMutationResult<
  unknown,
  unknown,
  number
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id: number) => {
      await deleteDecisionCenterVariableGroup(id)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['allVariableGroups'])
    },
  })
}
