import { Space, Text } from '@signifyd/components'
import { Alert, Modal } from 'antd'
import { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'

interface Props {
  isVisible: boolean
  onDelete: (policyId: number) => void
  onCancel: () => void
  policyId: number
}

const DeletePolicyModal: FC<Props> = ({
  isVisible,
  onCancel,
  onDelete,
  policyId,
}) => {
  const { t } = useTranslation()

  const handleDelete = async (): Promise<void> => {
    onDelete(policyId)
  }

  return (
    <Modal
      open={isVisible}
      onOk={handleDelete}
      okText={
        <span data-test-id="confirmDeletePolicyButton">
          {t('common.deletePolicyModal.okText')}
        </span>
      }
      onCancel={onCancel}
      title={t('common.deletePolicyModal.title')}
      okButtonProps={{ type: 'link' }}
      cancelButtonProps={{ type: 'link' }}
      closable={false}
    >
      <Alert type="error" message={t('common.deletePolicyModal.alert')} />
      <Space size="md" />
      <Text>
        <Trans
          i18nKey="common.deletePolicyModal.message"
          components={{
            bold: <Text weight="semibold" />,
          }}
        />
      </Text>
    </Modal>
  )
}

export default DeletePolicyModal
