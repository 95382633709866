import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ResponsiveSankey } from '@nivo/sankey'
import { Space, Text } from '@signifyd/components'
import { useQueryParams } from 'use-query-params'
import { useTypedRouteParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { useStoreState } from 'stores'
import { SANKEY_CONFIG, SANKEY_THEME_CONFIG } from './DistributionSankey.config'
import {
  getDistributionSankeyData,
  getNodeColumn,
} from './DistributionSankey.data'
import { getValueByViewType, mapIdToLabel } from './DistributionSankey.util'
import styles from './DistributionSankey.less'

const DistributionSankey: FC = () => {
  const { t } = useTranslation()

  const routeParams = useTypedRouteParams()
  const [filters] = useQueryParams(PublishPageQueryParams)
  const { viewType } = filters

  const ruleSimulation = useStoreState(
    (state) => state.simulationModel.ruleSimulation
  )

  const sankeyData = useMemo(() => {
    if (!routeParams?.checkpoint) {
      return null
    }

    return getDistributionSankeyData(
      ruleSimulation,
      viewType!,
      routeParams.checkpoint
    )
  }, [ruleSimulation, viewType, routeParams?.checkpoint])

  if (
    !viewType ||
    !sankeyData ||
    sankeyData.links.length === 0 ||
    sankeyData.nodes.length === 0
  ) {
    return null
  }

  return (
    <div data-test-id="distribution-sankey">
      <div className={styles.titleContainer}>
        <Text
          className={styles.title}
          type="secondary"
          size="xs"
          transform="uppercase"
        >
          {t('publishWithSimulatorPage.distributionSankey.before')}
        </Text>
        <Text
          className={styles.title}
          type="secondary"
          size="xs"
          transform="uppercase"
        >
          {t('publishWithSimulatorPage.distributionSankey.after')}
        </Text>
      </div>
      <Space size="xs" />
      <div className={styles.chartWrapper}>
        <ResponsiveSankey
          {...SANKEY_CONFIG}
          data={sankeyData}
          label={mapIdToLabel}
          linkTooltip={({ link }) => {
            const value = getValueByViewType(viewType, link.value)

            return (
              <div
                className={styles.tooltip}
              >{`${link.source.label} → ${link.target.label} : ${value}`}</div>
            )
          }}
          nodeTooltip={({ node }) => {
            const column = getNodeColumn(node)
            const value = getValueByViewType(viewType, node.value)

            return (
              <div
                className={styles.tooltip}
              >{`${node.label} (${column}): ${value}`}</div>
            )
          }}
          theme={SANKEY_THEME_CONFIG}
          animate={false}
          colors={sankeyData.colors}
        />
      </div>
    </div>
  )
}

export default DistributionSankey
