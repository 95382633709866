import { FC, useState } from 'react'
import { RangePickerValue, Space } from '@signifyd/components'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import { SIMULATION_STATUS } from '@signifyd/http'
import StretchedNoResults from 'core/components/StretchedNoResults'
import useUserTeams from 'core/hooks/useUserTeams'
import CreateSimulationContainer from 'pages/PublishWithSimulatorPage/containers/CreateSimulationContainer'
import RunningSimulationContainer from 'pages/PublishWithSimulatorPage/containers/RunningSimulationContainer'
import SimulationResultContainer from 'pages/PublishWithSimulatorPage/containers/SimulationResultContainer'
import { useStoreState } from 'stores'
import SimulationResultHeader from '../SimulationResultHeader'

export interface SimulationDatePickerProps {
  dateRangeValue: NonNullable<RangePickerValue>
  setDateRange: (dateRangeValue: RangePickerValue) => void
  userTimeZone: string
}

const SimulationContainer: FC = () => {
  const { t } = useTranslation()
  const { userTimeZone } = useUserTeams()
  const simulation = useStoreState(
    (state) => state.simulationModel.ruleSimulation
  )
  const noInvestigations = useStoreState(
    (state) => state.simulationModel.noInvestigations
  )

  if (userTimeZone) {
    moment.tz.setDefault(userTimeZone)
  }

  const sevenDaysAgo = moment().subtract(7, 'days')
  const now = moment()

  const simulationDateRange = [
    sevenDaysAgo,
    now,
  ] as NonNullable<RangePickerValue>

  const [dateRangeValue, setDateRange] =
    useState<NonNullable<RangePickerValue>>(simulationDateRange)

  const handleDateRange = (rangeValue: RangePickerValue): void => {
    setDateRange(rangeValue ?? [null, null])
  }

  if (noInvestigations) {
    return (
      <div data-test-id="noResultsFoundContainer">
        <Space size="lg" />
        <SimulationResultHeader
          setDateRange={handleDateRange}
          dateRangeValue={dateRangeValue}
          userTimeZone={userTimeZone}
        />
        <Space size="lg" />
        <StretchedNoResults
          title={t('publishWithSimulatorPage.noResultsFound.title')}
          description={t('publishWithSimulatorPage.noResultsFound.description')}
        />
      </div>
    )
  }

  if (simulation?.status === SIMULATION_STATUS.RUNNING) {
    return <RunningSimulationContainer />
  }

  if (simulation?.status === SIMULATION_STATUS.FINISHED) {
    return (
      <SimulationResultContainer>
        <SimulationResultHeader
          setDateRange={handleDateRange}
          dateRangeValue={dateRangeValue}
          userTimeZone={userTimeZone}
        />
      </SimulationResultContainer>
    )
  }

  return (
    <CreateSimulationContainer
      dateRangeValue={dateRangeValue}
      setDateRange={handleDateRange}
      userTimeZone={userTimeZone}
    />
  )
}

export default SimulationContainer
