import { Input } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  value?: string
  onChange: (value?: string) => void
}

const TextFilter: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation()

  return (
    <Input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      allowClear
      placeholder={t('variablePage.variableTable.textFilter.placeholder')}
    />
  )
}

export default TextFilter
