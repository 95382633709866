import {
  COMPARISON_OPERATOR,
  ConditionTreeLeafNodeValue,
  RuleFeatureWithPredictionListMapping,
} from 'stores/conditionTree'

export enum LOGICAL_OPERATOR {
  and = 'and',
  or = 'or',
  not = 'not',
  numberOfConditions = 'numberOfConditions',
}

export type ComparisonOperator = COMPARISON_OPERATOR | null

export interface SubConditionNode {
  id: string
  ruleFeature: RuleFeatureWithPredictionListMapping | null
  operator: ComparisonOperator
  value: ConditionTreeLeafNodeValue
  isNot: boolean
}

export interface ConditionNode {
  id: string
  operator: LOGICAL_OPERATOR | null
  children: Map<string, SubConditionNode>
  numberOfConditions: number | null
}

export interface ConditionTree {
  id: string
  operator: LOGICAL_OPERATOR | null
  nodes: Map<string, ConditionNode>
}
