import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router'
import { UnauthorizedLayout } from '../UnauthorizedLayout/UnauthorizedLayout'

interface ProtectedProps {
  isUserAuthorized: boolean
}

export const Protected: FC<ProtectedProps> = ({ isUserAuthorized }) => {
  const { t } = useTranslation()

  if (!isUserAuthorized) {
    return <UnauthorizedLayout message={t('common.unauthorized.default')} />
  }

  return <Outlet />
}
