import { CHECKPOINT } from '@signifyd/http'
import { CREATE_POLICY_MODAL_STATE } from './CreatePolicyModal.types'

const getStartingModalState = (
  usableCheckpoints: Array<CHECKPOINT>
): CREATE_POLICY_MODAL_STATE => {
  return usableCheckpoints.length > 1
    ? CREATE_POLICY_MODAL_STATE.selectCheckpoint
    : CREATE_POLICY_MODAL_STATE.enterDetails
}

export { getStartingModalState }
