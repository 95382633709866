import { ConditionTreeLeafNode } from 'stores/conditionTree'
import { PredictionListMap } from 'stores/rule/rule.store.constants'

const useSelectedPredictionListName = (
  predictionListMap: PredictionListMap | null,
  leafNode: ConditionTreeLeafNode
): string | undefined => {
  const { predictionListFeature } = leafNode.ruleFeature || {}

  if (
    leafNode.value &&
    !Number.isNaN(+leafNode.value) &&
    predictionListMap &&
    predictionListFeature
  ) {
    const predictionList = predictionListMap[predictionListFeature?.type].find(
      (list) => list.id.toString() === leafNode.value?.toString()
    )

    return predictionList?.name
  }

  return undefined
}

export default useSelectedPredictionListName
