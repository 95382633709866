export default {
  title: 'Manage groups',
  variablePageLink: 'Switch to manage variables',
  addVariableGroupModal: {
    toggleButton: 'Create new variable group',
    title: 'Add new variable group',
    namePlaceholder: 'Enter new group name',
    variablesPlaceholder: 'Select initial variables here',
  },
  deleteGroupModal: {
    title: 'Delete variable group',
    toggleButton: 'Delete',
    confirmButton: 'Delete',
    description: 'Are you sure you want to delete this variable group?',
  },
  editGroupModal: {
    title: 'Edit variable group',
    toggleButton: 'Edit',
  },
  columnTitles: {
    id: 'ID',
    name: 'Name',
    variableIds: 'Variables',
    delete: 'Delete',
  },
}
