import { SimulationState, IsLoadingStatuses } from './simulation.types'

export const DEFAULT_LOADING_STATE: IsLoadingStatuses = {
  createRuleSimulation: false,
  cancelRuleSimulation: false,
  getRuleSimulation: false,
}

export const DEFAULT_STATE: SimulationState = {
  ruleSimulation: null,
  simulationDateRange: [null, null],
  noInvestigations: false,
  progressInDecimal: null,
  stopPoll: null,
  simulationId: null,
  isLoading: DEFAULT_LOADING_STATE,
}

const simulationState = { ...DEFAULT_STATE }

export default simulationState
