export default {
  conditions: {
    conditionTitle: 'Configure condition',
    conditionLabel: 'IF',
    addCondition: 'Add condition',
    addSubcondition: 'Add sub-condition',
    placeholder: 'Select one',
    selectNumber: 'Select number',
    options: {
      and: 'All',
      or: 'Any',
      numberOfConditions: 'Partial',
      not: 'Not',
    },
    discardConditionPopconfirm: {
      title: 'Please confirm before proceeding',
      description: 'This action will discard the conditions set below.',
    },
    match: 'Match',
    numberOfSubConditions: 'of the above sub-conditions',
    conditionNumber: 'Condition {{number}}',
    invalidNumberOfConditions:
      'Add 3 or more sub-conditions to enable partial matching',
    tooltip: {
      all: 'All (and)- all criteria must be met',
      or: 'Any (or) - at least one criterion must be met',
      partial: 'Partial - specify the number of criteria that must be met',
    },
  },
  conditionInputs: {
    operatorSelect: {
      placeholder: 'Select one',
    },
    valueSelect: {
      placeholder: 'Select one',
      notFoundContent:
        "Oops — your entry doesn't match available values. Try selecting.",
    },
    numberInput: {
      placeholder: 'Enter number',
      and: 'and',
      betweenError: 'Values cannot be the same, please try again',
    },
    stringInput: {
      placeholder: 'Enter value',
    },
    binInput: {
      placeholder: 'Enter BIN',
    },
    predictionListSelect: {
      placeholder: 'Select one',
      notFoundContent: 'No available lists',
      createList: 'Create list',
      itemsCount_one: '{{count}} item',
      itemsCount_other: '{{count}} items',
    },
    deleteButton: {
      tooltipTitle: 'Remove',
    },
  },
  ruleRecommendedActionSelect: {
    cardTitle: 'Select action when conditions match an order',
    cardLabel: 'THEN',
    placeholder: 'Select one',
    options: {
      ACCEPT: 'Accept',
      REJECT: 'Reject',
      HOLD: 'Hold',
      CHALLENGE: 'Challenge',
      CREDIT: 'Credit',
    },
    disabledTooltip:
      'Action cannot be edited on an existing policy. Duplicate this policy to edit the action.',
    footer:
      'Note: You will still be able to view the Signifyd Guarantee or Score. This action determines the next step for the order.',
  },
  editReason: {
    title: 'Reason for edit (required)',
    subTitle: 'Give a reason for the edit.',
    placeholder: 'Reason for edit goes in here',
    tooltip: 'Edit the policy and give a reason to proceed to the next step.',
  },
  nextButton: 'Next step',
  allVariables: 'All variables',
}
