import { Button, Modal } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeleteVariableGroup } from 'pages/AdminPages/queries/useGetDecisionCenterGroups'

interface Props {
  id: number
}

const DeleteVariableGroupModal: FC<Props> = ({ id }) => {
  const { t } = useTranslation()

  const deleteVariableGroup = useDeleteVariableGroup()

  const [isVisible, setIsVisible] = useState(false)

  return (
    <>
      <Button
        danger
        onClick={() => {
          setIsVisible(true)
        }}
      >
        {t('variableGroupPage.deleteGroupModal.toggleButton')}
      </Button>
      <Modal
        visible={isVisible}
        title={t('variableGroupPage.deleteGroupModal.title')}
        okText={t('variableGroupPage.deleteGroupModal.confirmButton')}
        onOk={async () => {
          await deleteVariableGroup.mutateAsync(id)

          setIsVisible(false)
        }}
        onCancel={() => {
          setIsVisible(false)
        }}
        confirmLoading={deleteVariableGroup.isLoading}
      >
        {t('variableGroupPage.deleteGroupModal.description')}
      </Modal>
    </>
  )
}

export default DeleteVariableGroupModal
