import {
  CHECKPOINT,
  getPolicyReport,
  PolicyReportResponse,
} from '@signifyd/http'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import {
  getPolicyReportDateRange,
  TIMEFRAME,
} from 'core/components/PolicyReporting/PolicyReporting.utils'

const useGetPolicyReport = (
  policyId: number,
  checkpoint: CHECKPOINT,
  timeframe: TIMEFRAME
): UseQueryResult<PolicyReportResponse> => {
  return useQuery(
    ['policyReport', policyId, timeframe],
    async () => {
      const [startDate, endDate] = getPolicyReportDateRange(timeframe)

      const { data } = await getPolicyReport(policyId, startDate, endDate)

      return data
    },
    {
      enabled: !!policyId && checkpoint !== CHECKPOINT.LOGIN,
      cacheTime: 0,
    }
  )
}

export default useGetPolicyReport
