import {
  PolicyMatchId,
  VALIDATE_RULE_ID_TYPE,
  ValidateRuleResultItem,
  VALIDATION_FAILURE_REASON,
} from '@signifyd/http'

export enum ID_TYPE {
  NONE = 'none',
  ORDER_ID = 'order',
  INVESTIGATION_ID = 'case',
  RETURN_ID = 'return',
}

type ValidIdTypes = Exclude<ID_TYPE, ID_TYPE.NONE>

type ValidResultItemTypes = Pick<
  ValidateRuleResultItem,
  'orderId' | 'investigationId' | 'returnId'
>

type IdTypeHash = Record<ValidIdTypes, keyof ValidResultItemTypes>

/**
 * Generate an array of new policy match ids from a given array of some policy validation type (investigation, order etc)
 */
export const generateNewPolicyMatchIds = (
  ids: Array<StrOrNum>,
  idType: VALIDATE_RULE_ID_TYPE
): Array<PolicyMatchId> => {
  const policyMatchIds: Array<PolicyMatchId> = ids.map((id) => ({
    investigationId:
      idType === VALIDATE_RULE_ID_TYPE.INVESTIGATION_ID ? Number(id) : null,
    orderId: idType === VALIDATE_RULE_ID_TYPE.ORDER_ID ? id.toString() : null,
    returnId: idType === VALIDATE_RULE_ID_TYPE.RETURN_ID ? id.toString() : null,
  }))

  return policyMatchIds
}

export const getIdTypeFromResults = (
  results: Array<ValidateRuleResultItem>
): ID_TYPE => {
  if (!results?.length) {
    return ID_TYPE.NONE
  }

  const [firstResult] = results

  if (firstResult.investigationId) {
    return ID_TYPE.INVESTIGATION_ID
  }

  if (firstResult.returnId) {
    return ID_TYPE.RETURN_ID
  }

  if (firstResult.orderId) {
    return ID_TYPE.ORDER_ID
  }

  return ID_TYPE.NONE
}

const idTypePropertyMap: IdTypeHash = {
  [ID_TYPE.ORDER_ID]: 'orderId',
  [ID_TYPE.INVESTIGATION_ID]: 'investigationId',
  [ID_TYPE.RETURN_ID]: 'returnId',
}

export const getInvalidIdsFromInvalidResults = (
  invalidResults: Array<ValidateRuleResultItem>,
  idType: ID_TYPE
): Array<string> => {
  if (idType === ID_TYPE.NONE) {
    return []
  }

  return invalidResults.map((result) => `${result[idTypePropertyMap[idType]]}`)
}

export const getOutdatedIdsFromValidationResults = (
  validationResults: Array<ValidateRuleResultItem>,
  idType: ID_TYPE
): Array<string> => {
  if (idType === ID_TYPE.NONE) {
    return []
  }

  return validationResults.reduce<Array<string>>((acc, result) => {
    if (result.validationFailureReason === VALIDATION_FAILURE_REASON.TOO_OLD) {
      acc.push(`${result[idTypePropertyMap[idType]]}`)
    }

    return acc
  }, [] as Array<string>)
}

export const hasValidationFailed = (
  results: Array<ValidateRuleResultItem> = []
): boolean => results.some(({ conditionTestResult }) => !conditionTestResult)
