import type { CHECKPOINT_ACTION } from '@signifyd/http'

const checkpointAction: Record<CHECKPOINT_ACTION, string> = {
  ACCEPT: 'Accept',
  REJECT: 'Reject',
  CHALLENGE: 'Challenge',
  CREDIT: 'Credit',
  HOLD: 'Hold',
}

export default checkpointAction
