import { useEffect } from 'react'
import * as z from 'zod'
import { getFirstCheckpoint } from '../DashboardPage.utils'
import useSelectedTeamCheckpoints from '../queries/useSelectedTeamCheckpoints'
import useTeamId from './useTeamId'
import {
  DashboardPageFilters,
  useDashboardPageFilters,
} from './useDashboardPageFilters'

const useInitializeDashboard = (): void => {
  const [filters, setFilters] = useDashboardPageFilters()

  const { data: selectedTeamCheckpoints = [] } = useSelectedTeamCheckpoints()

  const teamId = useTeamId()

  const checkpoint = getFirstCheckpoint(selectedTeamCheckpoints)

  useEffect(() => {
    if (!teamId || !checkpoint) {
      return
    }

    const newFilters: Partial<DashboardPageFilters> = {}

    if (
      !filters.checkpoint ||
      !selectedTeamCheckpoints.includes(filters.checkpoint)
    ) {
      newFilters.checkpoint = checkpoint
    }

    if (!z.number().safeParse(filters.teamId).success) {
      newFilters.teamId = teamId
    }

    if (Object.keys(newFilters).length) {
      setFilters(newFilters)
    }
  }, [checkpoint, filters, selectedTeamCheckpoints, setFilters, teamId])
}

export default useInitializeDashboard
