import {
  RULE_SET_UPDATABLE_FIELD,
  RuleResponse,
  UpdateRuleSetPayload,
} from '@signifyd/http'

export const getUpdateRuleSetPayload = (
  publishedRules: Array<RuleResponse>
): UpdateRuleSetPayload => {
  const { RULE_IDS, DRAFT_RULE_CHANGES } = RULE_SET_UPDATABLE_FIELD
  const ruleIds = publishedRules.map(({ ruleId }) => ruleId)
  const draftRuleChanges = publishedRules.map(
    ({ ruleId, scheduleEnd, scheduleStart }) => {
      return {
        ruleId,
        scheduleStart: scheduleStart || undefined,
        scheduleEnd: scheduleEnd || undefined,
      }
    }
  )

  return {
    ruleIds,
    draftRuleChanges,
    updateMask: [RULE_IDS, DRAFT_RULE_CHANGES],
  }
}
