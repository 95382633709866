import { create } from 'zustand'
import { nanoid } from 'nanoid'
import { cloneDeep } from 'lodash'
import {
  SubConditionNode,
  ConditionTree,
  ConditionNode,
  LOGICAL_OPERATOR,
} from 'pages/PolicyConditionsPage/components/ConditionTreeRule/ConditionTreeRule.types'

const EMPTY_CONDITION_TREE: ConditionTree = {
  id: nanoid(),
  operator: null,
  nodes: new Map<string, ConditionNode>(),
}

const EMPTY_NODE: SubConditionNode = {
  id: nanoid(),
  ruleFeature: null,
  operator: null,
  value: null,
  isNot: false,
}

const EMPTY_SUBCONDITION: ConditionNode = {
  id: nanoid(),
  operator: null,
  children: new Map<string, SubConditionNode>().set(EMPTY_NODE.id, EMPTY_NODE),
  numberOfConditions: null,
}

interface ConditionTreeState {
  conditionTree: ConditionTree
  setConditionTree: (conditionTree: ConditionTree) => void
  conditionActions: {
    addCondition: (operator: LOGICAL_OPERATOR) => void
    updateConditionOperator: (operator: LOGICAL_OPERATOR) => void
    removeCondition: (id: string) => void
  }
  subConditionActions: {
    addSubCondition: (parentKey: string, operator: LOGICAL_OPERATOR) => void
    updateSubCondition: (
      parentKey: string,
      id: string,
      node: Partial<SubConditionNode>
    ) => void
    removeSubCondition: (parentKey: string, id: string) => void
    updateSubConditionOperator: (
      parentKey: string,
      operator: LOGICAL_OPERATOR,
      numberOfConditions?: number
    ) => void
  }
}

export const useConditionTreeStore = create<ConditionTreeState>((set) => ({
  conditionTree: EMPTY_CONDITION_TREE,
  setConditionTree: (conditionTree: ConditionTree) =>
    set(() => ({ conditionTree })),
  conditionActions: {
    addCondition: (operator) =>
      set((state) => {
        const tree = cloneDeep(state.conditionTree)

        const newNode = {
          ...EMPTY_SUBCONDITION,
          id: nanoid(),
        }

        tree.nodes.set(newNode.id, newNode)
        tree.operator = operator

        return { conditionTree: tree }
      }),
    updateConditionOperator: (operator) =>
      set((state) => {
        const tree = cloneDeep(state.conditionTree)

        tree.operator = operator

        return { conditionTree: tree }
      }),
    removeCondition: (id) =>
      set((state) => {
        const tree = cloneDeep(state.conditionTree)

        tree.nodes.delete(id)

        return { conditionTree: tree }
      }),
  },
  subConditionActions: {
    updateSubCondition: (parentKey, id, node) =>
      set((state) => {
        const tree = cloneDeep(state.conditionTree)
        const existingNode = tree.nodes.get(parentKey)?.children.get(id)

        if (existingNode) {
          const subConditionNode: SubConditionNode = {
            ...existingNode,
            ...node,
          }

          tree.nodes.get(parentKey)?.children.set(id, subConditionNode)
        }

        return { conditionTree: tree }
      }),
    addSubCondition: (parentKey, operator) =>
      set((state) => {
        const tree = cloneDeep(state.conditionTree)

        const existingSubCondition = tree.nodes.get(parentKey)

        if (existingSubCondition) {
          const newNode = {
            ...EMPTY_NODE,
            id: nanoid(),
          }

          existingSubCondition.children.set(newNode.id, newNode)

          existingSubCondition.operator = operator
        }

        return { conditionTree: tree }
      }),
    removeSubCondition: (parentKey, id) =>
      set((state) => {
        const tree = cloneDeep(state.conditionTree)
        const node = tree.nodes.get(parentKey)

        if (node?.children.size === 1) {
          const newNode = {
            ...EMPTY_NODE,
            id,
          }

          node?.children.set(id, newNode)
        } else {
          node?.children.delete(id)
        }

        if (
          node?.operator === LOGICAL_OPERATOR.numberOfConditions &&
          node.numberOfConditions &&
          node.numberOfConditions >= node.children.size
        ) {
          node.numberOfConditions = null
        }

        return { conditionTree: tree }
      }),

    updateSubConditionOperator: (parentKey, operator, numberOfConditions) =>
      set((state) => {
        const tree = cloneDeep(state.conditionTree)

        const existingSubCondition = tree.nodes.get(parentKey)

        if (existingSubCondition) {
          existingSubCondition.operator = operator
          existingSubCondition.numberOfConditions = null

          if (operator === LOGICAL_OPERATOR.numberOfConditions) {
            existingSubCondition.numberOfConditions = numberOfConditions ?? null
          }
        }

        return { conditionTree: tree }
      }),
  },
}))

export const useSubConditionTreeActions =
  (): ConditionTreeState['subConditionActions'] =>
    useConditionTreeStore((state) => state.subConditionActions)

export const useConditionTreeActions =
  (): ConditionTreeState['conditionActions'] =>
    useConditionTreeStore((state) => state.conditionActions)
