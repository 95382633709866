import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Statistic, Divider, Row, Col, Tooltip } from 'antd'
import { Text, Space } from '@signifyd/components'

interface Props {
  title: ReactNode
  value: string
  percentage: string
  originalNumber: string
}

const TotalImpactStatistic: FC<Props> = ({
  title,
  value,
  percentage,
  originalNumber,
}) => {
  const { t } = useTranslation()

  return (
    <div data-test-id="totalImpactStatisticWrapper">
      <Statistic title={title} value={value} />
      <Space size="xs" />
      <Row>
        <Col>
          <Tooltip
            placement="top"
            title={t('publishWithSimulatorPage.totalImpactCard.tooltip')}
          >
            <Text>{percentage}</Text>
          </Tooltip>
        </Col>
        <Col>
          <Divider type="vertical" />
        </Col>
        <Col>
          <Text type="secondary">
            {t(
              'publishWithSimulatorPage.totalImpactCard.numberBeforeSimulation',
              { formattedNumber: originalNumber }
            )}
          </Text>
        </Col>
      </Row>
    </div>
  )
}

export default TotalImpactStatistic
