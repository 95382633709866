import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { listRules, getRuleSet, CHECKPOINT, RuleSet } from '@signifyd/http'

interface Props {
  teamId?: number | null
  checkpoint?: CHECKPOINT | null
}

export const USE_PUBLISHED_RULESET_QUERY_KEY = 'ruleSet'

export const usePublishedRuleset = ({
  teamId,
  checkpoint,
}: Props): UseQueryResult<RuleSet | null> => {
  return useQuery({
    queryKey: [USE_PUBLISHED_RULESET_QUERY_KEY, teamId, checkpoint],
    enabled: !!teamId && !!checkpoint,
    keepPreviousData: true,
    queryFn: async () => {
      const {
        data: { data },
      } = await listRules({
        teamIds: teamId?.toString(),
        limit: 1,
        checkpoints: checkpoint!,
      })

      const [rule] = data

      if (!rule?.activeRuleSetId) {
        return null
      }

      const { data: ruleSet } = await getRuleSet(rule.activeRuleSetId)

      return ruleSet
    },
  })
}
