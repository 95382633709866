import { i18nInstance, Text } from '@signifyd/components'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { RuleResponse } from '@signifyd/http'
import { Trans } from 'react-i18next'
import { Fragment } from 'react'
import EditPolicyMenuItem from 'core/components/EditPolicyMenuItem'
import DuplicatePolicyMenuItem from 'core/components/DuplicatePolicyMenuItem'
import { SELECTED_RULE_ACTION } from 'pages/DashboardPage/DashboardPage.types'
import { isPublishedPolicy } from 'core/constants'
import { TIMEFRAME } from 'core/components/PolicyReporting/PolicyReporting.utils'
import { MENU_KEYS, OnClickRuleMenuItem } from './RuleDropdown.types'
import styles from './RuleDropdown.less'

const getMenuItems = (
  rule: RuleResponse,
  onClickEditRuleDetails: OnClickRuleMenuItem,
  onClickManageSchedule: OnClickRuleMenuItem,
  onClickDeletePolicy: OnClickRuleMenuItem,
  timeframe: TIMEFRAME
): Array<JSX.Element> => {
  const ruleHasSchedule = rule.scheduleStart || rule.scheduleEnd
  const getMoveRulePath = (selectedRuleAction: SELECTED_RULE_ACTION): string =>
    `/policies/publish/${rule.teamId}/${rule.checkpoint}?selectedRuleId=${rule.ruleId}&selectedRuleAction=${selectedRuleAction}`
  const isPublished = isPublishedPolicy(rule)

  const viewSummary = (
    <Menu.Item key={MENU_KEYS.ViewSummary} data-test-id="viewSummaryMenuItem">
      <Link to={`/policies/summary/${rule.ruleId}?timeframe=${timeframe}`}>
        {i18nInstance.t('ruleDashboardPage.ruleDropdown.viewSummary')}
      </Link>
    </Menu.Item>
  )

  const editRuleDetails = (
    <Menu.Item
      key={MENU_KEYS.EditRuleDetails}
      data-test-id={`editRuleDetailsMenuItem-${rule.ruleId}`}
      onClick={() => onClickEditRuleDetails(rule)}
    >
      {i18nInstance.t('ruleCommon.dropdown.editDetails')}
    </Menu.Item>
  )

  const editPolicy = (
    <EditPolicyMenuItem
      key={MENU_KEYS.EditPolicy}
      menuKey={MENU_KEYS.EditPolicy}
      policy={rule}
    />
  )

  const duplicatePolcy = (
    <DuplicatePolicyMenuItem
      policy={rule}
      data-test-id={`duplicatePolicyDetailsMenuItem-${rule.ruleId}`}
      key={MENU_KEYS.DuplicatePolicy}
      menuKey={MENU_KEYS.DuplicatePolicy}
    />
  )

  const deletePolicy = (
    <Menu.Item
      key={MENU_KEYS.DeletePolicy}
      data-test-id={`deletePolicyDetailsMenuItem-${rule.ruleId}`}
      onClick={() => onClickDeletePolicy(rule)}
    >
      {i18nInstance.t('ruleDashboardPage.ruleDropdown.deletePolicy')}
    </Menu.Item>
  )

  const manageSchedule = (
    <Menu.Item
      key={MENU_KEYS.ManageSchedule}
      data-test-id={`manageScheduleMenuItem-${rule.ruleId}`}
      onClick={() => onClickManageSchedule(rule)}
    >
      {i18nInstance.t(
        `ruleDashboardPage.ruleDropdown.${
          ruleHasSchedule ? 'editSchedule' : 'addSchedule'
        }`
      )}
    </Menu.Item>
  )

  const publishingDivider = (
    <Fragment key="PUBLISHING">
      <Menu.Divider className={styles.divider} />
      <Menu.Item disabled data-test-id="publishingDividerMenuItem">
        {i18nInstance.t('ruleDashboardPage.ruleDropdown.publishingDivider')}
      </Menu.Item>
    </Fragment>
  )

  const managePolicyDivider = (
    <Fragment key="MANAGE POLICY">
      <Menu.Divider className={styles.divider} />
      <Menu.Item disabled data-test-id="managePolicyDividerMenuItem">
        {i18nInstance.t('ruleDashboardPage.ruleDropdown.managePolicyDivider')}
      </Menu.Item>
    </Fragment>
  )

  const moveToPublished = (
    <Menu.Item
      key={MENU_KEYS.MoveToPublished}
      data-test-id={`moveToPublishedMenuItem-${rule.ruleId}`}
    >
      <Link to={getMoveRulePath(SELECTED_RULE_ACTION.MOVE_TO_PUBLISHED)}>
        <Trans
          i18nKey="ruleDashboardPage.ruleDropdown.moveToPublished"
          components={{ bold: <Text weight="semibold" /> }}
        />
      </Link>
    </Menu.Item>
  )

  const changeRank = (
    <Menu.Item
      key={MENU_KEYS.ChangeRank}
      data-test-id={`changeRankMenuItem-${rule.ruleId}`}
    >
      <Link to={getMoveRulePath(SELECTED_RULE_ACTION.CHANGE_RANK)}>
        {i18nInstance.t('ruleDashboardPage.ruleDropdown.changeRank')}
      </Link>
    </Menu.Item>
  )

  const moveToOthers = (
    <Menu.Item
      key={MENU_KEYS.MoveToOthers}
      data-test-id={`moveToOthersMenuItem-${rule.ruleId}`}
    >
      <Link to={getMoveRulePath(SELECTED_RULE_ACTION.MOVE_TO_OTHERS)}>
        <Trans
          i18nKey="ruleDashboardPage.ruleDropdown.moveToOthers"
          components={{ bold: <Text weight="semibold" /> }}
        />
      </Link>
    </Menu.Item>
  )

  if (isPublished) {
    return [
      viewSummary,
      managePolicyDivider,
      manageSchedule,
      editPolicy,
      editRuleDetails,
      duplicatePolcy,
      publishingDivider,
      changeRank,
      moveToOthers,
    ]
  }

  return [
    viewSummary,
    managePolicyDivider,
    editPolicy,
    editRuleDetails,
    duplicatePolcy,
    deletePolicy,
    publishingDivider,
    moveToPublished,
  ]
}

export default getMenuItems
