import { AxiosResponse } from 'axios'
import {
  PolicyMatchId,
  ValidateRuleResultItem,
  ValidateRulePayload,
  ValidationResponse,
  VALIDATION_FAILURE_REASON,
  validateRule,
} from '@signifyd/http'
import { DefinedUseQueryResult, useQuery } from '@tanstack/react-query'
import { isEqual, partition, uniqWith } from 'lodash'
import { hasValidationFailed } from './utils'

export interface Results {
  invalidInvestigationResults: Array<ValidateRuleResultItem>
  validInvestigationResults: Array<ValidateRuleResultItem>
}

export interface ValidatedResults {
  results: Results
  didValidationFail: boolean
}

export const useValidatePolicies = (
  policyMatchIds: Array<PolicyMatchId> = [],
  isMatching: boolean,
  isEditing: boolean,
  ruleId?: number,
  teamId?: number
): DefinedUseQueryResult<ValidatedResults> => {
  const validateIds = async (): Promise<ValidatedResults> => {
    if (!ruleId || !teamId || policyMatchIds.length === 0) {
      return Promise.resolve({
        results: {
          invalidInvestigationResults: [],
          validInvestigationResults: [],
        },
        didValidationFail: false,
      })
    }

    const validPolicyMatchIds = policyMatchIds.filter(
      (id) => id.investigationId == null || !Number.isNaN(id.investigationId)
    )

    const validateRulePayload: ValidateRulePayload = {
      policyMatchIds: validPolicyMatchIds,
      match: isMatching,
      teamId,
      useDraftPredicate: isEditing,
    }

    const { data }: AxiosResponse<ValidationResponse> = await validateRule(
      ruleId,
      validateRulePayload
    )

    const [validResults, invalidInvestigationResults] = partition(
      data.validationResults,
      (investigation: ValidateRuleResultItem) =>
        investigation.validationFailureReason !==
        VALIDATION_FAILURE_REASON.INVALID_ID
    )

    const getRowKey = ({
      investigationId,
      orderId,
      returnId,
    }: ValidateRuleResultItem): string =>
      `${investigationId}${orderId}${returnId}`

    const didValidationFail = hasValidationFailed(validResults)
    const uniqValidResults = uniqWith(validResults, isEqual)
    const validInvestigationResults = uniqValidResults.map((item) => ({
      ...item,
      key: getRowKey(item),
    }))

    return {
      results: { validInvestigationResults, invalidInvestigationResults },
      didValidationFail,
    }
  }

  const validationResults = useQuery({
    queryKey: [
      `${isMatching ? 'matching' : 'nonMatching'}ValidatedIds`,
      ruleId,
      teamId,
      policyMatchIds,
    ],
    queryFn: () => {
      return validateIds()
    },
    initialData: () => ({
      results: {
        validInvestigationResults: [],
        invalidInvestigationResults: [],
      },
      didValidationFail: false,
    }),
    enabled: !!policyMatchIds.length && !!ruleId && !!teamId,
  })

  return validationResults
}
