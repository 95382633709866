import { FC } from 'react'
import { Text } from '@signifyd/components'
import styles from './MenuItemText.less'

interface Props {
  type?: 'secondary' | 'danger'
}

const MenuItemText: FC<Props> = ({ children, type }) => (
  <Text size="sm" block type={type} className={styles.menuItem}>
    {children}
  </Text>
)

export default MenuItemText
