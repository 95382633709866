import { RuleResponse } from '@signifyd/http'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { ConditionTree } from 'pages/PolicyConditionsPage/components/ConditionTreeRule/ConditionTreeRule.types'
import { useStoreActions, useStoreState } from 'stores'
import { convertToLegacyConditionTreeStructure } from 'pages/PolicyConditionsPage/PolicyConditionsPage.utils'
import { serializeConditionTree } from 'stores/conditionTree'
import { getHTTPErrorStatus, messageOnError } from 'core/http'

interface UseFinishPolicyConditionsOptions {
  editReason?: string
  policy: RuleResponse | null
  conditionTree: ConditionTree
}

const useFinishPolicyConditions = ({
  editReason,
  policy,
  conditionTree,
}: UseFinishPolicyConditionsOptions): (() => Promise<void>) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { policyHTTPStatuses } = useStoreState((state) => state.ruleModel)

  const { updatePolicy, setState: setRuleStoreState } = useStoreActions(
    (actions) => actions.ruleModel
  )

  return async () => {
    const isEditing = !!editReason

    if (!policy || !conditionTree) {
      return
    }

    const oldConditionTreeStructure =
      convertToLegacyConditionTreeStructure(conditionTree)
    const predicate = JSON.stringify(
      serializeConditionTree(oldConditionTreeStructure)
    )

    const updateRequestBody = isEditing
      ? { draftPredicate: predicate, editReason }
      : { predicate }

    const pageType = isEditing ? 'edit' : 'create'

    try {
      await updatePolicy({
        name: policy.name,
        description: policy.description,
        ruleOutcome: policy.ruleOutcome,
        ruleId: policy.ruleId,
        ...updateRequestBody,
      })

      navigate(`/policies/${pageType}/${policy.ruleId}/validation`)
    } catch (e) {
      messageOnError(t('ruleCommon.fallbackErrorMessage.createRule'))(e)

      const status = getHTTPErrorStatus(
        e,
        t('ruleCommon.fallbackErrorMessage.updateRule')
      )

      setRuleStoreState({
        policyHTTPStatuses: {
          createPolicy: policyHTTPStatuses.createPolicy,
          updatePolicy: status,
        },
      })
    }
  }
}

export default useFinishPolicyConditions
