import { PredictionList, PREDICTION_LIST_TYPE } from '@signifyd/http'
import { LIST_PAGE_TYPE_FILTER } from 'stores/predictionList'

type TypeToCountMap = Partial<Record<LIST_PAGE_TYPE_FILTER, number>>

export const getCountsForTypeFilters = (
  predictionLists: Array<PredictionList>
): TypeToCountMap => {
  const typeToCountMap: TypeToCountMap = {
    [LIST_PAGE_TYPE_FILTER.ALL]: predictionLists.length,
  }

  Object.values(PREDICTION_LIST_TYPE).forEach((type) => {
    typeToCountMap[type] = 0
  })

  predictionLists.forEach((list) => {
    const countForPredictionListType = typeToCountMap[list.type]

    if (countForPredictionListType !== undefined) {
      const newCount = countForPredictionListType + 1

      typeToCountMap[list.type] = newCount
    }
  })

  return typeToCountMap
}
