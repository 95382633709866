export default {
  appName: 'Policies',
  ruleSchedule: {
    addScheduleButtonText: 'Add schedule',
  },
  ruleStatusBadge: {
    tooltipTitle: 'Current status',
    status: {
      ACTIVE: 'Active',
      DRAFT: 'Draft',
      EXPIRED: 'Expired',
      INACTIVE: 'Inactive',
      NEW: 'New',
      SCHEDULED: 'Schedule',
      DELETED: 'Deleted',
    },
  },
  createRuleSteps: {
    conditions: {
      title: 'Configure',
      description:
        'Use variables to configure conditions and select an action to take when conditions match an order',
    },
    validation: {
      title: 'Validate',
      description:
        'Review policy configuration and validate with previously identified orders',
    },
  },
  rulePageHeader: {
    title: 'Create policy',
    title_edit: 'Edit policy',
    title_create: 'Create policy',
    extra: 'New policy not created yet',
  },
  discardRuleCreationPopconfirm: {
    triggerBtnText: 'Discard',
    title: 'Please confirm before discarding',
    description: 'Any progress on the new policy will be lost.',
    okText: 'Discard',
    cancelText: 'Keep progress',
  },
  createRuleModal: {
    triggerBtnText: 'Create policy',
    title: 'Create policy',
    okText: 'Continue',
  },
  editRuleModal: {
    triggerBtnText: 'Edit details',
    title: 'Edit details',
    okText: 'Apply',
  },
  ruleDetailsModal: {
    nameLabel: 'Name',
    namePlaceholder: 'Hint: Short names are best, max 80 characters',
    descriptionLabel: 'Description (optional)',
    descriptionPlaceholder: 'Hint: Describe purpose of policy',
    teamSelectLabel: 'Team (store)',
    cancelText: 'Cancel',
  },
  ruleScheduleModal: {
    cancelText: 'Cancel',
    okText: 'Apply',
    formLabel: 'Schedule',
    startInputPlaceholder: 'Start date and time',
    endInputPlaceholder: 'End date and time',
  },
  ruleSummaryCard: {
    notValid: 'Not a valid policy',
    editConditions: 'Edit policy configuration',
    if: 'IF',
    then: 'THEN',
    and: 'and',
    or: 'OR',
    not: 'not',
    rank: 'Rank',
    editReason: '<bold>Reason for edit:</bold> {{editReason}}',
    conditionDisplayText: {
      and: 'All following conditions match',
      and_subCondition: 'If all sub-conditions match',
      numberOfConditions: 'If {{number}} of {{max}} sub-conditions match',
      or: 'Any following conditions match',
      or_subCondition: 'If any sub-conditions match',
    },
  },
  fallbackErrorMessage: {
    getRule:
      "The policy you are looking for has been deleted or doesn't exist.",
    filterRules:
      'Apologies — our system encountered an error retrieving policies. Try again.',
    createRule:
      'Apologies — our system encountered an error creating policy. Try again.',
    updateRule:
      'Apologies — our system encountered an error updating policy. Try again.',
    deletePolicy:
      'Apologies — our system encountered an error deleting the policy. Try again.',
    validateRule:
      'Apologies — our system encountered an error validating policy. Try again.',
    listPredictionLists:
      'Apologies — our system encountered an error retrieving lists. Try again.',
    createPredictionList:
      'Apologies — our system encountered an error creating list. Try again.',
    deserializeRulePredicate:
      'Apologies — our system encountered an error. Try again.',
    publishRuleSet:
      'Apologies — our system encountered an error creating policy. Try again.',
    createDraftRuleSetAndListAllRules:
      'Apologies — our system encountered an error creating policy. Try again.',
    updateRuleSet:
      'Apologies — our system encountered an error updating policy. Try again.',
    getRuleSet:
      'Apologies — our system encountered an error retrieving policy. Try again.',
    getRuleSetAndListAllRules:
      'Apologies — our system encountered an error. Try again.',
    filterVariables: 'Apologies — our system encountered an error. Try again.',
    maxRuleLimitExceededPolicies:
      'You have exceeded the maximum number of {{count}} published policy. {{ movePoliciesText }}',
    maxRuleLimitExceededPolicies_other:
      'You have exceeded the maximum number of {{count}} published policies. {{ movePoliciesText }}',
    moveExcessRules:
      'To publish new policies, move {{count}} policy to "Not to publish"',
    moveExcessRules_other:
      'To publish new policies, move {{count}} policies to "Not to publish"',
    expiredRules:
      'Please update expired schedules for the following policies: {{expiredRuleNames}}',
  },
  successMessage: {
    publishRuleSet: 'Success! Settings published.',
    updatePolicyAndPublishRuleSet:
      'Success! Policy edit and publishing settings are saved.',
  },
  dropdown: {
    editTooltip:
      'Apologies, this policy has variables that are no longer supported and may not be edited.',
    editConfiguration: 'Edit configuration',
    editDetails: 'Edit name or description',
    duplicate: 'Duplicate',
    duplicateTooltip:
      'Apologies, this policy has variables that are no longer supported and may not be duplicated.',
  },
}
