import { FC, memo, useState, useMemo, useCallback } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { RuleResponse } from '@signifyd/http'
import { joinClassNames } from '@signifyd/utils'
import RuleRank from 'core/components/RuleRank'
import RuleScheduleModal from 'core/components/RuleScheduleModal'
import useUserTeams from 'core/hooks/useUserTeams'
import {
  ChangeSchedulePayload,
  DROPPABLE_ID,
} from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import RuleSetDnDDroppableRow from './RuleSetDnDDroppableRow'
import DraggableRule from './RuleSetDnDDraggableRule'
import styles from './RuleSetDnDDroppableRuleList.less'

interface DraggableRuleListProps {
  rules: Array<RuleResponse>
  droppableId: DROPPABLE_ID
  selectedRuleId: number | null
  onMoveRuleToAnotherList: (srcIndex: number) => void
  onClickSchedule?: (srcIndex: number) => void
}

const RuleList: FC<DraggableRuleListProps> = ({
  rules,
  droppableId,
  selectedRuleId,
  onMoveRuleToAnotherList,
  onClickSchedule,
}) => {
  const isPublishedRule = droppableId === DROPPABLE_ID.PUBLISHED

  return (
    <>
      {rules.map((rule: RuleResponse, index: number) => (
        <DraggableRule
          key={rule.ruleId}
          rule={rule}
          selected={selectedRuleId === rule.ruleId}
          isPublishedRule={isPublishedRule}
          index={index}
          onMoveToAnotherList={() => onMoveRuleToAnotherList(index)}
          onClickSchedule={
            isPublishedRule && onClickSchedule
              ? () => onClickSchedule(index)
              : undefined
          }
        />
      ))}
    </>
  )
}

const MemoRuleList = memo<DraggableRuleListProps>(RuleList)

const getListDraggingClassNames = (isDraggingOver: boolean): string => {
  return isDraggingOver ? styles.dragging : ''
}

interface Props {
  selectedRuleId: number | null
  droppableId: DROPPABLE_ID
  rules: Array<RuleResponse>
  placeholder: string
  onMoveRuleToAnotherList: (srcIndex: number) => void
  onChangeRuleSchedule?: (payload: ChangeSchedulePayload) => void
}

const RuleSetDnDDroppableRuleList: FC<Props> = ({
  selectedRuleId,
  droppableId,
  rules,
  placeholder,
  onMoveRuleToAnotherList,
  onChangeRuleSchedule,
}) => {
  const { t } = useTranslation()
  const { userTimeZone } = useUserTeams()
  const [selectedRuleIndex, setSelectedRuleIndex] = useState(-1)
  const showPlaceholder = !rules || rules.length === 0
  const isPublished = droppableId === DROPPABLE_ID.PUBLISHED
  const selectedRule = useMemo(
    () => rules[selectedRuleIndex],
    [rules, selectedRuleIndex]
  )

  const handleCancel = useCallback(
    () => setSelectedRuleIndex(-1),
    [setSelectedRuleIndex]
  )

  const modalTitle =
    selectedRule?.scheduleEnd || selectedRule?.scheduleStart
      ? t('publishWithSimulatorPage.ruleScheduleModal.manageSchedule')
      : t('publishWithSimulatorPage.ruleScheduleModal.addSchedule')

  return (
    <div className={styles.wrapper}>
      <div className={styles.ranksCol}>
        {isPublished &&
          rules.map((rule, index) => (
            <RuleSetDnDDroppableRow
              key={rule.ruleId}
              className={styles.rankItem}
            >
              <RuleRank rank={index + 1} />
            </RuleSetDnDDroppableRow>
          ))}
      </div>
      <div className={styles.ruleListCol}>
        <Droppable
          droppableId={droppableId}
          data-analytics-id="rule-list-droppable"
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={joinClassNames([
                styles.ruleList,
                isPublished ? styles.published : styles.others,
                showPlaceholder && styles.placeholder,
                getListDraggingClassNames(snapshot.isDraggingOver),
              ])}
            >
              <MemoRuleList
                rules={rules}
                selectedRuleId={selectedRuleId}
                droppableId={droppableId}
                onMoveRuleToAnotherList={onMoveRuleToAnotherList}
                onClickSchedule={setSelectedRuleIndex}
              />
              {showPlaceholder && (
                <div className={styles.placeholder}>{placeholder}</div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      <RuleScheduleModal
        visible={!!selectedRule}
        userTimeZone={userTimeZone}
        title={modalTitle}
        rule={selectedRule}
        onCancel={handleCancel}
        onSubmit={({ scheduleStart, scheduleEnd }) => {
          setSelectedRuleIndex(-1)

          if (onChangeRuleSchedule) {
            onChangeRuleSchedule({
              index: selectedRuleIndex,
              scheduleStart,
              scheduleEnd,
            })
          }
        }}
      />
    </div>
  )
}

export default RuleSetDnDDroppableRuleList
